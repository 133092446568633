import { v4 as uuidv4 } from 'uuid';

import InvoiceNumberValueObject from 'Invoice/SampleProforma/_models/ValueObjects/InvoiceNumberValueObject';

class InvoiceNumberEntity {
  private readonly id: string;
  private value: InvoiceNumberValueObject;
  private disabled: boolean;

  constructor(disabled: boolean) {
    this.id = uuidv4();
    this.value = new InvoiceNumberValueObject(null);
    this.disabled = disabled;
  }

  public getInvoiceNumber(): InvoiceNumberValueObject {
    return this.value;
  }

  public getValue(): string | null {
    return this.value.getValue();
  }

  public setValue(value: string | undefined): void {
    this.value = new InvoiceNumberValueObject(value ?? null);
  }

  public validate(): void {
    if (this.value.getError() !== null) {
      throw this.value.getError();
    }
  }

  getDisabled(): boolean {
    return this.disabled;
  }

  setDisabled(disabled: boolean): void {
    this.disabled = disabled;
  }
}

export default InvoiceNumberEntity;
