import { MultipleActionItem } from 'Models/Orders/MultipleSelectActions/MultipleSelectActions';
import { BaseAction } from 'Store/actions/_types_/BaseAction';
export const SET_SELECTED_ORDERS = 'SET_SELECTED_ORDERS';
export const CLEAR_SELECTED_ORDERS = 'CLEAR_SELECTED_ORDERS';

export interface SetSelectedOrders extends BaseAction {
  orders: MultipleActionItem[];
}
export type ClearSelectedOrders = BaseAction;

export const doSetSelectedOrders = (orders: MultipleActionItem[]): SetSelectedOrders => ({
  type: SET_SELECTED_ORDERS,
  orders,
});

export const doClearSelectedOrders = (): ClearSelectedOrders => ({
  type: CLEAR_SELECTED_ORDERS,
});
export type OrdersAction = SetSelectedOrders | ClearSelectedOrders;
