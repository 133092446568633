import { ReactElement, useEffect, useState } from 'react';


import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { useFeatureFlags } from 'Components/UI/Devbar/widgets/Flags/FlagsMapper';
import FlagsPopover from 'Components/UI/Devbar/widgets/Flags/FlagsPopover';
import { vpFeatureFlags } from 'FeatureFlags/FeatureFlags';
import { FlagInfo } from 'FeatureFlags/interface/FlagInfo';
import { User } from 'Models/User/_types_/User';

interface Props {
  user: User | null;
  ldFlags: LDFlagSet;
}

const FeatureFlagsPopover = (props: Props): ReactElement => {
  const allFeatureFlags = useFeatureFlags();
  const [featureFlags, setFeatureFlags] = useState<FlagInfo[]>([]);

  useEffect((): void => {
    setFeatureFlags(allFeatureFlags(vpFeatureFlags.getAllFeatureFlags, props.ldFlags));
  }, [props.ldFlags, allFeatureFlags]);

  return <FlagsPopover canDisable text="Feature Flags" flags={featureFlags} />;
};

export default FeatureFlagsPopover;
