import { Key } from 'react';

export const REQUIRED_TEXT = 'Required';

const Required = (val: Key | undefined): string | undefined =>
  val && val.toString().trimEnd().length > 0 ? undefined : REQUIRED_TEXT;

const MinLength =
  (minLength: number): ((val: Key) => string | undefined) =>
  (val: Key): string | undefined => {
    return ('' + val).trim().length >= minLength ? undefined : `Min length is ${minLength}`;
  };

const MaxLength =
  (maxLength: number): ((val: Key) => string | undefined) =>
  (val: Key): string | undefined => {
    return ('' + val).length <= maxLength ? undefined : `Max length is ${maxLength}`;
  };

const maxByteLength =
  (maxLength: number): ((val: Key) => string | undefined) =>
  (val: Key): string | undefined => {
    return new Blob(['' + val], {
      type: 'text/plain',
    }).size <= maxLength
      ? undefined
      : `Too many characters`;
  };

const maxNumber =
  (number: number): ((val: Key) => string | undefined) =>
  (val: Key): string | undefined => {
    if (!val) {
      return undefined;
    }
    return parseInt('' + val) <= number ? undefined : `Max value is ${number}`;
  };

const minNumber =
  (number: number): ((val: Key) => string | undefined) =>
  (val: Key): string | undefined => {
    if (!val) {
      return;
    }
    return parseInt('' + val) >= number ? undefined : `Value must be greater than ${number}`;
  };

const onlyNumber = (val: Key): string | undefined => {
  if (!val) {
    return;
  }
  if (isNaN(+('' + val))) {
    return 'Number only';
  }
};

const minMaxValidator =
  (
    min: number,
    max: number,
    minAlertErrorString = '',
    maxAlertErrorString = '',
  ): ((val: Key) => string | undefined) =>
  (val: Key) => {
    if (!val) {
      return;
    }
    const safeVal = parseFloat('' + val);
    if (isNaN(safeVal)) {
      return;
    }
    if (safeVal < min) {
      return minAlertErrorString ?? `Value must be greater than ${min}`;
    }
    if (safeVal > max) {
      return maxAlertErrorString ?? `Value must be smaller than ${max}`;
    }
  };

const expectedValueValidator =
  (
    expected?: number,
    errorString = `Value is not the same as expected: ${expected}`,
  ): ((val: Key) => string | undefined) =>
  (val: Key) => {
    if (!val || !expected) {
      return;
    }
    if (+('' + val) !== expected) {
      return errorString;
    }
  };

export const validators = {
  constants: {
    REQUIRED_TEXT,
  },
  required: Required,
  minLength: MinLength,
  maxLength: MaxLength,
  maxByteLength,
  maxNumber,
  minNumber,
  onlyNumber,
  minMaxValidator,
  expectedValueValidator,
};
