import { useRef, useEffect, useCallback, useState, useMemo } from 'react';

import { AxiosError } from 'axios';

import { UseBestApiTupleConfigs } from 'Components/Hooks/_types_/UseBestApiTupleConfigs';
import { useBaseApiQuery } from 'Components/Hooks/useBaseApiQuery';
import { BestOneErrorResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';

import { UseLazyBestApiTuple } from './_types_';

export * from './_types_';

/**
 * @param apiEndpoint - the endpoint to be called
 * @param {boolean} config - One of the configs from UseBestApiTupleConfigs
 * @param {Response} fakeData - return this fake data
 * @returns UseLazyBestApiTuple
 */
export const useLazyBestApi = <Response, Config extends UseBestApiTupleConfigs>(
  apiEndpoint: string,
  config: Config,
  fakeData?: { data: Response | (() => Response); delay?: number },
): UseLazyBestApiTuple<Response, Config> => {
  const localApiEndpoint = useRef(apiEndpoint);
  const latestConfig = useRef(config);

  useEffect(() => {
    latestConfig.current = config;
    if (localApiEndpoint.current !== apiEndpoint) {
      localApiEndpoint.current = apiEndpoint;
    }
  });

  const [loading, error, response, baseQuery] = useBaseApiQuery<
    Response,
    Config,
    AxiosError<BestOneErrorResponse>
  >(localApiEndpoint.current, latestConfig.current);
  const [localError, setLocalError] = useState<AxiosError<BestOneErrorResponse> | undefined>();

  useEffect((): void => {
    setLocalError(error);
  }, [error]);

  const query = useRef(baseQuery);
  const fetch = useCallback(
    async (adjustedConfig?: Partial<Config>): Promise<void> => {
      setLocalError(undefined);
      await query
        .current({ ...latestConfig.current, ...adjustedConfig }, fakeData, localApiEndpoint.current)
        .catch((e) => {
          setLocalError(e);
        });
    },
    [localApiEndpoint, fakeData],
  );

  const updateConfig = useCallback((newConfig: { apiEndpoint?: string; config?: Config }) => {
    if (newConfig.apiEndpoint) {
      localApiEndpoint.current = newConfig.apiEndpoint;
    }
    if (newConfig.config) {
      latestConfig.current = newConfig.config;
    }
  }, []);

  return useMemo(
    () => [fetch, { loading, error: localError, data: response }, updateConfig],
    [localError, fetch, loading, response, updateConfig],
  );
};
