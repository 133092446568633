import { PageableProps } from '../../../Shared/Table/_types_/TableMeta';
import { TableState } from '../_types_/TableState';

export const SET_TABLE_PAGINATION = 'SET_TABLE_PAGINATION';

export interface SetTablePaginationAction {
  type: typeof SET_TABLE_PAGINATION;
  pagination: PageableProps;
}

export const doSetTablePagination = (pagination: PageableProps): SetTablePaginationAction => ({
  type: SET_TABLE_PAGINATION,
  pagination,
});

export const applySetTablePagination = (
  newState: TableState,
  action: SetTablePaginationAction
): TableState => {
  const { pageSize, currentPage, ...pag } = action.pagination;

  newState.pagination = {
    ...pag,
    currentPage: newState.pagination?.currentPage ?? currentPage,
    pageSize: newState.pagination?.pageSize ?? pageSize,
  };

  return newState;
};
