import React, { ReactElement, useMemo } from 'react';

import SearchableDropdown, {
  SearchableDropdownElement,
} from 'Components/Shared/SearchableDropdown/SearchableDropdown';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableColumn } from '../../state/_types_/TableColumn';
import { SelectOneFilter, SelectFilter } from '../../state/_types_/TableFilters';
import { doChangeFilter } from '../../state/actions/changeFilter';
import { useTableState } from '../../state/useTableState';

interface Props<T extends TableRecord> {
  filter: SelectFilter | SelectOneFilter;
  column: TableColumn<T>;
}

const TableSelectFilter = <T extends TableRecord>({
  filter: {
    loading,
    elements = [
      {
        title: 'No items available',
        value: -1,
      },
    ],
    ...filter
  },
  column,
}: Props<T>): ReactElement => {
  const { state, dispatch } = useTableState<T>();

  const handleClick = (value: string | number) => {
    if (filter.type === 'select') {
      dispatch(
        doChangeFilter({
          property: column.property,
          filterType: filter.type,
          value: [value],
        }),
      );
    } else {
      dispatch(
        doChangeFilter({
          property: column.property,
          filterType: filter.type,
          value: value,
        }),
      );
    }
  };

  const currentFilter = state.filters[column.property];
  const currentValue = useMemo((): SearchableDropdownElement | undefined => {
    const currentElement = elements.find((x) => x.value === currentFilter?.value);
    if (currentElement === undefined) {
      return undefined;
    }
    return {
      name: currentElement.title,
      value: currentElement.value,
    };
  }, [currentFilter?.value, elements]);
  return (
    <SearchableDropdown
      disableClearable
      elements={elements.map((x) => ({ name: x.title, value: x.value }))}
      loading={loading ?? false}
      value={currentValue}
      onChange={(e) => handleClick(e.value)}
      variant="outlined"
      width="100%"
    />
  );
};

export default TableSelectFilter;
