import { Key } from 'react';

import { TableState } from '../_types_/TableState';

export const EXPAND_TABLE_ROW = 'EXPAND_TABLE_ROW';

export interface ExpandTableRow {
  type: typeof EXPAND_TABLE_ROW;
  selectedPrimaryKey: string;
}

export const doExpandTableRow = (selectedPrimaryKey: Key): ExpandTableRow => ({
  type: EXPAND_TABLE_ROW,
  selectedPrimaryKey: String(selectedPrimaryKey),
});

export const applyExpandTableRow = (newState: TableState, action: ExpandTableRow): TableState => {
  const filteredExpandedRows = newState.expandedRows.filter((r) => r !== action.selectedPrimaryKey);
  if (filteredExpandedRows.length !== newState.expandedRows.length) {
    newState.expandedRows = filteredExpandedRows;
  } else {
    newState.expandedRows.push(action.selectedPrimaryKey);
  }

  return newState;
};
