import { useSelector } from 'react-redux';

import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

export const useGetFirstAndLastLetter = () => {
  const user = useSelector((root: RootReducerState): UserReducerType => root.userReducer);

  if (!user?.user || !user.user.fullName) {
    return '';
  }
  const splitted = user.user.fullName.split(' ');
  const first = splitted[0][0];
  const second = splitted.length > 1 ? splitted[1][0] : '';
  return `${first}${second}`;
};
