import { useEffect } from 'react';

import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';

import { doSetShouldTableCollapse } from '../../state/actions/setShouldTableCollapse';
import { useTableState } from '../../state/useTableState';

const useLayoutHelper = (): void => {
  const { state, dispatch } = useTableState();
  const shouldCollapseTable = useIsMobile();

  useEffect(() => {
    if (state.tableOptions.responsive) {
      dispatch(doSetShouldTableCollapse(shouldCollapseTable));
    }
  }, [dispatch, shouldCollapseTable, state.tableOptions.responsive]);
};

export default useLayoutHelper;
