import { Key } from 'react';

import { TableState } from '../_types_/TableState';

export const REMOVE_TABLE_ROW = 'REMOVE_TABLE_ROW';

export interface RemoveTableRow {
  type: typeof REMOVE_TABLE_ROW;
  selectedPrimaryKey: string;
}

export const doRemoveTableRow = (selectedPrimaryKey: Key): RemoveTableRow => ({
  type: REMOVE_TABLE_ROW,
  selectedPrimaryKey: String(selectedPrimaryKey),
});

export const applyRemoveTableRow = (newState: TableState, action: RemoveTableRow): TableState => {
  newState.rows = newState.rows.filter(
    (r) => '' + r[newState.primaryKey] !== action.selectedPrimaryKey
  );

  return newState;
};
