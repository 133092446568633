import { ReactNode } from 'react';


import _ from 'lodash';

import { TableColumn } from 'Components/Table/state/_types_/TableColumn';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { TableStateDispatch } from 'Components/Table/state/_types_/TableStateDispatch';
import numberFormatter from 'Helpers/formatters/numberFormatter';

const cellContent = <T extends TableRecord>(
  column: TableColumn<T>,
  row: T,
  dispatch: TableStateDispatch<T>
): ReactNode => {
  if (column.resolver) {
    return column.resolver(row, dispatch);
  }
  if (column.number) {
    return (
      <>
        {numberFormatter({
          value: +row[column.property],
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}
      </>
    );
  }

  return <>{_.get(row, column.property) ?? ' - '}</>;
};

export default cellContent;
