import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import {
  Purchaser,
  ServerBrands,
  ServerBrandWithProductLines,
} from '_types_/MasterData/MasterDataType';
import { GetClaimConfig } from 'Claim/_services/claimService';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { properties } from 'Helpers/properties';
import { Vendor } from 'Store/reducers/dataCache.reducer';

axios.defaults.paramsSerializer = (params: any): string => {
  return qs.stringify(params, { indices: false, skipNulls: true }); // param=value1&param=value2
};
const branchNumber = sessionStorage.getItem('masterDataBranch')
  ? sessionStorage.getItem('masterDataBranch') + '/'
  : '';

export const FETCH_BRANDS = properties.serviceApiURL + branchNumber + `masterdata/brands`;
export const FETCH_BRANDS_AND_PL =
  properties.serviceApiURL + branchNumber + `masterdata/brands-productlines`;
export const FETCH_BUYERS = properties.serviceApiURL + branchNumber + `masterdata/buyers`;
export const fetchBuyersForBrnd = (brandId: number) =>
  properties.serviceApiURL + branchNumber + `masterdata/brand/${brandId}/buyers`;

export const GET_ALL_VENDORS = `
${properties.serviceApiURL}masterdata/vendors`;

const useFetchBrands = (): UseLazyBestApiTuple<ServerBrands[], GetClaimConfig> => {
  return useLazyBestApi<ServerBrands[], GetClaimConfig>(FETCH_BRANDS, {
    headers: {
      'MASTERDATA-API-VERSION': '2.0',
    },
    method: 'GET',
  });
};

const fetchBrandsAndPL = (
  productLineNumbers?: string[]
): Promise<ServerBrandWithProductLines[]> => {
  return axios
    .get(FETCH_BRANDS_AND_PL, {
      headers: {
        'MASTERDATA-API-VERSION': '2.1',
      },
      params: {
        productLineNumber: productLineNumbers ?? null,
      },
    })
    .then((result: AxiosResponse<ServerBrandWithProductLines[]>): ServerBrandWithProductLines[] => {
      return result.data;
    });
};

const getAllVendors = (): Promise<Vendor[]> => {
  return axios
    .get(GET_ALL_VENDORS, {
      headers: {
        'MASTERDATA-API-VERSION': '2.0',
      },
    })
    .then((res: AxiosResponse<Vendor[]>): Vendor[] => {
      return res.data;
    });
};

const useFetchPurchasers = (): UseLazyBestApiTuple<Purchaser[], GetClaimConfig> => {
  return useLazyBestApi<Purchaser[], GetClaimConfig>(FETCH_BUYERS, {
    headers: {
      'MASTERDATA-API-VERSION': '1.0',
    },
    method: 'GET',
  });
};

export const masterDataService = {
  useFetchBrands,
  getAllVendors,
  fetchBrandsAndPL,
  useFetchPurchasers,
};
