import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
  SET_USER,
  DELETE_USER,
  SET_USER_CONFIG,
  USER_SETTINKS_KEY,
  LOGOUT_USER,
  UPDATE_USER_MDM,
} from 'Constants/user.constants';
import { User } from 'Models/User/_types_/User';
import { UserConfig } from 'Models/User/_types_/UserConfig';

import { FAKE_PERMISSION } from '../../Constants/user.constants';

import { BaseAction } from './_types_/BaseAction';

export interface SetUserConfig extends BaseAction {
  config: UserConfig;
}

export interface FakePermission extends BaseAction {
  permissionId: number;
}

export interface SetUser extends BaseAction {
  user: User;
}

export interface UpdateUserMdM extends BaseAction {
  newMdM: string;
}

const setUser = (user: User): SetUser => {
  return { type: SET_USER, user: { ...user } };
};
const updateUserMdM = (newMdM: string): UpdateUserMdM => {
  return { type: UPDATE_USER_MDM, newMdM };
};

const deleteUser = (): BaseAction => {
  return { type: DELETE_USER };
};

const logoutUser = (): BaseAction => {
  return { type: LOGOUT_USER };
};

const fakePermission = (permissionId: number): FakePermission => {
  return { type: FAKE_PERMISSION, permissionId };
};

export const useSetUserConfig = (): ((flags: LDFlagSet, config?: UserConfig) => SetUserConfig) => {
  return (flags: LDFlagSet, config?: UserConfig) => {
    if (config === undefined) {
      const savedData = localStorage.getItem(USER_SETTINKS_KEY);
      const newCfg = savedData !== null ? JSON.parse(savedData) : undefined;

      return {
        type: SET_USER_CONFIG,
        config: newCfg,
      };
    }

    localStorage.setItem(USER_SETTINKS_KEY, JSON.stringify(config));
    return { type: SET_USER_CONFIG, config };
  };
};

export const userActions = {
  setUser,
  updateUserMdM,
  deleteUser,
  fakePermission,
  logoutUser,
};
