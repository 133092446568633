import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { Dialog, DialogButtonProps } from '@bestseller-bit/frontend-community.components.dialog';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { usePutDefaultProfile } from 'UserAuthentication/_LoginUserServices/hooks/functionalities/usePutDefaultProfile';
import AvailableProfiles from 'UserAuthentication/Functionalities/SelectDefault/AvailableProfiles';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const SelectDefault = (props: Props) => {
  const { open, setOpen } = props;
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>();
  const [fetchPutDefaultAccount, { loading, data, error }] = usePutDefaultProfile(
    selectedAccount ?? '',
  );
  const [hasFetched, setHasFetched] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const setDefaultAccount = useCallback(() => {
    setHasFetched(true);
    fetchPutDefaultAccount();
  }, [fetchPutDefaultAccount]);

  useEffect(() => {
    if (loading || !hasFetched) {
      return;
    }
    if (error) {
      setErrorMessage(`Error found: ${error.response?.data.message}`);
      return;
    }
    if (data) {
      setErrorMessage(undefined);
      setOpen(false);
    }
  }, [data, error, loading, setOpen, hasFetched]);

  const buttons = useMemo((): DialogButtonProps[] => {
    return [
      {
        label: loading ? 'Loading...' : 'Save',
        onClick: setDefaultAccount,
        variant: 'contained',
        disabled: !selectedAccount || loading,
      },
    ];
  }, [setDefaultAccount, selectedAccount, loading]);

  return (
    <Dialog
      buttons={buttons}
      width="lg"
      title="Choose default account"
      titleVariant="h4"
      dividers
      open={open}
      onClose={() => {}} // We don't want to close the dialog until user has selected an account
    >
      <Box p={1}>
        {loading ? (
          <Loading />
        ) : (
          <AvailableProfiles
            selectedAccount={selectedAccount}
            setSelectedAccount={(value: string | undefined) => setSelectedAccount(value)}
          />
        )}
        {!loading && errorMessage && (
          <Box mt={2}>
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default SelectDefault;
