import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';

const PrivateRoute = ({ children }: PropsWithChildren<{}>) => {
  const userTokens = useAuthState().authState?.tokens;

  return userTokens?.accessToken ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
