import { UseBestApiRequestConfig } from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { GetNewsAttachmentResponse, ServerNews } from 'Models/News/_types_/News';

import { FETCH_NEWS, FETCH_NEWS_ATTCHMENT } from '.';

export type UseBestApiNewsTupleConfigs = GetNewsAttachmentRequestConfig;

type GetNewsAttachmentRequestConfig = UseBestApiRequestConfig & {
  method: 'GET';
};

type GetNewsRequestConfig = UseBestApiRequestConfig & {
  method: 'GET';
};

export const useGetNewsAttachment = (
  newsId: number
): UseLazyBestApiTuple<GetNewsAttachmentResponse, GetNewsAttachmentRequestConfig> =>
  useLazyBestApi<GetNewsAttachmentResponse, GetNewsAttachmentRequestConfig>(
    FETCH_NEWS_ATTCHMENT(newsId),
    {
      headers: { 'NEWS-API-VERSION': '1.0' },
      method: 'GET',
    }
  );

export const useGetNews = (): UseLazyBestApiTuple<ServerNews[], GetNewsRequestConfig> =>
  useLazyBestApi<ServerNews[], GetNewsRequestConfig>(FETCH_NEWS, {
    headers: {
      'NEWS-API-VERSION': '1.0',
    },
    method: 'GET',
  });
