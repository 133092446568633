import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import qs from 'qs';

export const useQueryParams = (): Record<string, string | string[]> => {
  const location = useLocation();
  return useMemo(() => {
    return [...new URLSearchParams(location.search).entries()].reduce(
      (acc: Record<string, string | string[]>, tuple: [string, string]) => {
        // getting the key and value from each tuple
        const [k, v] = tuple;
        if (acc[k]) {
          // if the current key is already an array, we'll add the value to it
          if (Array.isArray(acc[k])) {
            acc[k] = [...(acc[k] as string[]), v];
          } else {
            // if it's not an array, but contains a value, we'll convert it into an array
            // and add the current value to it
            acc[k] = [acc[k] as string, v];
          }
        } else {
          // plain assignment if no special case is present
          acc[k] = v;
        }

        return acc;
      },
      {}
    );
  }, [location.search]);
};

export const useSetParamsInUri = <T>(): ((key: string, val: T) => void) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  return useCallback(
    (key: string, val: T): void => {
      const newLocation = qs.stringify(
        { ...queryParams, [key]: val },
        {
          skipNulls: true,
          arrayFormat: 'repeat',
        }
      );
      navigate({
        search: newLocation,
      });
    },
    [navigate, queryParams]
  );
};
