import { datadogRum } from '@datadog/browser-rum';
import {
  LOGOUT,
  SET_ACCESS_TOKEN,
  SET_AD_ACCOUNTS,
  SET_ERROR_MESSAGE,
  SET_INTERCEPTOR_INSTALLED,
  SET_TOKENS,
  UPDATE_ACCESS_TOKEN,
} from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { authState } from 'Hooks/useAuthHandler/state/_types/AuthState';
import { authHandlerStateActions } from 'Hooks/useAuthHandler/state/authHandlerStateActions';

export const authHandlerReducer = (
  state: authState,
  action: authHandlerStateActions,
): authState => {
  const logout = (oldState: authState) => {
    return {
      ...oldState,
      tokens: undefined,
      safeTokens: undefined,
      user: undefined,
      microsoftAccessToken: undefined,
    };
  };

  switch (action.type) {
    case UPDATE_ACCESS_TOKEN: {
      if (state.tokens === undefined) {
        datadogRum.addError('AUTH ERROR: Trying to update accessToken on undefined tokens');
        return logout(state);
      }
      return {
        ...state,
        tokens: {
          ...state.tokens,
          accessToken: action.accessToken,
          expires: action.expires,
        },
        safeTokens: {
          ...state.tokens,
          refreshToken: 'REVOKED',
          accessToken: 'REVOKED',
        },
      };
    }
    case SET_TOKENS: {
      return {
        ...state,
        tokens: action.tokens,
        safeTokens: {
          ...action.tokens,
          refreshToken: 'REVOKED',
          accessToken: 'REVOKED',
        },
      };
    }
    case SET_ACCESS_TOKEN: {
      return {
        ...state,
        microsoftAccessToken: action.microsoftAccessToken,
      };
    }
    case LOGOUT: {
      return logout(state);
    }
    case SET_ERROR_MESSAGE: {
      return { ...state, errorMessage: action.errorMessage };
    }
    case SET_INTERCEPTOR_INSTALLED: {
      return { ...state, interceptorInstalled: action.interceptorInstalled };
    }
    case SET_AD_ACCOUNTS: {
      return { ...state, profiles: action.adAccounts };
    }

    default:
      return state;
  }
};
