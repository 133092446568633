import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Alert, MenuItem, Tooltip } from '@mui/material';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { cutStringIfNeeded } from 'Helpers/cutStringIfNeeded';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { User } from 'Models/User/_types_/User';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { useChangeProfile } from 'UserAuthentication/_LoginUserServices/hooks/functionalities/useChangeProfile';
import { Profile } from 'UserAuthentication/Functionalities/ChooseProfile/_types/ProfileResponse';

export const PROFILE_TABLE_NAME = 'Choose Account';

const SwitchProfileList = (props: {
  closeProfileMenu: () => void;
  closeProfilesList: () => void;
  setLoadingSwitchProfile?: (loading: boolean) => void;
}): ReactElement => {
  const { authState } = useAuthState();

  const userProfiles = useMemo((): Profile[] => {
    return authState.profiles ?? [];
  }, [authState.profiles]);

  const { closeProfilesList, closeProfileMenu } = props;
  const navigate = useNavigate();
  const user = useSelector((root: RootReducerState): User | null => root.userReducer.user);

  const [selectedAccount, setSelectedAccount] = useState<string | undefined>();

  const { hasLoaded, errors } = useChangeProfile(selectedAccount);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleClick = useCallback(
    (bi2NetworkId: string) => {
      setSelectedAccount(bi2NetworkId);
    },
    [setSelectedAccount],
  );

  const awaitRefresh = useCallback(() => {
    return new Promise(() => {
      setTimeout(() => {
        if (props.setLoadingSwitchProfile) {
          props.setLoadingSwitchProfile(false);
        }
        setLoading(false);
        closeProfilesList();
        closeProfileMenu();
        navigate('/');
      }, 2000);
    });
  }, [closeProfileMenu, closeProfilesList, navigate, props]);

  useEffect(() => {
    if (!hasLoaded && selectedAccount) {
      setLoading(true);
    }
  }, [hasLoaded, selectedAccount, props]);

  useEffect(() => {
    if (hasLoaded && (errors.tokenError || errors.profileError)) {
      setLoading(false);
      setError(
        'Something went wrong: ' + (errors.tokenError?.message ?? errors.profileError?.message),
      );
    }
  }, [errors.profileError, errors.tokenError, hasLoaded]);

  useEffect(() => {
    if (hasLoaded && !errors.tokenError && !errors.profileError) {
      awaitRefresh();
    }
  }, [
    closeProfileMenu,
    closeProfilesList,
    errors.profileError,
    errors.tokenError,
    hasLoaded,
    awaitRefresh,
  ]);

  const isLoading = useMemo(() => loading, [loading]);

  if (!user) {
    return <div>Something went wrong</div>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: isLoading ? 'lightgrey' : 'transparent',
        display: 'block',
      }}
    >
      {isLoading && (
        <div style={{ position: 'absolute', left: '40%', top: '40%' }}>
          <Loading />
        </div>
      )}
      {userProfiles.map((row) => {
        if (!row.companyName) {
          return null;
        }
        const displayName = cutStringIfNeeded(row.companyName, 25);
        return (
          <Tooltip
            key={row.bi2NetworkId}
            title={row.companyName.length < 25 ? '' : row.companyName}
            placement="top"
          >
            <MenuItem
              key={row.bi2NetworkId}
              onClick={() => handleClick(row.bi2NetworkId)}
              disabled={isLoading || row.mdmIdentifier === user.vendorMdmIdentifier}
            >
              {displayName}
            </MenuItem>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default SwitchProfileList;
