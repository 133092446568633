import { useState, ReactElement, MouseEvent } from 'react';

import { Popover } from '@mui/material';
import Button from '@mui/material/Button';

import BaseButton from 'Components/Shared/BestButton/BaseButton';

import { BranchPointer } from './_types_';
import classes from './BeBranch.module.scss';

export * from './_types_';

const beBranches: BranchPointer[] = [
  {
    beBranchName: 'ordersBranch',
    beBranchNumber: sessionStorage.getItem('ordersBranch') ?? '',
    identifier: 0,
  },
  {
    beBranchName: 'masterDataBranch',
    beBranchNumber: sessionStorage.getItem('masterDataBranch') ?? '',
    identifier: 1,
  },
  {
    beBranchName: 'stylesBranch',
    beBranchNumber: sessionStorage.getItem('stylesBranch') ?? '',
    identifier: 2,
  },
  {
    beBranchName: 'packingNotesBranch',
    beBranchNumber: sessionStorage.getItem('packingNotesBranch') ?? '',
    identifier: 3,
  },
  {
    beBranchName: 'newsBranch',
    beBranchNumber: sessionStorage.getItem('newsBranch') ?? '',
    identifier: 4,
  },
  {
    beBranchName: 'extServiceBranch',
    beBranchNumber: sessionStorage.getItem('extServiceBranch') ?? '',
    identifier: 5,
  },
  {
    beBranchName: 'supportBranch',
    beBranchNumber: sessionStorage.getItem('supportBranch') ?? '',
    identifier: 6,
  },
  {
    beBranchName: 'documentsBranch',
    beBranchNumber: sessionStorage.getItem('documentsBranch') ?? '',
    identifier: 7,
  },
  {
    beBranchName: 'invoiceBranch',
    beBranchNumber: sessionStorage.getItem('invoiceBranch') ?? '',
    identifier: 8,
  },
  {
    beBranchName: 'merchandiserOffice',
    beBranchNumber: sessionStorage.getItem('merchandiserOffice') ?? '',
    identifier: 9,
  },
  {
    beBranchName: 'gqlStylesBranch',
    beBranchNumber: sessionStorage.getItem('gqlStylesBranch') ?? '',
    identifier: 10,
  },
  {
    beBranchName: 'bomBranch',
    beBranchNumber: sessionStorage.getItem('bomBranch') ?? '',
    identifier: 11,
  },
  {
    beBranchName: 'sampleBranch',
    beBranchNumber: sessionStorage.getItem('sampleBranch') ?? '',
    identifier: 12,
  },
  {
    beBranchName: 'userServiceBranch',
    beBranchNumber: sessionStorage.getItem('userServiceBranch') ?? '',
    identifier: 13,
  },
  {
    beBranchName: 'claimsBranch',
    beBranchNumber: sessionStorage.getItem('claimsBranch') ?? '',
    identifier: 14,
  },
  {
    beBranchName: 'newPackingNotesBranch',
    beBranchNumber: sessionStorage.getItem('newPackingNotesBranch') ?? '',
    identifier: 15,
  },
];

const BeBranches = (): ReactElement => {
  const [anchorElBeBranches, setAnchorElBeBranches] = useState<HTMLButtonElement | null>(null);

  const openBeBranches = Boolean(anchorElBeBranches);
  const beBranchesPopoverId = openBeBranches ? 'beBranchesPopoverId' : undefined;

  const updateSessionsStorageBeBranches = (clear?: boolean): void => {
    beBranches.forEach((beBranch: BranchPointer): void => {
      sessionStorage.setItem(beBranch.beBranchName, clear ? '' : beBranch.beBranchNumber);
    });
  };

  updateSessionsStorageBeBranches();
  return (
    <div>
      <Button
        aria-describedby={beBranchesPopoverId}
        variant="text"
        onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
          setAnchorElBeBranches(evt.currentTarget);
        }}
      >
        <span>BE Branches</span>
        <span>{!openBeBranches ? '⇧' : '⇩'}</span>
      </Button>
      <Popover
        id={beBranchesPopoverId}
        open={openBeBranches}
        anchorEl={anchorElBeBranches}
        onClose={(): void => setAnchorElBeBranches(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <table>
          <tbody>
            <tr>
              <td> Service </td>
              <td align="right"> Branch Number</td>
            </tr>
            {beBranches.map((beBranch: BranchPointer): ReactElement => {
              return (
                <tr key={beBranch.beBranchName + beBranch.beBranchNumber} className={classes.row}>
                  <td>{beBranch.beBranchName}</td>
                  <td align="right">
                    <Button
                      className={classes.branch}
                      onClick={(): void => {
                        let branchNumber = prompt(
                          `Enter Branch Number, now it is  "${
                            beBranches[beBranch.identifier].beBranchNumber
                          }`,
                        );
                        if (branchNumber !== null) {
                          branchNumber = branchNumber.replace(/\D/g, '');
                          const newBranch = beBranches[beBranch.identifier];
                          if (branchNumber.length > 0) {
                            branchNumber = 'SUP-' + branchNumber;
                          }
                          newBranch.beBranchNumber = branchNumber;
                          const newBranches = beBranches;
                          newBranches[beBranch.identifier] = newBranch;
                          updateSessionsStorageBeBranches();
                          location.reload();
                        }
                      }}
                    >
                      {beBranches[beBranch.identifier].beBranchNumber}
                    </Button>
                  </td>
                </tr>
              );
            })}

            <tr className={classes.buttonContainer}>
              <td colSpan={2} align="center">
                <BaseButton
                  onClick={(): void => {
                    updateSessionsStorageBeBranches(true);
                    location.reload();
                  }}
                >
                  Clear BE Branches
                </BaseButton>
              </td>
            </tr>
          </tbody>
        </table>
      </Popover>
    </div>
  );
};

export default BeBranches;
