import { properties } from 'Helpers/properties';

export const packingNoteBaseUrl = (url: string): string => {
  const branchNumber = sessionStorage.getItem('packingNotesBranch')
    ? sessionStorage.getItem('packingNotesBranch') + '/'
    : '';

  return properties.serviceApiURL + branchNumber + url;
};

export const newPackingNoteBaseUrl = (url: string): string => {
  const branchNumber = sessionStorage.getItem('newPackingNotesBranch')
    ? sessionStorage.getItem('newPackingNotesBranch') + '/pn/'
    : 'pn/';

  return properties.serviceApiURL + branchNumber + url;
};
