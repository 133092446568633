import React, { ReactElement, ChangeEvent, useEffect, useState, useMemo } from 'react';

import {
  TextField,
  FormControl,
  FormControlProps as MuiFormControlProps,
  Tooltip,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import { useField, useFormikContext } from 'formik';

import { baseProps, BaseFormProps } from 'Components/Shared/Forms/BaseForm/BaseForm';
import classes from 'Components/Shared/Forms/Styles/ValidatedStyles.module.scss';
import { validate } from 'Components/Shared/Forms/utils/FormUtils';

interface ValidatedTextfieldProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  FormControlProps?: MuiFormControlProps;
  tooltip?: string;
  onClearError?: (name: string) => void;
}

type componentProps = ValidatedTextfieldProps & TextFieldProps & BaseFormProps;

const ValidatedTextfield = (
  props: componentProps & baseProps & { variant?: 'standard' | 'outlined' | 'filled' },
): ReactElement => {
  const [localValue, setLocalValue] = useState(props.value ?? '');
  const { type, validators, FormControlProps, onClearError, ...rest } = props;

  const [field, meta, helpers] = useField({
    name: props.name,
    validate: (val: string): string | undefined => {
      const result = validate(val, validators, props.required);
      return result && result[0];
    },
  });

  useEffect((): void => {
    setLocalValue(props.value ?? '');
  }, [props.value]);

  const [localError, setLocalError] = useState<string | undefined>();
  const formContext = useFormikContext();

  useEffect(() => {
    if (field.value !== props.value && props.value) {
      helpers.setValue(props.value);
    }
  }, [field.value, props.value, helpers]);

  const validateRealTime = (value: string): string | undefined => {
    if (validators) {
      const errors = validate(value, validators, props.required);
      return errors?.[0];
    }
    return undefined;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const val = e.target.value;
    setLocalValue(val);

    const error = validateRealTime(val);
    setLocalError(error);

    helpers.setValue(val);
    if (onClearError) {
      onClearError(props.name);
    }

    props.onChange?.(e);
  };

  const helperText = useMemo(() => {
    if (localError) {
      return localError;
    }
    if (props.helperText !== undefined) {
      return props.helperText;
    }
    return meta.touched || formContext.submitCount > 0 ? meta.error || ' ' : ' ';
  }, [formContext.submitCount, meta.error, meta.touched, props.helperText, localError]);

  const isError = useMemo(() => {
    return !!localError || (!!meta.error && (meta.touched || formContext.submitCount > 0));
  }, [localError, meta.error, meta.touched, formContext.submitCount]);

  return (
    <FormControl
      fullWidth={props.fullWidth}
      error={isError}
      {...FormControlProps}
      variant={props.variant}
      data-testid="form"
    >
      <Tooltip title={props.tooltip ?? ''}>
        <TextField
          {...field}
          {...rest}
          type={type ?? 'text'}
          value={localValue}
          error={isError}
          onChange={handleChange}
          onBlur={() => helpers.setTouched(true)}
          helperText={helperText}
          margin={props.margin ?? 'dense'}
          slotProps={{
            htmlInput: {
              ...props.slotProps?.htmlInput,
              classes: { root: classes.whiteBg, error: classes.error },
              'data-testid': 'form-testfield',
            },
            inputLabel: { variant: props.variant },
            formHelperText: { classes: { root: classes.errorText } },
          }}
        />
      </Tooltip>
    </FormControl>
  );
};

export default ValidatedTextfield;
