import { ReactElement } from 'react';

import { Paper, Popper } from '@mui/material';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useHasPermission } from 'Models/Permission/permissionHelper';
import { NestedRouteConfig } from 'Views/routes';

import { NavigationItem } from './NavigationMenuItem/index';

interface NavigationMenuProps {
  anchorEl: Element | null;
  handleClose: () => void;
  open: boolean;
  childrenRoutes?: { [ChildRouteKey: string]: NestedRouteConfig };
}

export const NavigationMenu = ({
  anchorEl,
  handleClose,
  open,
  childrenRoutes,
}: NavigationMenuProps): ReactElement => {
  const flags = useFlags();
  const hasPermission = useHasPermission();

  return (
    <Popper anchorEl={anchorEl} open={open} placement="right-start" sx={{ zIndex: '99999' }}>
      <Paper>
        {childrenRoutes &&
          Object.keys(childrenRoutes).map((routeId, index) => {
            const route = childrenRoutes[routeId];
            const shouldDisplay = route.display !== false;
            const isFeatureFlagged = route.featureFlag
              ? !!flags[route.featureFlag as string]
              : true;
            if (!shouldDisplay || !isFeatureFlagged || !hasPermission(route.permission)) {
              return null;
            }

            return (
              <NavigationItem
                key={`navigation-item-${routeId}-${index}`}
                childrenRoutes={childrenRoutes}
                routeId={routeId}
                index={index}
                handleClose={handleClose}
              />
            );
          })}
      </Paper>
    </Popper>
  );
};
