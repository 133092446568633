import React, {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { TableContainer, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import makeStyles from '@mui/styles/makeStyles';

import useTableExpandByUrl from 'Components/Table/Components/Helpers/useTableExpandByUrl';
import TableToolbar from 'Components/Table/Components/TableToolbar';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { doExpandTableRow } from 'Components/Table/state/actions/expandRow';
import { doControlSelectedRows } from 'Components/Table/state/actions/keepSelectedRowsOfThisArray';
import { doSelectTableRow } from 'Components/Table/state/actions/selectRow';
import { doSetTableSort } from 'Components/Table/state/actions/setTableSort';
import { getIsRowExpanded } from 'Components/Table/state/selectors/getIsRowExpanded';

import { NestedKeyOf } from '../../_types_/NestedKeyOf';

import useColumnsHelper from './Components/Helpers/useColumnsHelper';
import useLayoutHelper from './Components/Helpers/useLayoutHelper';
import usePaginationHelper from './Components/Helpers/usePaginationHelper';
import useTableOptionsHelper from './Components/Helpers/useTableOptionsHelper';
import useTableRowHelper from './Components/Helpers/useTableRowHelper';
import useTableSearchHelper from './Components/Helpers/useTableSearchHelper';
import NoRecordsFound from './Components/TableErrors/NoRecordsFound';
import TableError from './Components/TableErrors/TableError';
import TableFooter from './Components/TableFooter';
import TableHeader from './Components/TableHeader';
import TableLoader from './Components/TableLoader';
import TableRow, { getNestedProperty } from './Components/TableRow';
import { TableState } from './state/_types_/TableState';
import { doSetPrimaryKey } from './state/actions/setPrimaryKey';
import { doSetShouldApplyFilters } from './state/actions/setShouldApplyFilters';
import { doSetTableRefetch } from './state/actions/setShouldRefetch';
import { doSetTableLoading } from './state/actions/setTableLoading';
import { doSetTableName } from './state/actions/setTableName';
import { useTableState } from './state/useTableState';

import { ExpandTableRef, TableProps } from '.';

const buildKeyFromTableName = (tableName: string) =>
  `${tableName.toLowerCase().replace(' ', '-')}:`;

const Table = <T extends TableRecord>(
  {
    title,
    tableName,
    primaryKey,
    options = {},
    rows,
    columns,
    standardActions,
    selectedElementIds,
    hyperControlledSelectedRows,
    selectedActions,
    onSelectionChange,
    pagination,
    loading,
    sort,
    onShouldRefreshData,
    onApplyFilters,
    search,
    defaultSearchLabel,
    defaultSearch,
    customStyling,
    error,
    ExpandedRowContent,
    scrollToEnd,
  }: TableProps<T>,
  ref: Ref<ExpandTableRef>,
): ReactElement => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    root: (state: TableState) => ({
      height: 'inherit',

      ...(state.tableOptions.toolbar && {
        borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px `,
      }),
    }),
  }));
  const { state, dispatch } = useTableState<T>();
  const classes = useStyles(state);
  const tableRef = useRef<HTMLTableElement>(null);

  const [hasScrolled, setHasScrolled] = useState(false);

  const primaryKeyOfRow = useCallback(
    (row: T) => (primaryKey.includes('.') ? getNestedProperty(row, primaryKey) : row[primaryKey]),
    [primaryKey],
  );

  useEffect((): void => {
    if (!state.isLoading && state.rows.length > 0) {
      if (hyperControlledSelectedRows) {
        dispatch(doControlSelectedRows(selectedElementIds ?? []));
      } else {
        selectedElementIds?.forEach((id) => dispatch(doSelectTableRow(id)));
      }
    }
  }, [
    dispatch,
    selectedElementIds,
    state.isLoading,
    state.rows.length,
    hyperControlledSelectedRows,
  ]);

  usePaginationHelper(pagination);
  useLayoutHelper();
  useColumnsHelper(columns);
  useTableOptionsHelper(options);
  useTableSearchHelper(search);
  useTableRowHelper(rows);

  useEffect(() => {
    dispatch(doSetPrimaryKey(primaryKey));
  }, [dispatch, primaryKey]);

  useEffect(() => {
    if (tableName !== state.tableName) {
      dispatch(doSetTableName(tableName));
    }
  }, [dispatch, state.tableName, tableName]);

  useEffect(() => {
    if (sort) {
      dispatch(doSetTableSort(sort));
    }
  }, [dispatch, sort]);

  useEffect(() => {
    if (loading !== undefined && state.isLoading !== loading) {
      dispatch(doSetTableLoading(loading));
    }
  }, [dispatch, loading, state.isLoading]);

  useEffect(() => {
    if (state.shouldRefetch) {
      if (onShouldRefreshData) {
        onShouldRefreshData();
      }
      dispatch(doSetTableRefetch(false));
    }
  }, [dispatch, onShouldRefreshData, state.shouldRefetch]);

  useEffect(() => {
    if (state.shouldApplyFilters) {
      (Object.keys(state.filters) as NestedKeyOf<T>[]).forEach((property) => {
        const currentFilter = state.filters[property];
        const columnFilter = state.columns.find((r) => r.property === property)?.filter;
        if (currentFilter !== undefined && columnFilter !== undefined) {
          columnFilter.setValue(currentFilter.value as typeof columnFilter.setValue.arguments);
        }
      });
      if (onApplyFilters) {
        onApplyFilters(state.filters);
      }
      dispatch(doSetShouldApplyFilters(false));
    }
  }, [dispatch, onApplyFilters, state.columns, state.filters, state.shouldApplyFilters]);

  useEffect(() => {
    if (hasScrolled || (state?.rows ?? []).length === 0 || !scrollToEnd) {
      return;
    }
    requestAnimationFrame(() => {
      setHasScrolled(true);
      if (tableRef && tableRef.current) {
        tableRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
      }
    });
  }, [hasScrolled, scrollToEnd, state?.rows]);

  useTableExpandByUrl();

  const handleCheckAll = useCallback(
    (checked: boolean) => {
      if (onSelectionChange) {
        state.rows.forEach((row) => {
          onSelectionChange(primaryKeyOfRow(row) as string | number, checked);
        });
      }
    },
    [onSelectionChange, primaryKeyOfRow, state.rows],
  );

  useImperativeHandle(ref, () => ({
    expand: (id: string) => {
      if (getIsRowExpanded(state, id)) {
        return;
      }
      dispatch(doExpandTableRow(id));
    },
  }));

  return (
    <>
      <TableToolbar<T>
        title={title}
        standardActions={standardActions}
        selectedActions={selectedActions}
        defaultSearchLabel={defaultSearchLabel}
        defaultSearch={defaultSearch}
      />

      {state.rows.length === 0 && !state.isLoading && !error && <NoRecordsFound />}
      {error && <TableError error={error} />}
      <TableContainer component={state.tableOptions.paper ? Paper : 'div'} className={classes.root}>
        <MuiTable stickyHeader={state.tableOptions.stickyHeader} ref={tableRef}>
          <TableHeader<T> onCheckAllCallback={handleCheckAll} />
          <TableLoader />
          <MuiTableBody>
            {state.rows.map((tableRow) => (
              <TableRow<T>
                onCheckCallback={onSelectionChange}
                key={`${buildKeyFromTableName(tableName)}${primaryKeyOfRow(tableRow)}`}
                row={tableRow}
                customRowClassName={customStyling?.customRowClassName}
                ExpandedRowContent={ExpandedRowContent}
                hyperControlledSelectedRows={hyperControlledSelectedRows}
              />
            ))}
          </MuiTableBody>
        </MuiTable>
      </TableContainer>
      <TableFooter />
    </>
  );
};

const TableWithRef = forwardRef(Table) as <T extends TableRecord>(
  p: TableProps<T> & { ref?: Ref<ExpandTableRef> },
) => ReactElement;

export default TableWithRef;
