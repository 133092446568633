import { useState, useCallback, ReactElement, useEffect, useMemo } from 'react';

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { useQuery } from '@apollo/client';
import { GET_RELEASES } from '_gqlQueries/releaseNotes.gql';
import { USER_SETTINKS_KEY } from 'Constants/user.constants';
import { GetReleasesQuery } from 'generated/releasetool_graphql';
import { UserConfig } from 'Models/User/_types_/UserConfig';
import { ReleaseItem } from 'ReleaseNotes/ReleaseItem';
import { ReleaseNotes } from 'ReleaseNotes/ReleaseNotes';

import GreetingDialog from './GreetingDialog/GreetingDialog';

const GreetingDialogs = (): ReactElement => {
  const ldFlags = useFlags();
  const ldClient = useLDClient();
  const [latestReleaseNote, setLatestReleaseNote] = useState<string>('');
  const storedConfig = localStorage.getItem(USER_SETTINKS_KEY);
  const [showingDialogIndex, setShowingDialogIndex] = useState(0);
  const [dialogs, setDialogs] = useState<ReactElement[]>([]);
  const [config, setConfig] = useState<UserConfig>(storedConfig ? JSON.parse(storedConfig) : {});
  const [ogConfig] = useState<UserConfig>(storedConfig ? JSON.parse(storedConfig) : {});
  const [closed, setClosed] = useState(false);

  const saveSettingToLocalStore = useCallback(
    (releaseNoteId: string, value: boolean): void => {
      const newConfig: UserConfig = {
        ...config,
        hideReleases: config.hideReleases ?? {},
      };
      newConfig.hideReleases[releaseNoteId] = value;
      localStorage.setItem(USER_SETTINKS_KEY, JSON.stringify(newConfig));
      setConfig(newConfig);
      setClosed(true);
      return;
    },
    [config],
  );

  const { data } = useQuery<GetReleasesQuery>(GET_RELEASES);

  const activeReleases = useMemo(() => data?.releases?.filter((x) => x?.released) ?? [], [data]);
  const last10Releases = useMemo(() => {
    const sorted = activeReleases.toSorted((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return b.releaseIndex - a.releaseIndex;
    });
    return sorted.slice(0, 10);
  }, [activeReleases]);

  const contentForDialog = useMemo(() => {
    const items: ReleaseItem[] = [];
    last10Releases.forEach((x) => {
      if (!x) {
        return;
      }
      items.push(
        ...x.items.map(
          (y): ReleaseItem => ({
            content: y.description,
            title: y.title,
          }),
        ),
      );
    });
    return <ReleaseNotes items={items} />;
  }, [last10Releases]);

  useEffect((): void => {
    if (dialogs.length > 0 || !ldFlags || ldClient?.getContext().anonymous) {
      return;
    }

    if (data?.releases) {
      const dialogBuilder: ReactElement[] = [];
      const activeReleases = data.releases.filter((x) => x?.released);
      const latestReleaseNoteIndex = '' + activeReleases[activeReleases.length - 1]?.releaseIndex;
      setLatestReleaseNote(latestReleaseNoteIndex);

      activeReleases.forEach((x, i) => {
        if (!x) {
          return;
        }
        dialogBuilder.push(
          <GreetingDialog
            showBack={i > 0}
            goBack={(): void => {
              setShowingDialogIndex((prevVal: number): number => prevVal - 1);
            }}
            showForward={i < activeReleases.length - 1}
            goForward={(): void => {
              setShowingDialogIndex((prevVal: number): number => prevVal + 1);
            }}
            key={`release-${i}`}
            title={`Latest note: ${x?.name}`}
            maxWidth="lg"
            staticHeight={'60%'}
            open={true}
            onClose={(hide: boolean): void => {
              saveSettingToLocalStore(latestReleaseNoteIndex, hide);
            }}
            content={() => contentForDialog}
          />,
        );
      });
      setDialogs(dialogBuilder);
      setShowingDialogIndex(dialogBuilder.length - 1);
    }
  }, [
    activeReleases,
    contentForDialog,
    data?.releases,
    dialogs.length,
    ldClient,
    ldFlags,
    saveSettingToLocalStore,
  ]);

  return (
    (!closed &&
      dialogs.length > 0 &&
      showingDialogIndex <= dialogs.length &&
      (!ogConfig.hideReleases ||
        (ogConfig.hideReleases && !ogConfig.hideReleases[latestReleaseNote])) &&
      dialogs[showingDialogIndex]) || <></>
  );
};

export default GreetingDialogs;
