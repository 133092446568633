import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { basicAuthToken, properties } from 'Helpers';

import Splash from 'Components/UI/Splash';
import { authState } from 'Hooks/useAuthHandler/state/_types/AuthState';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { AuthHandlerContext } from 'Hooks/useAuthHandler/state/authHandlerContext';
import { authHandlerReducer } from 'Hooks/useAuthHandler/state/useAuthReducer';

import { DataDogRumContext } from '../../Contexts/DataDogRumContext';

export const AuthHandlerProvider = (props: PropsWithChildren<unknown>): ReactElement => {
  const datadogRum = React.useContext(DataDogRumContext);

  const userConfig: authState = {
    config: {
      refreshUrl: `${properties.loginApiURL}oauth/token`,
      refreshTokenToken: basicAuthToken(),
      loginpath: '/login',
      noAccessPath: '/noAccess',
      datadogRum: datadogRum,
    },
    loadingComponent: <Splash />,
  };

  const [state, dispatch] = React.useReducer(authHandlerReducer, userConfig);
  const [token, setToken] = useState<Token | null>(null);
  const value = { state, dispatch };

  useEffect(() => {
    if (
      state.tokens &&
      state.tokens.accessToken !== token?.accessToken &&
      state.tokens.expires > (token?.expires ?? 0)
    ) {
      setToken(state.tokens);
    }
  }, [state.tokens, token]);

  const content = useMemo((): ReactNode => {
    return props.children;
  }, [props.children]);

  return <AuthHandlerContext.Provider value={value}>{content}</AuthHandlerContext.Provider>;
};
