export enum SELECT_TYPE {
  'ORDER',
  'STYLE',
  'MANUAL',
}

export const mapIdToSelectType = (detailsId: number): number => {
  switch (detailsId) {
    case 1:
      return SELECT_TYPE.ORDER;
    case 2:
      return SELECT_TYPE.STYLE;
    case 3:
      return SELECT_TYPE.MANUAL;
    default:
      return 0;
  }
};

export const mapSelectTypeToId = (selectType: SELECT_TYPE): number => {
  switch (selectType) {
    case SELECT_TYPE.ORDER:
      return 1;
    case SELECT_TYPE.STYLE:
      return 2;
    case SELECT_TYPE.MANUAL:
      return 3;
    default:
      return 0;
  }
};

export interface BasedOnSelection {
  basedOnType: SELECT_TYPE;
  value: string | undefined;
}

export enum BasedOnModalAction {
  'REDIRECT',
  'RETURN_VALUE',
}

export enum LineInfoGroup {
  'SALES_SAMPLE',
  'MEETING_SAMPLE',
}
