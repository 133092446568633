import { SetDetailsComment } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const updateDetailsComment = (
  updatedState: SampleProformaHeaderState,
  action: SetDetailsComment
): SampleProformaHeaderState => {
  return {
    ...updatedState,
    lineInfoGroup: updatedState.lineInfoGroup.map((line) => {
      if (line.groupId === action.groupId) {
        const updatesLines = [
          ...line.lines.map((line) => {
            if (line.lineId === action.lineId) {
              return {
                ...line,
                comment: action.value,
              };
            }
            return line;
          }),
        ];
        return {
          ...line,
          lines: updatesLines,
        };
      }
      return line;
    }),
  };
};
