import { ReactElement, useState, useEffect } from 'react';

import { DialogTitle, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { AllOrNone } from '_types_/AllOrNone';
import BaseButton from 'Components/Shared/BestButton/BaseButton';
import IconButton from 'Components/Shared/BestButton/IconButton';

import classes from './GreetingDialog.module.scss';

interface ForwardProp {
  showForward: boolean;
  goForward: () => void;
}

interface BackProp {
  showBack: boolean;
  goBack: () => void;
}

interface Props {
  open: boolean;
  title: string;
  content: () => ReactElement;
  onClose: (hide: boolean) => void;
  staticHeight?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const GreetingDialog = (
  props: Props & AllOrNone<ForwardProp> & AllOrNone<BackProp>
): ReactElement | null => {
  const [pathContainsPrinter, setPathContainsPrinter] = useState(false);
  const [hideDialog, setHideDialog] = useState(false);

  useEffect((): void => {
    setPathContainsPrinter(window.location.pathname.indexOf('printer') !== -1);
  }, []);

  const height = props.staticHeight
    ? { height: props.staticHeight, maxHeight: props.staticHeight }
    : null;

  const handleClose = (): void => {
    props.onClose(hideDialog);
  };

  if (!pathContainsPrinter) {
    return (
      <Dialog
        fullWidth
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={handleClose}
        PaperProps={{ style: { ...height } }}
        aria-labelledby="alert-dialog-guide-title"
        aria-describedby="alert-dialog-guide-description"
      >
        <DialogTitle id="alert-dialog-guide-title">
          <div className={classes.navigation}>
            <div>
              {props.showBack && props.goBack && (
                <IconButton
                  onClick={props.goBack}
                  iconName="goBack"
                  noMinWidth
                  noMargin
                  style={{ padding: '0px 4px 6px 0px' }}
                  size="large"
                />
              )}
            </div>
            <div style={{ flex: 1 }}>{props.title}</div>
            <div>
              {props.showForward && props.goForward && (
                <IconButton
                  onClick={props.goForward}
                  iconName="goForward"
                  noMinWidth
                  noMargin
                  style={{ padding: '0px 4px 6px 0px' }}
                  size="large"
                />
              )}
            </div>
          </div>
        </DialogTitle>
        {props.content()}
        <DialogActions className={classes.actions}>
          <div>
            <FormControlLabel
              control={<Checkbox />}
              checked={hideDialog}
              onChange={(_, checked: boolean): void => {
                setHideDialog(checked);
              }}
              label="Don't show this message again"
            />
          </div>
          <div>
            <BaseButton onClick={handleClose}>Close</BaseButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

export default GreetingDialog;
