import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import SortIconSvg from 'Assets/images/svg/SortIcon/sortIcon.svg';

const SortIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={SortIconSvg} viewBox="0 0 24 24" {...props} />
);

export default SortIcon;
