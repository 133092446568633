import { ReactElement, MouseEvent, useState } from 'react';

import { Button, Popover } from '@mui/material';

import { DD_ENV } from 'Helpers';

import { useGetDatabaseName } from 'Models/Environment/environmentHelper';

const BuildInfo = (): ReactElement => {
  const [anchorElFeatureFlags, setAnchorElFeatureFlags] = useState<HTMLButtonElement | null>(null);
  const getDatabaseName = useGetDatabaseName();

  const openBuildInfo = Boolean(anchorElFeatureFlags);
  const buildPopoverId = openBuildInfo ? 'buildPopoverId' : undefined;

  return (
    <>
      <Button
        aria-describedby={buildPopoverId}
        variant="text"
        onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
          setAnchorElFeatureFlags(evt.currentTarget);
        }}
      >
        <span>Build info&nbsp;</span>
        <span>{!openBuildInfo ? '⇧' : '⇩'}</span>
      </Button>
      <Popover
        id={buildPopoverId}
        open={openBuildInfo}
        anchorEl={anchorElFeatureFlags}
        onClose={(): void => setAnchorElFeatureFlags(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <table>
          <tr>
            <td>
              <span>Running&nbsp;</span>
              <a href="https://vitejs.dev/" target="_blank" rel="noreferrer">
                vitejs
              </a>
            </td>
          </tr>
          <tr>
            <td>Build time:</td>
            <td>{__BUILD_DATE__}</td>
          </tr>
          <tr>
            <td>DB:</td>
            <td>{getDatabaseName}</td>
          </tr>
          <tr>
            <td>DD env: </td>
            <td>{DD_ENV}</td>
          </tr>
        </table>
      </Popover>
    </>
  );
};

export default BuildInfo;
