import { useCallback, useEffect, useState } from 'react';

import { SET_AD_ACCOUNTS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { useGetLinkedProfiles } from 'UserAuthentication/_LoginUserServices/hooks/functionalities/useGetLinkedProfiles';

export const useFetchGetLinkedProfiles = () => {
  const { authState, authDispatch } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [fetchedForToken, setFetchedForToken] = useState<string | undefined>();
  const [fetch, { loading: loadingQuery, error, data }] = useGetLinkedProfiles(
    authState.tokens?.accessToken ?? '',
  );

  const refetch = useCallback(() => {
    if (authState.tokens?.accessToken === undefined) {
      return Promise.reject(new Error('useGetLinkedProfiles: No access token'));
    }

    fetch();
  }, [authState.tokens?.accessToken, fetch]);

  useEffect(() => {
    if (
      authState.profiles !== undefined ||
      authState.tokens?.accessToken === undefined ||
      fetchedForToken === authState.tokens.accessToken ||
      loading
    ) {
      return;
    }
    setFetchedForToken(authState.tokens.accessToken);
    refetch();
  }, [authState.profiles, authState.tokens?.accessToken, fetchedForToken, loading, refetch]);

  useEffect(() => {
    if (loadingQuery) {
      setLoading(true);
      return;
    }
    if (error) {
      setLoading(false);
      authDispatch({
        type: SET_AD_ACCOUNTS,
        adAccounts: undefined,
      });
      return;
    }
    if (data) {
      setLoading(false);
      authDispatch({
        type: SET_AD_ACCOUNTS,
        adAccounts: data ?? undefined,
      });
    }
  }, [authDispatch, data, error, loadingQuery]);

  return {
    refetch,
  };
};
