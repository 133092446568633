import { ReactElement, useEffect, useState } from 'react';

import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useGetPapskiltQuery, usePapskiltChangedSubscription } from 'generated/papskilt_graphql';

const PAPSKILT_PROJECT_ID = '80e9e02e-a42d-4a88-b300-0e71fd14b650';

const Papskilt = (): ReactElement | null => {
  const [message, setMessage] = useState<string | undefined>();

  const { data } = useGetPapskiltQuery({
    fetchPolicy: 'no-cache',
    variables: {
      projectId: PAPSKILT_PROJECT_ID,
    },
  });

  const { data: papskiltSubscriptionData } = usePapskiltChangedSubscription({
    variables: {
      projectId: PAPSKILT_PROJECT_ID,
    },
  });

  useEffect((): void => {
    setMessage(papskiltSubscriptionData?.papskiltChanged.payload.message ?? undefined);
  }, [papskiltSubscriptionData]);

  useEffect((): void => {
    setMessage(data?.papskilt.message ?? undefined);
  }, [data?.papskilt.message]);

  return (
    <Grid container flexDirection="column" justifyContent="center" rowGap={1} mb={2}>
      {message && (
        <Grid>
          <Alert severity="warning">{message}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default Papskilt;
