import { Suspense, useMemo } from 'react';

import {
  CREATE_SAMPLE_COMMERCIAL_INVOICE_TO,
  CREATE_SAMPLE_PROFORMA_INVOICE_TO,
  INBOUND_INVOICE_TO,
  INVOICE_TO,
  OUTBOUND_INVOICE_TO,
  SAMPLE_COMMERCIAL_INVOICE_TO,
  SAMPLE_PROFORMA_INVOICE_TO,
} from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import { useHasPermission } from 'Models/Permission/permissionHelper';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { getIconByIconName } from '../../Components/Shared/Icons/Icons';
import { PERMISSION } from '../../Constants/permissions.constants';
import { useGetFeatureFlag } from '../../FeatureFlags/FeatureFlags';
import Invoice from '../../Invoice/index';
import NoAccess from '../NoAccess/NoAccess';

import { RouteConfig } from './index';

const InboundInvoice = lazyImport(() => import('Invoice/Inbound'));
const OutboundInvoice = lazyImport(() => import('Invoice/Outbound'));
const SampleProformaInvoice = lazyImport(() => import('Invoice/SampleProforma/index'));
const CreateSampleProformaInvoice = lazyImport(
  () => import('Invoice/SampleProforma/CreateSampleProforma/index'),
);
const SampleCommercialInvoice = lazyImport(() => import('Invoice/SampleCommercial'));
const CreateSampleCommercialInvoice = lazyImport(
  () => import('Invoice/SampleCommercial/CreateSampleCommercial/index'),
);

export const useINVOICE_ROUTES = (): RouteConfig => {
  const hasPermission = useHasPermission();
  const getFeatureFlag = useGetFeatureFlag();
  return useMemo(
    () => ({
      label: 'Invoice',
      to: INVOICE_TO,
      icon: getIconByIconName('accountBalance'),
      color: 'darkgreen',
      component: (
        <Suspense fallback={<LazyLoadingMessage view="Invoice" />}>
          <Invoice />
        </Suspense>
      ),
      permission: [PERMISSION.VP_INVOICE_INBOUND_VIEW, PERMISSION.VP_PROFORMA_INVOICE_VIEW],
      fallbackComponent: NoAccess,
      children: {
        Inbound: {
          display: true,
          label: 'Create Purchase Order Invoice',
          to: INBOUND_INVOICE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Create Purchase Order Invoice" />}>
              <InboundInvoice />
            </Suspense>
          ),
          permission: PERMISSION.VP_INVOICE_INBOUND_VIEW,
          fallbackComponent: NoAccess,
          featureFlag: getFeatureFlag('invoice-inbound'),
        },
        Outbound: {
          display: true,
          label: 'Purchase Order Invoice',
          to: OUTBOUND_INVOICE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Purchase Order Invoice" />}>
              <OutboundInvoice />
            </Suspense>
          ),
          permission: PERMISSION.VP_INVOICE_OUTBOUND_VIEW,
          fallbackComponent: NoAccess,
          featureFlag: getFeatureFlag('invoice-outbound'),
        },
        SampleProforma: {
          display: hasPermission(PERMISSION.VP_PROFORMA_INVOICE_VIEW),
          label: 'Sample Proforma Invoice',
          to: SAMPLE_PROFORMA_INVOICE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Sample Proforma Invoice'" />}>
              <SampleProformaInvoice />
            </Suspense>
          ),
          fallbackComponent: NoAccess,
          featureFlag: getFeatureFlag('sample-proforma-invoice'),
          permission: PERMISSION.VP_PROFORMA_INVOICE_VIEW,
        },
        CreateSampleProforma: {
          display: false,
          label: 'Create Sample Proforma Invoice',
          to: CREATE_SAMPLE_PROFORMA_INVOICE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Create Sample Proforma Invoice" />}>
              <CreateSampleProformaInvoice />
            </Suspense>
          ),
          fallbackComponent: NoAccess,
          featureFlag: getFeatureFlag('sample-proforma-invoice'),
          permission: PERMISSION.VP_PROFORMA_INVOICE_CREATE,
        },
        SampleCommercial: {
          display: hasPermission(PERMISSION.VP_COMMERCIAL_INVOICE_VIEW),
          label: 'Sample Commercial Invoice',
          to: SAMPLE_COMMERCIAL_INVOICE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Sample Commercial Invoice" />}>
              <SampleCommercialInvoice />
            </Suspense>
          ),
          fallbackComponent: NoAccess,
          featureFlag: getFeatureFlag('sample-commercial-invoice'),
          permission: PERMISSION.VP_COMMERCIAL_INVOICE_VIEW,
        },
        CreateSampleCommercial: {
          display: false,
          label: 'Create Sample Commercial Invoice',
          to: CREATE_SAMPLE_COMMERCIAL_INVOICE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Create Sample Commercial Invoice" />}>
              <CreateSampleCommercialInvoice />
            </Suspense>
          ),
          fallbackComponent: NoAccess,
          featureFlag: getFeatureFlag('sample-commercial-invoice'),
          permission: PERMISSION.VP_COMMERCIAL_INVOICE_CREATE,
        },
      },
    }),
    [getFeatureFlag, hasPermission],
  );
};
