import { ReactElement, useState, PropsWithChildren } from 'react';

import { Dialog } from '@mui/material';

import CloseDialogButton from 'Components/Shared/CloseDialogButton/CloseDialogButton';

interface NewsViewerProps {
  modal: ReactElement;
  clickable: boolean;
}
const NewsViewerModal = (props: PropsWithChildren<NewsViewerProps>): ReactElement => {
  const [showConfirmedOrder, setshowConfirmedOrder] = useState(false);

  return (
    <>
      <div
        onClick={(): void => {
          if (props.clickable) {
            setshowConfirmedOrder(true);
          }
        }}
      >
        {props.children}
      </div>
      <Dialog
        scroll="body"
        fullWidth
        open={showConfirmedOrder}
        onClose={(): void => setshowConfirmedOrder(false)}
      >
        <CloseDialogButton onClose={(): void => setshowConfirmedOrder(false)} />
        {props.modal}
      </Dialog>
    </>
  );
};

export default NewsViewerModal;
