class IdAndNameValueObject {
  private readonly id: number;
  private readonly name: string;

  constructor(id: number | undefined | null, name: string | undefined | null) {
    if (id === undefined || id === null) {
      throw new Error('Id is required');
    }
    if (name === undefined || name === null) {
      throw new Error('Name is required');
    }
    this.id = id;
    this.name = name;
  }

  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }
}

export default IdAndNameValueObject;
