import { useCallback, useEffect, useState } from 'react';

import axios, { AxiosError } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import wait from 'waait';

import { datadogRum } from '@datadog/browser-rum';
import { UPDATE_ACCESS_TOKEN } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useRefreshToken } from 'Hooks/useAuthHandler/helpers/useRefreshToken';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import useErrorHandler from 'Hooks/useAuthHandler/useErrorHandler';

const useAxiosInterceptors = (): boolean => {
  const [_requestInterceptor, setRequestInterceptor] = useState<number | undefined>();
  const [_authInterceptor, setAuthInterceptor] = useState<number | undefined>();
  const [_errorInterceptor, setErrorInterceptor] = useState<number | undefined>();
  const { authState, authDispatch } = useAuthState();
  const errorHandler = useErrorHandler();
  const { refreshToken, logoutUser } = useRefreshToken();

  useEffect((): void => {
    setErrorInterceptor((prevInterceptorNumber) => {
      if (prevInterceptorNumber !== undefined) {
        axios.interceptors.response.eject(prevInterceptorNumber);
      }
      return axios.interceptors.response.use(undefined, (error: AxiosError) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        return errorHandler(error);
      });
    });
  }, [errorHandler]);

  const getAccessToken = useCallback(() => {
    return authState.tokens?.accessToken;
  }, [authState.tokens?.accessToken]);

  const refreshAuthLogic = useCallback(
    async (failedRequest: { response: { config: { headers: { [x: string]: string } } } }) => {
      if (getAccessToken() === undefined) {
        // If we don't have access token, of course we don't have refresh token neither
        return;
      }
      if (authState.tokens?.refreshToken === undefined) {
        logoutUser();
        datadogRum.addError(`No refresh token provided ${authState.tokens}`);
        throw new Error('No refresh token provided');
      }
      return await refreshToken()
        .then(async (tokenRefreshResponse) => {
          authDispatch({
            type: UPDATE_ACCESS_TOKEN,
            accessToken: tokenRefreshResponse.data.access_token,
            expires: new Date().getTime() + 1000 * tokenRefreshResponse.data.expires_in,
          });
          await wait(1); //Give the request interceptor time to do its magic （◞‸◟）
          datadogRum.addAction('Token refreshed');

          failedRequest.response.config.headers['Authorization'] =
            'Bearer ' + tokenRefreshResponse.data.access_token;
          return failedRequest;
        })
        .catch((error) => {
          datadogRum?.addError(`Unable to refresh token :( ${error}`);
          logoutUser();
          throw new Error('Unable to refresh token :(');
        });
    },
    [authDispatch, authState.tokens, getAccessToken, logoutUser, refreshToken],
  );

  useEffect((): void => {
    setAuthInterceptor((prevInterceptorNumber) => {
      if (prevInterceptorNumber !== undefined) {
        axios.interceptors.response.eject(prevInterceptorNumber);
      }
      return createAuthRefreshInterceptor(axios, refreshAuthLogic, {});
    });
  }, [refreshAuthLogic]);

  useEffect((): void => {
    setRequestInterceptor((prevValue) => {
      if (prevValue !== undefined) {
        axios.interceptors.request.eject(prevValue);
      }
      const accesstoken = getAccessToken();
      const newInterceptor = axios.interceptors.request.use((request) => {
        if (!accesstoken || request.headers.Authorization) {
          return request;
        }
        request.headers.Authorization = 'Bearer ' + accesstoken;
        return request;
      });
      if (prevValue !== undefined) {
        axios.interceptors.request.eject(prevValue);
      }
      return newInterceptor;
    });
  }, [getAccessToken]);

  return true;
};
export default useAxiosInterceptors;
