import { areAllValuesTheSame } from 'Helpers/util';
import { UpdateDetailsPricePrPcs } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const updateDetailsQuantityPricePrPcs = (
  updatedState: SampleProformaHeaderState,
  action: UpdateDetailsPricePrPcs
): SampleProformaHeaderState => {
  return {
    ...updatedState,
    lineInfoGroup: updatedState.lineInfoGroup.map((line) => {
      if (line.groupId === action.groupId) {
        const updatesLines = [
          ...line.lines.map((line) => {
            if (line.lineId === action.lineId) {
              return {
                ...line,
                pricePrPcs: {
                  editable: line.pricePrPcs?.editable ?? true,
                  value: action.value,
                  mixed: false,
                  hasError: action.hasError,
                },
              };
            }
            return line;
          }),
        ];

        const isMixed = !areAllValuesTheSame(updatesLines, 'pricePrPcs', 'value');

        return {
          ...line,
          pricePrPcs: {
            editable: line.pricePrPcs?.editable ?? true,
            value: isMixed ? undefined : updatesLines[0].pricePrPcs?.value,
            mixed: isMixed,
            hasError: action.hasError,
          },
          lines: updatesLines,
        };
      }
      return line;
    }),
  };
};
