
import _ from 'lodash';

import { SELECT_TYPE } from 'Invoice/SampleProforma/CreateSampleProforma/BasedOnModal/_types';
import { SetSampleProformaHeaderBrand } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const updateBrand = (
  updatedState: SampleProformaHeaderState,
  action: SetSampleProformaHeaderBrand
): SampleProformaHeaderState => {
  const header = updatedState.headerInfo?.setBrand(action.value);
  return {
    ...updatedState,
    headerInfo: _.cloneDeep(header),
    lineInfoGroup: updatedState.lineInfoGroup.map((x) => {
      return {
        ...x,
        lines: x.lines.map((line) => {
          if (line.collection?.from === SELECT_TYPE.MANUAL) {
            return {
              ...line,
              collection: undefined,
            };
          }
          return line;
        }),
      };
    }),
  };
};
