import { ReactElement } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100px',
    height: '100px',
  },
});

interface PropType {
  loading: boolean;
}

const LoadingDialog = ({ loading }: PropType): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={loading}
        style={{
          zIndex: 1198,
        }}
        classes={{
          paper: classes.paper,
        }}
        color="secondary"
      >
        <CircularProgress />
      </Dialog>
    </>
  );
};
export default LoadingDialog;
