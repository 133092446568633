import React, { ReactElement } from 'react';

import { Close } from '@mui/icons-material';
import { DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import classes from './SupportModal.module.scss';

interface PropType {
  handleClose: () => void;
}

const SupportModalIndex = ({ handleClose }: PropType): ReactElement | null => {
  const closeModal = (): void => {
    handleClose();
  };

  return (
    <Dialog
      open
      aria-labelledby="support-index-dialog-title"
      aria-describedby="support-index-dialog-description"
    >
      <DialogTitle id="support-index-dialog-title">
        <span>Please share your experience with us.</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent id="support-index-dialog-description">
        <DialogContentText>
          Help us improve your Vendor Portal workflow by reporting experienced issues and missing
          features. We value your opinion, so feel free to share them with us.
        </DialogContentText>
        <br />
        <DialogContentText>
          <div>
            <span>Send an email to&nbsp;</span>
            <b>techsourcing@bestseller.com</b>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SupportModalIndex;
