import { useReducer } from 'react';


import { cloneDeep } from 'lodash';

import { CHANGE_PAGE } from 'Components/Table/state/actions/changePage';
import {
  CONTROL_SELECTED_ROWS,
  applyKeepSelectedRowsOfThisArray,
} from 'Components/Table/state/actions/keepSelectedRowsOfThisArray';

import { TableRecord } from './_types_/TableRecord';
import { TableState } from './_types_/TableState';
import { TableStateDispatch } from './_types_/TableStateDispatch';
import { CHANGE_FILTER, applyChangeFilter } from './actions/changeFilter';
import { applyChangePage } from './actions/changePage';
import { CHANGE_PAGE_SIZE, applyChangePageSize } from './actions/changePageSize';
import { CHANGE_TABLE_ROW, applyChangeTableRow } from './actions/changeRow';
import { CLEAR_FILTER, applyClearFilter } from './actions/clearFilter';
import { EXPAND_TABLE_ROW, applyExpandTableRow } from './actions/expandRow';
import { REMOVE_TABLE_ROW, applyRemoveTableRow } from './actions/removeRow';
import {
  SELECT_ALL_TABLE_ROWS_IN_VIEW,
  applySelectAllTableRowsInView,
} from './actions/selectAllRowsInView';
import { SELECT_TABLE_ROW, applySelectTableRow } from './actions/selectRow';
import { SET_PRIMARY_KEY, applySetPrimaryKey } from './actions/setPrimaryKey';
import { SET_TABLE_SEARCH, applySetTableSearch } from './actions/setSearch';
import {
  SET_SHOULD_APPLY_FILTERS,
  applySetShouldApplyFilters,
} from './actions/setShouldApplyFilters';
import { SET_TABLE_REFETCH, applySetTableRefetch } from './actions/setShouldRefetch';
import {
  applySetShouldTableCollapse,
  SET_SHOULD_TABLE_COLLAPSE,
} from './actions/setShouldTableCollapse';
import { SET_TABLE_COLUMNS, applySetTableColumns } from './actions/setTableColumns';
import { SET_TABLE_LOADING, applySetTableLoading } from './actions/setTableLoading';
import { SET_TABLE_NAME, applySetTableName } from './actions/setTableName';
import {
  SET_TABLE_OPTIONS,
  applySetTableOptions,
  DEFAULT_ROWS_PER_PAGE,
} from './actions/setTableOptions';
import { SET_TABLE_PAGINATION, applySetTablePagination } from './actions/setTablePagination';
import { applySetTableRows, SET_TABLE_ROWS } from './actions/setTableRows';
import { applySetTableSort, SET_TABLE_SORT } from './actions/setTableSort';
import { TableStateActions } from './tableStateActions';

const initialState: TableState = {
  tableName: '',
  primaryKey: '',
  tableOptions: {
    toolbar: true,
    stickyHeader: true,
    paper: true,
    selectable: false,
    selectAll: false,
    disableRefetchButton: false,
    searchBar: false,
    responsive: true,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE,
    expandable: false,
    showTableName: true,
    draggable: true,
    dontAddExpandToUrl: false,
    showExpandAllButton: false,
  },
  rows: [],
  columns: [],
  shouldRefetch: false,
  isLoading: false,
  shouldCollapse: false,
  shouldApplyFilters: false,
  searchValue: undefined,
  filters: {},
  selectedRows: {},
  expandedRows: [],
};

const reducer = <T extends TableRecord = TableRecord>(
  state: TableState,
  action: TableStateActions<T>
): TableState => {
  const immutableState = cloneDeep(state);

  switch (action.type) {
    case SET_TABLE_COLUMNS:
      return applySetTableColumns(immutableState, action);
    case SET_TABLE_OPTIONS:
      return applySetTableOptions(immutableState, action);
    case SET_TABLE_PAGINATION:
      return applySetTablePagination(immutableState, action);
    case SET_TABLE_SORT:
      return applySetTableSort(immutableState, action);
    case SET_TABLE_LOADING:
      return applySetTableLoading(immutableState, action);
    case SET_TABLE_REFETCH:
      return applySetTableRefetch(immutableState, action);
    case SET_SHOULD_APPLY_FILTERS:
      return applySetShouldApplyFilters(immutableState, action);
    case CHANGE_PAGE_SIZE:
      return applyChangePageSize(immutableState, action);
    case CHANGE_PAGE:
      return applyChangePage(immutableState, action);
    case SET_SHOULD_TABLE_COLLAPSE:
      return applySetShouldTableCollapse(immutableState, action);
    case CHANGE_FILTER:
      return applyChangeFilter(immutableState, action);
    case CLEAR_FILTER:
      return applyClearFilter(immutableState, action);
    case SET_TABLE_ROWS:
      return applySetTableRows(immutableState, action);
    case CHANGE_TABLE_ROW:
      return applyChangeTableRow(immutableState, action);
    case SET_PRIMARY_KEY:
      return applySetPrimaryKey(immutableState, action);
    case SET_TABLE_SEARCH:
      return applySetTableSearch(immutableState, action);
    case SELECT_TABLE_ROW:
      return applySelectTableRow(immutableState, action);
    case SELECT_ALL_TABLE_ROWS_IN_VIEW:
      return applySelectAllTableRowsInView(immutableState, action);
    case EXPAND_TABLE_ROW:
      return applyExpandTableRow(immutableState, action);
    case SET_TABLE_NAME:
      return applySetTableName(immutableState, action);
    case REMOVE_TABLE_ROW:
      return applyRemoveTableRow(immutableState, action);
    case CONTROL_SELECTED_ROWS:
      return applyKeepSelectedRowsOfThisArray(immutableState, action);

    default:
      return state;
  }
};

export const useTableStateReducer = (): [TableState, TableStateDispatch] => {
  return useReducer(reducer, initialState);
};
