import { ReactElement, ReactNode } from 'react';

import { Refresh, Search } from '@mui/icons-material';
import { IconButton, lighten, Toolbar, Typography, useTheme, Zoom } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';

import SearchBar from 'Components/Shared/SearchBar';
import { SearchValues } from 'Components/Shared/SearchBar/_types';
import ExportableButtons from 'Components/Table/Components/ActionButtons/ExportableButtons';
import ExpandAllButton from 'Components/Table/Components/ToolbarActions/ExpandAllButton';
import { doChangePage } from 'Components/Table/state/actions/changePage';
import { doSetTableRefetch } from 'Components/Table/state/actions/setShouldRefetch';

import { SelectedAction } from '../state/_types_/SelectedTableActions';
import { TableRecord } from '../state/_types_/TableRecord';
import { TableState } from '../state/_types_/TableState';
import { doSetTableSearch } from '../state/actions/setSearch';
import { useTableState } from '../state/useTableState';

import TableFilterModal from './TableFilterModal';

interface Props<T extends TableRecord> {
  standardActions?: ReactNode[];
  selectedActions?: SelectedAction<T>[];
  title?: string;
  defaultSearchLabel?: string;
  defaultSearch?: string;
}

const TableToolbar = <T extends TableRecord>(props: Props<T>): ReactElement => {
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    root: (state: TableState) => ({
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
      ...(state.tableOptions.stickyHeader && {
        position: 'sticky',
        top: 0,
      }),
    }),
    highlight:
      theme.palette.mode === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1',
    },
    searchButton: {
      // Height of the searchbar
      height: 'calc(0.9rem + 38px)',
      width: 'calc(0.9rem + 38px)',
      border: `1px #e1e0de solid;`,
      marginRight: 8,
    },
  }));
  const { state, dispatch } = useTableState<T>();
  const classes = useStyles(state);

  const searchValues: SearchValues<string> = {};
  state.columns.forEach((r) => {
    if (r.search) {
      searchValues[r.property] = {
        label: r.label,
        value: r.search.value,
        handler: r.search.handler,
      };
    }
  });

  const disabledSearchButton =
    state.isLoading ||
    state.shouldRefetch ||
    Object.values(searchValues).every((r) => r.value === null) ||
    state.searchValue === undefined;

  if (!state.tableOptions.toolbar) {
    return <></>;
  }

  /** Placeholder for when Select functionality is added */
  const numSelected = Object.keys(state.selectedRows).length;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      component={state.tableOptions.paper ? Paper : 'div'}
    >
      {props.title && (
        <Typography color="inherit" variant="h6" component="div">
          {props.title}
        </Typography>
      )}

      {state.tableOptions.showExpandAllButton && <ExpandAllButton />}

      {props.standardActions}
      {numSelected > 0 && (
        <>
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
          {props.selectedActions?.map((Comp, index) => (
            <Comp
              key={`${state.tableName.toLowerCase()}-table-selectedAction-${index}`}
              selectedRows={state.selectedRows}
              dispatch={dispatch}
            />
          ))}
          <ExportableButtons />
        </>
      )}

      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {state.tableOptions.showTableName && state.tableName}
      </Typography>
      {!state.tableOptions.disableRefetchButton && (
        <Zoom in={!state.isLoading && !state.shouldRefetch}>
          <Tooltip title="Refresh">
            <IconButton
              onClick={() => {
                dispatch(doSetTableRefetch(true));
                dispatch(doChangePage(0));
              }}
              size="large"
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        </Zoom>
      )}
      {state.tableOptions.searchBar && (
        <>
          <SearchBar
            searchFields={Object.keys(searchValues)}
            searchValues={searchValues}
            icon={false}
            defaultSearch={props.defaultSearch}
            defaultSearchField={{
              label: props.defaultSearchLabel ?? 'Search',
              value: state.searchValue,
              handler: (value: string | null) => {
                if (value !== state.searchValue) {
                  dispatch(doSetTableSearch(value ?? undefined));
                  dispatch(doChangePage(0));
                }
              },
            }}
            onSearch={() => {
              dispatch(doSetTableRefetch(true));
              dispatch(doChangePage(0));
            }}
          />
          <Tooltip title="Search" disableHoverListener={disabledSearchButton}>
            <div>
              <IconButton
                onClick={() => {
                  dispatch(doSetTableRefetch(true));
                  dispatch(doChangePage(0));
                }}
                disabled={disabledSearchButton}
                size="medium"
                className={classes.searchButton}
              >
                <Search />
              </IconButton>
            </div>
          </Tooltip>
        </>
      )}
      <TableFilterModal />
    </Toolbar>
  );
};

export default TableToolbar;
