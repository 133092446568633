import { TableState } from '../_types_/TableState';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export interface ChangePage {
  type: typeof CHANGE_PAGE;
  page: number;
}

export const doChangePage = (page: number): ChangePage => ({
  type: CHANGE_PAGE,
  page,
});

export const applyChangePage = (newState: TableState, action: ChangePage): TableState => {
  if (newState.pagination) {
    newState.pagination.currentPage = action.page;
  }
  return newState;
};
