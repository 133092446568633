import { Component, ReactElement } from 'react';

import { SvgIcon } from '@mui/material';

import Icon from 'Assets/images/svg/OrderIcon/orderHistory.svg';

class OrderHistoryIcon extends Component {
  render(): ReactElement {
    return <SvgIcon {...this.props} component={Icon} viewBox="0 0 24 24" />;
  }
}

export default OrderHistoryIcon;
