import { ReactElement, useMemo } from 'react';

import { Tooltip, Checkbox } from '@mui/material';

import { CheckboxStateEnum } from '../../../../_types_/CheckBoxState';
import { doSelectAllTableRowsInView } from '../../state/actions/selectAllRowsInView';
import { getAreAllRowsInViewSelected } from '../../state/selectors/getAreAllRowsInViewSelected';
import { useTableState } from '../../state/useTableState';

const SelectAll = ({
  onCheckAllCallback,
}: {
  onCheckAllCallback?: (checked: boolean) => void;
}): ReactElement => {
  const { state, dispatch } = useTableState();
  const checkboxState = getAreAllRowsInViewSelected(state);

  const allChecked = useMemo(() => checkboxState === CheckboxStateEnum.ALL, [checkboxState]);

  if (!state.tableOptions.selectAll || !state.tableOptions.selectable) {
    return <></>;
  }

  const selectAllRows = () => dispatch(doSelectAllTableRowsInView());

  return (
    <Tooltip title={allChecked ? 'Unselect all' : 'Select All'}>
      <Checkbox
        onClick={selectAllRows}
        indeterminate={checkboxState === CheckboxStateEnum.SOME}
        checked={allChecked}
        onChange={(_, checked) => onCheckAllCallback?.(checked)}
      />
    </Tooltip>
  );
};

export default SelectAll;
