import { ReactElement, useCallback, useState } from 'react';

import Grid from '@mui/material/Grid2';

import BeBranchInfo from 'UserAuthentication/Login/BeBranchInfo';
import CredentialsForm from 'UserAuthentication/Login/LoginScreens/CredentialsForm';
import Welcome from 'UserAuthentication/Login/LoginScreens/Welcome';

const LoginScreen = (): ReactElement => {
  const [showCredentialsForm, setShowCredentialsForm] = useState<boolean>(false);

  const goToCredentialsForm = useCallback(() => {
    setShowCredentialsForm(true);
  }, []);

  const goToWelcome = useCallback(() => {
    setShowCredentialsForm(false);
  }, []);

  return (
    <Grid
      container
      flexDirection={'column'}
      justifyContent="center"
      alignContent="center"
      height="100%"
      width="100%"
    >
      <Grid>
        <BeBranchInfo />
      </Grid>
      <Grid>
        {showCredentialsForm ? (
          <CredentialsForm goToWelcome={goToWelcome} />
        ) : (
          <Welcome goToCredentialsForm={goToCredentialsForm} />
        )}
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
