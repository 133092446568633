import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TransitionProps } from '@mui/material/transitions';

import { useReduxDispatch } from 'Helpers';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import IconButton from 'Components/Shared/BestButton/IconButton';
import LoadingDialog from 'Components/Shared/LoadingDialog';
import { PERMISSION } from 'Constants/permissions.constants';
import { News } from 'Models/News/_types_/News';
import NewsItem from 'Models/News/NewsItem/NewsItem';
import { newsService } from 'Services/news.service';
import { newsActions } from 'Store/actions/news.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

import classes from './NewsList.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NewsList = (): ReactElement => {
  const location = useLocation();
  const dashboardContext = useMemo(
    (): boolean => location.pathname.includes('/dashboard'),
    [location.pathname],
  );
  const newsReducer = useSelector((root: RootReducerState) => root.newsReducer);
  const dispatch = useReduxDispatch();
  const user = useSelector((root: RootReducerState) => root.userReducer.user);
  const [loading, setLoading] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [selectedNewsItem, setSelectedNewsItem] = useState<News>();

  const getNews = useCallback((): void => {
    setLoading(true);
    newsService
      .fetchNews()
      .then((res): void => {
        dispatch(newsActions.fetchNews(res));
        setLoading(false);
      })
      .catch((): void => setLoading(false));
  }, [dispatch]);

  const deleteHandler = (newsItem: News): void => {
    setShowDeletePrompt(true);
    setSelectedNewsItem(newsItem);
  };

  const showDeletePromptDialog = (): ReactElement | null => {
    if (selectedNewsItem !== undefined) {
      const newsItem = selectedNewsItem;
      return (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <span>Are you sure you want to delete the following news?</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {newsItem.title}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="buttonContainer">
              <IconButton
                onClick={(): void => setShowDeletePrompt(false)}
                iconName="goBack"
                colorSchema={{ foreground: 'black', background: 'white' }}
              />
              <IconButton
                onClick={(): void => {
                  setShowDeletePrompt(false);
                  newsActions.deleteNews(newsItem.id).then((): void => {
                    getNews();
                  });
                }}
                iconName="delete"
                permission={PERMISSION.VP_DELETE_NEWS}
                securityType="disabled"
                colorSchema={{ foreground: 'black', background: 'white' }}
              />
            </div>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  const displayNewsFromReducer = (): ReactNode => {
    const newsInGrid = newsReducer.news.map(
      (news: News): ReactElement => (
        <Grid size={{ xs: 12, sm: dashboardContext ? 12 : 6 }} key={news.id}>
          <NewsItem
            news={news}
            deleteHandler={deleteHandler}
            userPermission={user ? user.permissions : null}
            dashboardContext={dashboardContext}
          />
        </Grid>
      ),
    );
    return newsInGrid;
  };

  const renderNews = (): ReactElement => {
    return (
      <div>
        <Grid
          container
          spacing={!dashboardContext ? 10 : undefined}
          className={!dashboardContext ? classes.newsGrid : ''}
        >
          {displayNewsFromReducer()}
        </Grid>
        {!dashboardContext && <LoadingDialog loading={loading} />}
        {showDeletePrompt && showDeletePromptDialog()}
      </div>
    );
  };

  useEffect((): void => {
    getNews();
  }, [getNews]);

  return (
    <div>
      {dashboardContext && loading ? (
        <div className={classes.loading}>
          <Loading />
        </div>
      ) : (
        renderNews()
      )}
    </div>
  );
};

export default NewsList;
