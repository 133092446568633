export const hasComma = (value: string | null | undefined) => value?.includes(',');
export const hasSpace = (value: string | null | undefined) => value?.includes(' ');

export const hasCommaOrSpace = (value: string | null | undefined) =>
  hasComma(value) || hasSpace(value);

export const convertStringToArray = (string: string | undefined): string[] => {
  if (!string) {
    return [];
  }
  if (hasComma(string)) {
    return string.split(',').map((item) => item.trim());
  }
  return string.split(' ').map((item) => item.trim());
};

export const convertArrayIntoStringWithComasAndSpaces = (array: string[]): string => {
  if (array.length === 0) {
    return '';
  }
  return array.join(', ');
};
