
import { v4 as uuidv4 } from 'uuid';

import { ServerLineInfo } from 'Invoice/SampleProforma/_types/OrderLineGroup';
import {
  mapIdToSelectType,
  SELECT_TYPE,
} from 'Invoice/SampleProforma/CreateSampleProforma/BasedOnModal/_types';
import { QualityComposition } from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/Composition';
import { LineInfo } from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/LineInfo';

export const uniqueLineId = (
  orderNumber: string | undefined,
  lineNumber: string | number | undefined
): string => (orderNumber && lineNumber ? `${orderNumber}-${lineNumber}` : uuidv4());

const hasOrderNumber = (orderNumber: string | undefined): boolean => orderNumber !== undefined;
const hasStyleNumber = (styleNumber: number | undefined): boolean => styleNumber !== undefined;

const isEditablePrice = (
  orderNumber: string | null,
  styleNumber: number | null,
  pricePerPiece: number | null
): boolean => {
  return (
    !hasOrderNumber(orderNumber ?? undefined) ||
    !hasStyleNumber(styleNumber ?? undefined) ||
    pricePerPiece === null
  );
};
const mapDetailTypeId = (detailTypeId: number | undefined): SELECT_TYPE => {
  return mapIdToSelectType(detailTypeId ?? SELECT_TYPE.MANUAL);
};

const isFromHeader = (orderNumber: string | null, styleNumber: number | undefined): boolean => {
  return orderNumber !== undefined || styleNumber !== undefined;
};

const generateLineId = (orderNumber: string | null, lineNumber: number | null): string => {
  return uniqueLineId(orderNumber ?? undefined, lineNumber ?? undefined);
};

const mapQualityCompositions = (x: ServerLineInfo): QualityComposition[] => {
  return (x.composition?.qualityCompositions ?? []).map(
    (qualityComposition: QualityComposition) => {
      return {
        name: qualityComposition.name,
        id: qualityComposition.id,
        percentage: qualityComposition.percentage,
      };
    }
  );
};

export const mapLineInfoToLineDataArray = (
  orderLineGroupLineInfo: ServerLineInfo[]
): LineInfo[] => {
  return orderLineGroupLineInfo.map(
    (x, i): LineInfo => ({
      collection: x.collection ?? undefined,
      detailTypeId: mapDetailTypeId(x.detailTypeId),
      comment: undefined,
      deleteColumn: undefined,
      order: {
        lineNumber: x.lineNumber ?? undefined,
        number: x.orderNumber ?? undefined,
      },
      style:
        x.style !== null
          ? {
              name: x.style?.name ?? undefined,
              number: x.style?.number ?? undefined,
            }
          : undefined,
      orderDetailId: x.orderDetailId ?? undefined,
      fromHeader: isFromHeader(x.orderNumber, x.style?.number ?? undefined),
      color: { id: x.color?.id, name: x.color?.name, options: [] },
      variant: x.variant,
      size: x.size,
      quantity: x.quantity,
      invoicedQuantity: x.invoicedQuantity,
      originalQuantity: x.originalQuantity,
      maxAllowedQuantity: x.maxAllowedQuantity,
      gender: x.gender,
      sampleType: x.sampleType,
      manufacturer: x.manufacture,
      masterCategory: x.masterCategory,
      styleType: x.styleType,
      composition: {
        qualityCompositions: [
          {
            id: -1, //This is only for manual footwear, which we do not get from BE.
            composition: mapQualityCompositions(x),
          },
        ],
      },
      hsCode: {
        editable:
          isFromHeader(x.orderNumber, x.style?.number ?? undefined) &&
          (x.hsCode ?? undefined) === undefined,
        value: x.hsCode ?? undefined,
      },
      rowNum: '' + (i + 1),
      pricePrPcs: {
        value: x.pricePerPiece ?? 0,
        editable: isEditablePrice(x.orderNumber, x.style?.number ?? null, x.pricePerPiece),
        mixed: false,
        hasError: false,
      },
      totalAmount: x.totalAmount ?? 0,
      lineId: generateLineId(x.orderNumber, x.lineNumber),
      disabled: x.disabled,
    })
  );
};

export const mapLineInfoToLineData = (
  orderLineGroupLineInfo: ServerLineInfo,
  index: number
): LineInfo => {
  return {
    detailTypeId: mapDetailTypeId(orderLineGroupLineInfo.detailTypeId),
    comment: undefined,
    deleteColumn: undefined,
    orderDetailId: orderLineGroupLineInfo.orderDetailId ?? undefined,
    fromHeader: isFromHeader(
      orderLineGroupLineInfo.orderNumber,
      orderLineGroupLineInfo.style?.number ?? undefined
    ),
    order: {
      lineNumber: orderLineGroupLineInfo.lineNumber ?? undefined,
      number: orderLineGroupLineInfo.orderNumber ?? undefined,
    },
    collection: orderLineGroupLineInfo.collection ?? undefined,
    color: {
      id: orderLineGroupLineInfo.color.id,
      name: orderLineGroupLineInfo.color.name,
      options: [],
    },
    variant: orderLineGroupLineInfo.variant,
    size: orderLineGroupLineInfo.size,
    quantity: orderLineGroupLineInfo.quantity,
    invoicedQuantity: orderLineGroupLineInfo.invoicedQuantity,
    originalQuantity: orderLineGroupLineInfo.originalQuantity,
    maxAllowedQuantity: orderLineGroupLineInfo.maxAllowedQuantity,
    sampleType: orderLineGroupLineInfo.sampleType,
    gender: orderLineGroupLineInfo.gender,
    manufacturer: orderLineGroupLineInfo.manufacture,
    masterCategory: orderLineGroupLineInfo.masterCategory,
    styleType: orderLineGroupLineInfo.styleType,
    style:
      orderLineGroupLineInfo.style !== undefined
        ? {
            name: orderLineGroupLineInfo.style?.name ?? undefined,
            number: orderLineGroupLineInfo.style?.number ?? undefined,
          }
        : undefined,
    composition: {
      qualityCompositions: [
        {
          id: -1, //This is only for manual footwear, which we do not get from BE.
          composition: (orderLineGroupLineInfo.composition?.qualityCompositions ?? []).map(
            (qualityComposition: QualityComposition) => {
              return {
                id: qualityComposition.id,
                name: qualityComposition.name,
                percentage: qualityComposition.percentage,
              };
            }
          ),
        },
      ],
    },
    rowNum: (index ?? 1).toString(),
    pricePrPcs: {
      value: orderLineGroupLineInfo.pricePerPiece ?? undefined,
      editable: orderLineGroupLineInfo.pricePerPiece === null,
      mixed: false,
      hasError: false,
    },
    totalAmount: orderLineGroupLineInfo.totalAmount ?? 0,
    hsCode: {
      editable:
        isFromHeader(
          orderLineGroupLineInfo.orderNumber,
          orderLineGroupLineInfo.style?.number ?? undefined
        ) && (orderLineGroupLineInfo.hsCode ?? undefined) === undefined,
      value: orderLineGroupLineInfo.hsCode ?? undefined,
    },
    lineId: generateLineId(orderLineGroupLineInfo.orderNumber, orderLineGroupLineInfo.lineNumber),
    disabled: orderLineGroupLineInfo.disabled,
  };
};
