import React, { ReactElement } from 'react';

import { Chat, CheckBox, ContentPaste, Straighten, Style, Visibility } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import AllInclusive from '@mui/icons-material/AllInclusive';
import GoBack from '@mui/icons-material/ArrowBack';
import GoForward from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Assignment from '@mui/icons-material/AssignmentOutlined';
import AddAttachment from '@mui/icons-material/AttachFile';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import Clear from '@mui/icons-material/Clear';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContactSupport from '@mui/icons-material/ContactSupport';
import Dashboard from '@mui/icons-material/Dashboard';
import Delete from '@mui/icons-material/Delete';
import Transport from '@mui/icons-material/DirectionsBus';
import Approval from '@mui/icons-material/DoneOutline';
import Edit from '@mui/icons-material/Edit';
import FastForward from '@mui/icons-material/FastForward';
import Folder from '@mui/icons-material/FolderOutlined';
import HelpIcon from '@mui/icons-material/Help';
import File from '@mui/icons-material/InsertDriveFile';
import LanguageIcon from '@mui/icons-material/Language';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Login from '@mui/icons-material/Lock';
import Logout from '@mui/icons-material/LockOpen';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MobileMenu from '@mui/icons-material/Menu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import Image from '@mui/icons-material/PhotoSizeSelectActual';
import Print from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Time from '@mui/icons-material/WatchLater';
import ZoomOutMap from '@mui/icons-material/ZoomOutMap';
import { makeStyles } from '@mui/styles';

import CsvIcon from 'Components/Shared/Icons/CsvIcon';
import ExcelIcon from 'Components/Shared/Icons/ExcelIcon';
import MultiSplitIcon from 'Components/Shared/Icons/MultiSplit';

import _3DIcon from './3DIcon/index';
import AccountBalance from './AccountBalance';
import ConfirmOrdersIcon from './ConfirmOrders';
import CreatePackingNoteIcon from './CreatePackingNoteIcon';
import DeleteCartonNumberIcon from './DeleteCartonNumber/index';
import InvoiceIcon from './InvoiceIcon/index';
import MarketingIcon from './MarketingIcon';
import NewsIcon from './NewsIcon';
import OrderHistoryIcon from './OrderHistoryIcon/index';
import OrdersIcon from './OrdersIcon';
import PackingNoteIcon from './PackingNoteIcon';
import PolyBagIcon from './PolyBagIcon/index';
import PrintedIcon from './PrintedIcon';
import SourcingIcon from './SourcingIcon/index';
import UndeleteCartonNumberIcon from './UndeleteCartonNumber/index';

export type IconName =
  | 'add'
  | 'clear'
  | 'edit'
  | 'delete'
  | 'logout'
  | 'login'
  | 'approval'
  | 'goBack'
  | 'goForward'
  | 'addAttachment'
  | 'mobileMenu'
  | 'time'
  | 'transport'
  | 'image'
  | 'zoomOutMap'
  | 'OpenInNewIcon'
  | 'print'
  | 'split'
  | 'save'
  | 'help'
  | 'marketing'
  | 'createPackingNote'
  | 'supplement'
  | 'upload'
  | 'folder'
  | 'assignment'
  | 'download'
  | 'dashboard'
  | 'fastConfirm'
  | 'support'
  | 'packingNote'
  | 'news'
  | 'orders'
  | 'confirmOrders'
  | '3d'
  | 'sourcing'
  | 'sticker'
  | 'revise'
  | 'view'
  | 'confirm'
  | 'listAltIcon'
  | 'receipt'
  | 'accountBalance'
  | 'invoiceIcon'
  | 'printed'
  | 'draft'
  | 'SettingsBackupRestoreIcon'
  | 'RestoreFromTrashIcon'
  | 'deleteCartonNumber'
  | 'undeleteCartonNumber'
  | 'polybag'
  | 'style'
  | 'excel'
  | 'csv'
  | 'orderHistory'
  | 'MMC'
  | 'MultiSplit'
  | 'Demo'
  | 'GlobalSourcingSite'
  | 'contentPaste'
  | 'creditNote'
  | 'outlinedFlag'
  | 'userManagement';

interface IconElement {
  iconName: IconName;
  iconElement: ReactElement;
}

const BestIcons: IconElement[] = [
  { iconName: 'add', iconElement: <Add /> },
  { iconName: 'clear', iconElement: <Clear /> },
  { iconName: 'edit', iconElement: <Edit /> },
  { iconName: 'delete', iconElement: <Delete /> },
  { iconName: 'logout', iconElement: <Logout /> },
  { iconName: 'login', iconElement: <Login /> },
  { iconName: 'approval', iconElement: <Approval /> },
  { iconName: 'goBack', iconElement: <GoBack /> },
  { iconName: 'goForward', iconElement: <GoForward /> },
  { iconName: 'addAttachment', iconElement: <AddAttachment /> },
  { iconName: 'mobileMenu', iconElement: <MobileMenu /> },
  { iconName: 'time', iconElement: <Time /> },
  { iconName: 'transport', iconElement: <Transport /> },
  { iconName: 'image', iconElement: <Image /> },
  { iconName: 'zoomOutMap', iconElement: <ZoomOutMap /> },
  { iconName: 'OpenInNewIcon', iconElement: <OpenInNewIcon /> },
  { iconName: 'print', iconElement: <Print /> },
  { iconName: 'split', iconElement: <CallSplitIcon /> },
  { iconName: 'save', iconElement: <SaveIcon /> },
  { iconName: 'help', iconElement: <HelpIcon /> },
  { iconName: 'marketing', iconElement: <MarketingIcon /> },
  { iconName: 'supplement', iconElement: <AllInclusive /> },
  { iconName: 'createPackingNote', iconElement: <CreatePackingNoteIcon /> },
  { iconName: 'upload', iconElement: <ArrowUpwardIcon /> },
  { iconName: 'folder', iconElement: <Folder /> },
  { iconName: 'assignment', iconElement: <Assignment /> },
  { iconName: 'download', iconElement: <CloudDownloadIcon /> },
  { iconName: 'dashboard', iconElement: <Dashboard /> },
  { iconName: 'support', iconElement: <ContactSupport /> },
  { iconName: 'fastConfirm', iconElement: <FastForward /> },
  { iconName: 'packingNote', iconElement: <PackingNoteIcon /> },
  { iconName: 'news', iconElement: <NewsIcon /> },
  { iconName: 'orders', iconElement: <OrdersIcon /> },
  { iconName: 'confirmOrders', iconElement: <ConfirmOrdersIcon /> },
  { iconName: '3d', iconElement: <_3DIcon /> },
  { iconName: 'sourcing', iconElement: <SourcingIcon /> },
  { iconName: 'listAltIcon', iconElement: <ListAltIcon /> },
  { iconName: 'sticker', iconElement: <ReceiptOutlinedIcon /> },
  { iconName: 'revise', iconElement: <Chat /> },
  { iconName: 'confirm', iconElement: <CheckBox /> },
  { iconName: 'view', iconElement: <Visibility /> },
  { iconName: 'receipt', iconElement: <ReceiptIcon /> },
  { iconName: 'accountBalance', iconElement: <AccountBalance /> },
  { iconName: 'invoiceIcon', iconElement: <InvoiceIcon /> },
  { iconName: 'printed', iconElement: <PrintedIcon /> },
  { iconName: 'draft', iconElement: <File /> },
  {
    iconName: 'SettingsBackupRestoreIcon',
    iconElement: <SettingsBackupRestoreIcon />,
  },
  {
    iconName: 'RestoreFromTrashIcon',
    iconElement: <RestoreFromTrashIcon />,
  },
  {
    iconName: 'polybag',
    iconElement: <PolyBagIcon />,
  },
  {
    iconName: 'deleteCartonNumber',
    iconElement: <DeleteCartonNumberIcon />,
  },
  {
    iconName: 'undeleteCartonNumber',
    iconElement: <UndeleteCartonNumberIcon />,
  },
  { iconName: 'style', iconElement: <Style /> },
  { iconName: 'excel', iconElement: <ExcelIcon /> },
  { iconName: 'csv', iconElement: <CsvIcon /> },
  { iconName: 'MMC', iconElement: <Straighten /> },
  { iconName: 'orderHistory', iconElement: <OrderHistoryIcon /> },
  { iconName: 'MultiSplit', iconElement: <MultiSplitIcon /> },
  { iconName: 'Demo', iconElement: <AutoFixHighIcon /> },
  { iconName: 'GlobalSourcingSite', iconElement: <LanguageIcon /> },
  { iconName: 'contentPaste', iconElement: <ContentPaste /> },
  { iconName: 'creditNote', iconElement: <Assignment /> },
  { iconName: 'outlinedFlag', iconElement: <OutlinedFlagRoundedIcon /> },
  {
    iconName: 'userManagement',
    iconElement: <ManageAccountsIcon />,
  },
];

export function getIconByIconName(iconName: IconName): ReactElement {
  const filteredElements: IconElement[] = BestIcons.filter(function (item): boolean {
    return item.iconName === iconName;
  });

  return filteredElements[0].iconElement;
}

const styles = makeStyles({
  container: {
    display: (props?: { asFlex?: boolean }) => {
      return props?.asFlex ? 'flex' : '';
    },
  },
});

export function getIconElementByIconName(
  iconName: IconName,
  options?: { asFlex?: boolean },
): ReactElement {
  const classes = styles(options);
  return <div className={classes.container}>{getIconByIconName(iconName)}</div>;
}
