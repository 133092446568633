import { LineInfoGroup } from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/LineInfo';

/**
 * This function adjusts the line numbers in the groups of lines.
 * It iterates over each group and each line within the group, updating the proforma line number for each line.
 * It also updates the start and end line numbers for each group.
 *
 * @param {LineInfoGroup[]} groups - The groups of lines to fix up.
 * @returns {LineInfoGroup[]} The groups of lines with fixed up line numbers.
 */
export const fixupLineNumber = (groups: LineInfoGroup[]): LineInfoGroup[] => {
  let start = 1;
  let end = 0;

  // Iterate over each group
  groups.forEach((group) => {
    // Iterate over each line within the group
    group.lines.forEach((line, j) => {
      // Update the proforma line number for the line
      line.proformaLineNumber = `${start + j}`;
      // Update the end line number
      end = j;
    });

    // Update the start and end line numbers for the group
    group.rowNums = { start: start, end: start + end };

    // Update the start line number for the next group
    start += end + 1;
  });

  return groups;
};
