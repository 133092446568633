import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useReduxDispatch } from 'Helpers';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { User } from 'Models/User/_types_/User';
import { devSettingsActions } from 'Store/actions/devSettings.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

const useLDAuth = (): LDFlagSet | undefined => {
  const flags = useFlags();
  const ldClient = useLDClient();
  const dispatch = useReduxDispatch();
  const [isIdentifying, setIsIdentifying] = useState(false);

  const user = useSelector((root: RootReducerState): User | null => root.userReducer.user);

  const [ldFlags, setLdFlags] = useState<LDFlagSet | undefined>();
  const [featureFlagReady, setFeatureFlagReady] = useState(false);

  useEffect((): void => {
    if (sessionStorage.getItem('disableAllFF')) {
      dispatch(devSettingsActions.toggleDisableAllFf(true, true));
    }
  }, [dispatch]);

  useEffect((): void => {
    setFeatureFlagReady(false);

    //Flush the flags if the user is not logged in
    if (ldClient !== undefined && !ldClient.getContext().anonymous && !user) {
      setIsIdentifying(false);
      ldClient.waitUntilReady().then((): void => {
        ldClient.identify(
          {
            anonymous: true,
          },
          undefined,
          async (): Promise<void> => {
            return setFeatureFlagReady(true);
          },
        );
      });
      return;
    }

    if (ldClient && !isIdentifying) {
      if (ldClient.getContext().anonymous && !!user) {
        setIsIdentifying(true);
        ldClient.waitUntilReady().then((): void => {
          ldClient.identify(
            {
              key: '' + user.usersId,
              name: user.fullName,
              email: user.userName,
              anonymous: false,
            },
            undefined,
            async (): Promise<void> => {
              return setFeatureFlagReady(true);
            },
          );
        });
      } else {
        setFeatureFlagReady(true);
      }
    }
  }, [isIdentifying, ldClient, user]);

  useEffect((): void => {
    if (featureFlagReady) {
      setLdFlags(flags);
    }
  }, [featureFlagReady, flags]);

  return ldFlags;
};

export default useLDAuth;
