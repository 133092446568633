import React, { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useReduxDispatch } from 'Helpers';

import hackerman from 'Assets/images/jpeg/hackerman.jpeg';
import { userActions } from 'Store/actions/user.actions';

const BeBranchInfo = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const location = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const beBranch = useMemo(() => queryParams.get('beBranch') ?? undefined, [queryParams]);
  const storedBranch = useMemo(() => sessionStorage.getItem('userServiceBranch') ?? undefined, []);
  const hasBeBranch = useMemo(
    () => (storedBranch ?? '') !== '' || !!beBranch,
    [storedBranch, beBranch],
  );

  useEffect((): void => {
    dispatch(userActions.deleteUser());
  }, [dispatch]);

  useEffect(() => {
    if (beBranch) {
      sessionStorage.setItem('userServiceBranch', beBranch);
      navigate('/login', { replace: true });
    }
  }, [beBranch, navigate]);

  if (!hasBeBranch) {
    return <></>;
  }
  return (
    <Box display="flex" justifyContent="center">
      <Box marginBottom="8px" border="1px solid" padding="4px" borderRadius="8px">
        <Grid container flexDirection="row" spacing="8px">
          <Grid>
            <img src={hackerman} width="100px" alt="hackerman" />
          </Grid>
          <Grid display="flex">
            <Grid container flexDirection="column" alignItems="space-between">
              <Grid flex={1}>
                <span>
                  <span>Using branch&nbsp;</span>
                  <span>{storedBranch ?? beBranch}</span>
                </span>
              </Grid>
              <Grid>
                <Button
                  variant="outlined"
                  onClick={() => {
                    sessionStorage.setItem('userServiceBranch', '');
                    navigate('/login', { replace: true });
                  }}
                >
                  CLEAR BRANCH
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default BeBranchInfo;
