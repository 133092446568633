import { ReactElement } from 'react';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Card, IconButton, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import cellContent from 'Components/Table/Components/Helpers/cellContent';
import { getNestedProperty } from 'Components/Table/Components/TableRow';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { doExpandTableRow } from 'Components/Table/state/actions/expandRow';
import { getIsRowExpanded } from 'Components/Table/state/selectors/getIsRowExpanded';
import SkeletonizedTypography from 'PackingNotes/Areas/PackingNotesDetails/Components/shared/SkeletonizedTypography';

import { useTableState } from '../state/useTableState';

interface Props<T extends TableRecord> {
  row: T;
  className?: string;
  hasExpandedRow?: boolean;
}

const useStyles = () => {
  const theme = useTheme();
  return makeStyles(() => ({
    tableRecord: {
      display: 'flex',
      margin: 4,
      padding: 16,
      flexWrap: 'wrap',
      '& .tableCardValue': {
        [theme.breakpoints.down('lg')]: {
          width: '33%',
        },
        [theme.breakpoints.down('md')]: {
          width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
  }))();
};

const TableCard = <T extends TableRecord>({
  row,
  className = '',
  hasExpandedRow,
}: Props<T>): ReactElement => {
  const classes = useStyles();
  const { state, dispatch } = useTableState<T>();
  const { primaryKey } = state;
  const primaryKeyOfRow = String(
    primaryKey.includes('.') ? getNestedProperty(row, primaryKey) : row[primaryKey],
  );
  const expanded = getIsRowExpanded(state, primaryKeyOfRow);

  return (
    <Card className={[classes.tableRecord, className].join(' ')}>
      {state.columns.map((column) => {
        return (
          <div
            key={`tableCard-${row[state.primaryKey]}-${column.property}`}
            className="tableCardValue"
          >
            <Typography variant="caption" color="textSecondary">
              {column.label}:
            </Typography>
            <SkeletonizedTypography variant="body2">
              {cellContent(column, row, dispatch)}
            </SkeletonizedTypography>
          </div>
        );
      })}
      {hasExpandedRow && (
        <IconButton
          sx={{ mt: 1 }}
          onClick={() => {
            dispatch(doExpandTableRow(primaryKeyOfRow));
          }}
        >
          {expanded ? <ArrowCircleUpIcon /> : <ExpandCircleDownIcon />}
        </IconButton>
      )}
    </Card>
  );
};

export default TableCard;
