import { Key } from 'react';

import mime from 'mime-types';

import { datadogRum } from '@datadog/browser-rum';
import DateFnsUtils from '@date-io/date-fns';
import { OrderType, orderPrintType } from 'Models/Orders/_types_/OrderType';
import { Order } from 'Orders/_types_/Order';

import { ServerOrderType } from '../Models/Orders/_types_/OrderType';

export const transformNumberToAmericanFormatAndTwoDecimals = (number: number): string => {
  return number.toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const transformAmericanFormatNumberToNumber = (number: string): number => {
  return parseFloat(number.replace(/,/g, ''));
};

export const formatDateToYYYYMMDD = (date: Date): string =>
  new DateFnsUtils().formatByString(date, 'yyyy-MM-dd');

export const formatDateToDDMMYYYY = (date: Date, seperator?: '/' | '-'): string => {
  const sep = seperator ?? '-';
  return new DateFnsUtils().formatByString(date, `dd${sep}MM${sep}yyyy`);
};

export const getEnumKeyByEnumValue = <T extends Record<string, unknown>>(
  theEnum: T,
  enumValue: T[keyof T],
): keyof T => {
  return Object.keys(theEnum)[Object.values(theEnum).indexOf(enumValue)] as keyof T;
};

export const nameofFactory =
  <T>(): ((name: keyof T) => keyof T) =>
  (name: keyof T): keyof T =>
    name;

export const arraysEqual = <T>(a: T[], b: T[]): boolean => {
  if (a === b) {
    return true;
  }
  if (a === null || b === null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (b.indexOf(a[i]) === -1) {
      return false;
    }
  }
  return true;
};

export const detailsInformationAvailable = (order: OrderType | ServerOrderType): boolean => {
  return !!order.warehouseEta || !!order.cargoClosing;
};

export const openBlobInNewWindow = (response: Blob | undefined): void => {
  // IE & Edge fix for downloading blob files, gives option to save or open the file when the link is opened.
  if (!response) {
    return;
  }
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const now = new Date();
    const ts =
      now.toISOString().substring(0, 10).split('-').join('_') +
      '_' +
      now.toISOString().substring(11, 19).split(':').join('-');

    const filename = `BS_PRINT_${ts}.${mime.extension(response.type)}`;
    window.navigator.msSaveOrOpenBlob(response, filename);
  } else {
    const data = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = data;
    link.target = '_blank';
    link.click();
  }
};

export const hashCode = (s: string): string => {
  return btoa(s);
};

export const sortOrderByTimeAndOrdersNumber = (a: Order, b: Order): number => {
  if ((a.cargoClosingDate || new Date()).getTime() < (b.cargoClosingDate || new Date()).getTime()) {
    return -1;
  } else if (
    (a.cargoClosingDate || new Date()).getTime() > (b.cargoClosingDate || new Date()).getTime()
  ) {
    return 1;
  } else {
    return a.orderNumber.localeCompare(b.orderNumber);
  }
};

export enum orderNotConfirmableReasonsEnum {
  'NO_PRICE' = 'Unit Price Required',
  'NO_PURCHASER' = 'Order does not have a purchaser',
  'NO_CONFIRMED_PRICE' = 'Price in order has not been confirmed',
}

export const translateStringToNotConfirmableEnum = (stringNotConfirmable: string): string => {
  switch (stringNotConfirmable) {
    case 'NO_PRICE':
      return orderNotConfirmableReasonsEnum.NO_PRICE;
    case 'NO_PURCHASER':
      return orderNotConfirmableReasonsEnum.NO_PURCHASER;
    case 'NO_CONFIRMED_PRICE':
      return orderNotConfirmableReasonsEnum.NO_CONFIRMED_PRICE;
    default:
      return '';
  }
};

export const deepClone = <T>(object: T): T => {
  const clone = JSON.parse(JSON.stringify(object));
  return clone;
};

export const base64ToBuffer = (str: string): ArrayBuffer => {
  str = window.atob(str); // creates a ASCII string
  const buffer = new ArrayBuffer(str.length),
    view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    view[i] = str.charCodeAt(i);
  }
  return buffer;
};

const fileNameGenerator = (orders: Key | Key[]): Key =>
  (Array.isArray(orders) ? orders[0] : orders) ?? Math.random().toString(36).substr(2);

export const printFileInfoGenerator = (
  orders: Key | Key[],
  type: orderPrintType | string,
): { fileName: string; fileExt: string; fileType: string } => {
  const printType = type as orderPrintType;
  let fileExt = '';
  let fileType = '';
  if (printType) {
    fileType =
      type === 'EXCEL'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'application/pdf';
    fileExt = type === 'EXCEL' ? 'xlsx' : 'pdf';
  } else {
    const mimeType = mime.extension(fileType);
    fileExt = mimeType ? mimeType : '';
    fileType = type;
  }

  return { fileName: fileNameGenerator(orders).toString(), fileExt, fileType };
};

// TODO: Should do in the BE
export const getMaskedEmail = (email: string): string => {
  const skipFirstChars = 2;
  const firstSkippedChars = email.slice(0, skipFirstChars);

  const domainIndexStart = email.lastIndexOf('@');
  let maskedEmail = email.slice(skipFirstChars, domainIndexStart - skipFirstChars);

  maskedEmail = maskedEmail.replace(/./g, '*');
  const domainPlusPreviousChar = email.slice(
    domainIndexStart - skipFirstChars,
    domainIndexStart + skipFirstChars,
  );
  let maskedDomain = email.slice(domainIndexStart + skipFirstChars, email.length - skipFirstChars);
  maskedDomain = maskedDomain.replace(/./g, '*');
  const lastChars = email.slice(email.length - skipFirstChars, email.length);

  return firstSkippedChars
    .concat(maskedEmail)
    .concat(domainPlusPreviousChar)
    .concat(maskedDomain)
    .concat(lastChars);
};

export const removeUndefinedFromArray = <T>(item: T | undefined | null): item is T => {
  return !!item;
};

export const removeNullFromArray = <T>(item: T | null): item is T => {
  return !!item;
};

export const guaranteeArray = <T>(item: T | T[]): T[] => {
  if (!Array.isArray(item)) {
    return [item];
  }
  return item;
};

export const howManyAreUndefined = <T>(array: T[]): 'ALL' | 'SOME' | 'NONE' => {
  const originalLength = array.length;
  const numberOfNotUndefined = array.filter(removeUndefinedFromArray).length;
  if (numberOfNotUndefined === 0) {
    return 'ALL';
  }
  if (originalLength - numberOfNotUndefined === 0) {
    return 'NONE';
  }
  return 'SOME';
};

// https://stackoverflow.com/a/20392392/6619867
export const tryParseJSON = <T>(jsonString: string): T | false => {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    datadogRum.addError('tryParseJSON failed', {
      error: e,
      jsonString: JSON.stringify(jsonString),
    });
    return false;
  }

  return false;
};

export const toISOLocal = (d: Date): string => {
  const z = (n: number | string) => ('0' + n).slice(-2);
  const zz = (n: number | string) => ('00' + n).slice(-3);
  let off = d.getTimezoneOffset();
  const sign = off < 0 ? '+' : '-';
  off = Math.abs(off);

  return (
    d.getFullYear() +
    '-' +
    z(d.getMonth() + 1) +
    '-' +
    z(d.getDate()) +
    'T' +
    z(d.getHours()) +
    ':' +
    z(d.getMinutes()) +
    ':' +
    z(d.getSeconds()) +
    '.' +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ':' +
    z(off % 60)
  );
};

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

export const isDeleted = (deleted: 'Y' | 'N'): boolean => deleted === 'Y';

export const isNotUndefined = <T>(item: T | undefined): item is T => {
  return !!item;
};

export const isNotNull = <T>(item: T | null): item is T => {
  return !!item;
};

enum _AbstractEnum {}
export const findInEnum = <T extends typeof _AbstractEnum>(
  passedEnum: T,
  r: string,
): keyof T | undefined =>
  Object.entries(passedEnum).find(([, value]) => value === (r as T[keyof T]))?.[0] as
    | keyof T
    | undefined;

export const decodeTextForObject = (
  text: string | undefined,
  objectType: string,
  textType: string,
): string => {
  const stringToDecode = text ?? `The ${objectType} does not contain any ${textType}`;
  try {
    /** decodeURIComponent is needed due to form content most like being posted as application/x-www-form-urlencoded, encoding a '/' as '%2f'
     *   This decodes it
     */

    return decodeURIComponent(stringToDecode);
  } catch {
    /** In some cases, an error like:
     * URIError: URI malformed
     * Might be thrown
     */

    return stringToDecode;
  }
};

export const propertyOf = <TObj>(name: keyof TObj): keyof TObj => name;
export const isDraft = (status: string): boolean => status === 'Draft';

export const sortArrayByProperty = <T>(array: T[], property: keyof T): T[] => {
  const doSort = <S>(arrayItemA: S, arrayItemB: S, sortProperty: keyof S): number => {
    if (arrayItemA[sortProperty] < arrayItemB[sortProperty]) {
      return -1;
    }

    if (arrayItemA[sortProperty] > arrayItemB[sortProperty]) {
      return 1;
    }

    return 0;
  };
  return [...array].sort((a, b) => doSort(a, b, property));
};

/**
 * Groups an array of items by a specified key.
 * The key is determined by the provided key function.
 *
 * @param {T[]} arr - The array of items to group.
 * @param {(i: T) => K} key - A function that takes an item and returns the key to group by.
 * @returns {Record<K, T[]>} - Returns an object where each property is a key and its value is an array of items that have that key.
 */
export const arrayGroupBy = <T, K extends string | number | symbol>(
  arr: T[],
  key: (i: T) => K,
): Record<K, T[]> =>
  arr.reduce(
    (groups, item) => {
      // Extract the key
      const itemKey = key(item);

      // If the group doesn't exist, initialize it as an empty array
      if (!groups[itemKey]) {
        groups[itemKey] = [];
      }

      // Push the item into the group
      groups[itemKey].push(item);
      return groups;
    },
    {} as Record<K, T[]>,
  );

/**
 * Checks if all values of a specific nested property in an array of objects are the same.
 *
 * @template T The type of the objects in the array.
 * @template K The key of the property to check in the objects.
 * @template V The key of the nested property to check in the property identified by `K`.
 * @param {T[]} lines The array of objects to check.
 * @param {K} key The key of the property to check in the objects.
 * @param {V} valueKey The key of the nested property to check in the property identified by `K`.
 * @returns {boolean} `true` if all values of the specified nested property are the same, or if the array is empty or contains only one object. `false` otherwise.
 */
export const areAllValuesTheSame = <T, K extends keyof T, V extends keyof NonNullable<T[K]>>(
  lines: T[],
  key: K,
  valueKey: V,
): boolean => {
  if (lines.length === 0 || lines.length === 1) {
    return true;
  }

  return lines.every((line) => (line[key]?.[valueKey] ?? 0) === (lines[0][key]?.[valueKey] ?? 0));
};

export const setValueIfOldIsEmpty = (
  currentValue: string | undefined,
  newValue: string | undefined,
): string | undefined => {
  return currentValue ?? newValue;
};
