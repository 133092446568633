import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import DeleteCartonNumberSvg from 'Assets/images/svg/DeleteCartonNumber/deleteCartonNumber.svg';

const DeleteCartonNumberIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={DeleteCartonNumberSvg} viewBox="0 0 24 24" {...props} />
);

export default DeleteCartonNumberIcon;
