import { createContext } from 'react';

import { TableState } from './_types_/TableState';
import { TableStateDispatch } from './_types_/TableStateDispatch';

export const TableStateContext =
  createContext<
    | {
        state: TableState;
        dispatch: TableStateDispatch;
      }
    | undefined
  >(undefined);
