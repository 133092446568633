import { AxiosRequestConfig } from 'axios';
import { properties } from 'Helpers';

import {
  AccountInfo,
  Configuration,
  IPublicClientApplication,
  PopupRequest,
  ProtocolMode,
} from '@azure/msal-browser';
import { AuthContextInterface } from 'Contexts/adAuthProvider';

export const msalConfig: Configuration = {
  auth: {
    clientId: properties.ssoApplicationID,
    authority: 'https://login.microsoftonline.com/98db9fb9-f52b-4e63-83d9-795ccd2dfcca',
    protocolMode: ProtocolMode.AAD,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: PopupRequest = {
  scopes: ['openid', 'offline_access', 'email', 'User.Read'],
  prompt: 'select_account',
};

export const loginRequestWithAccount = (
  account: AccountInfo,
): { account: AccountInfo; scopes: string[] } => ({
  ...loginRequest,
  account,
});

export const refreshTokenAxios =
  (instance: IPublicClientApplication, authContext: AuthContextInterface) =>
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const freshToken = await getFreshToken(instance, authContext);
    config.headers = {
      authorization: freshToken.idToken ? `Bearer ${freshToken.idToken}` : '(token-not-set)',
    };
    return config;
  };

export const getFreshToken = async (
  instance: IPublicClientApplication,
  authContext: AuthContextInterface,
): Promise<{ idToken: string }> => {
  let freshToken = { idToken: authContext.idToken ?? '(idToken-not-set)' };
  if (authContext.account) {
    try {
      // https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
      freshToken = await instance.acquireTokenSilent(loginRequestWithAccount(authContext.account));
    } catch (e) {
      console.error(e);
    }
  }
  return freshToken;
};

export const refreshTokenApollo =
  (instance: IPublicClientApplication, authContext: AuthContextInterface) =>
  async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const freshToken = await getFreshToken(instance, authContext);
    const response = await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${freshToken.idToken}`,
        provider: 'AD',
      },
    });
    return {
      ...response,

      async text() {
        return await response.text();
      },
    };
  };
