import { Permission } from 'Models/Permission/_types_/Permission';

export const PERMISSION = {
  VP_UPDATE_NEWS: 34725272,
  VP_DELETE_NEWS: 34725273,
  VP_CREATE_NEWS: 34725271,
  VP_VIEW_NEWS: 34725274,
  VP_VIEW_ORDER: 35015276,
  VP_FAVORITE_ORDER: 35015278,
  VP_ORDER_CONFIRMATION: 35015279,
  VP_VIEWED_MEASUREMENTCARD: 35015280,
  VP_VIEW_DASHBOARD: 35015281,
  VP_VIEW_BOTTOMBAR: 35080964,
  VP_DEVELOPER_PERMISSION: 36408864,
  VP_VIEW_ORDER_ATTACHMENT: 35786687,

  VP_PACKING_NOTE_UNPRINT: 35875088,
  VP_PACKING_NOTE_TRANSMIT: 35875087,
  VP_PACKING_NOTE_UNDELETE: 35875086,
  VP_PACKING_NOTE_PRINT_LABEL: 35875085,
  VP_PACKING_NOTE_DELETE_PRINTED: 35875084,
  VP_PACKING_NOTE_DELETE_DRAFT: 35875083,
  VP_PACKING_NOTE_EDIT_PRINTED: 35875082,
  VP_PACKING_NOTE_VIEW: 35875081,
  VP_PACKING_NOTE_CREATE: 35875080,
  VP_PACKING_NOTE_EDIT_OUTSIDE_CARTON_DIMENSIONS: 35946735,

  VP_INVOICE_INBOUND_GENERATE: 35795920,
  VP_INVOICE_INBOUND_VIEW: 35795921,
  VP_INVOICE_OUTBOUND_VIEW: 35795922,
  VP_INVOICE_OUTBOUND_CONFIRM: 35795923,
  VP_INVOICE_OUTBOUND_CANCEL: 35795924,
  VP_INVOICE_REVERSE: 35832650,
  VP_INVOICE_RETRY: 35838747,
  VP_INVOICE_MIGRATION: 35838746,

  VP_STYLES_VIEW: 35987281,

  VP_VIEW_INVOICES: 35795925,
  NONE: 0,

  // SAMPLE PROFORMA INVOICE
  VP_PROFORMA_INVOICE_CONFIRM: 36251161,
  VP_PROFORMA_INVOICE_CREATE: 36251160,
  VP_PROFORMA_INVOICE_DELETE: 36251162,
  VP_PROFORMA_INVOICE_PREVIEW: 36251163,
  VP_PROFORMA_INVOICE_REQUEST_UPDATE: 36251166,
  VP_PROFORMA_INVOICE_REVERSE: 36251165,
  VP_PROFORMA_INVOICE_UPDATE: 36251167,
  VP_PROFORMA_INVOICE_UPLOAD_DOCUMENT: 36251164,
  VP_PROFORMA_INVOICE_VIEW: 36251159,
  VP_PROFORMA_INVOICE_LABELS: 36275303,

  // SAMPLE COMMERCIAL INVOICE
  VP_COMMERCIAL_INVOICE_REVERSE: 36299022,
  VP_COMMERCIAL_INVOICE_CREATE: 36251169,
  VP_COMMERCIAL_INVOICE_VIEW: 36251168,
  VP_COMMERCIAL_INVOICE_DELETE: 36408290,
  VP_COMMERCIAL_INVOICE_CONFIRM: 36408291,

  // CLAIMS
  VP_CLAIM_VIEW: 36373557,
  VP_CLAIM_CONFIRM: 36373558,
  VP_CLAIM_DECLINE: 36373559,
  VP_CLAIM_EDIT: 36373560,
  VP_CLAIM_REVERSE: 36373561,
  VP_CLAIM_PRINT: 36373562,

  // CREDIT NOTES
  VP_CREDIT_NOTE_VIEW: 36422941,
  VP_CREDIT_NOTE_CONFIRM: 36422943,
  VP_CREDIT_NOTE_CREATE: 36422942,
  VP_CREDIT_NOTE_DELETE: 36422944,
  VP_CREDIT_NOTE_EDIT: 36422945,
  VP_CREDIT_NOTE_REVERSE: 36422946,
  VP_CREDIT_NOTE_PRINT: 36422947,
};

const getPermission = (index: number): Permission => {
  return {
    permissionName: Object.keys(PERMISSION)[index].toString(),
    permissionsId: Object.values(PERMISSION)[index],
  };
};

export const getPermissionsArray = (): Permission[] => {
  const permissions: Permission[] = [];
  for (let i = 0; i < Object.keys(PERMISSION).length; i++) {
    permissions.push(getPermission(i));
  }
  return permissions;
};
