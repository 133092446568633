import { ReactElement, useCallback, useMemo } from 'react';

import { Typography, Link, Box } from '@mui/material';

import { Dialog, DialogButtonProps } from '@bestseller-bit/frontend-community.components.dialog';
import { LOGIN_GUIDE_URL } from 'Constants/shared.constants';

interface Props {
  open: boolean;
  onClose: () => void;
  goToCredentialsForm: (() => void) | undefined;
}

const FlowFailedDialog = (props: Props): ReactElement => {
  const handleOpenLoginGuide = useCallback(() => {
    const newWindow = window.open(LOGIN_GUIDE_URL, '_blank');
    newWindow?.focus();
  }, []);

  const buttons = useMemo((): DialogButtonProps[] => {
    return [
      {
        label: 'Close',
        onClick: props.onClose,
      },
    ];
  }, [props.onClose]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title="Seems like you've cancelled the login flow."
      titleVariant="h5"
      dividers
      buttons={buttons}
    >
      <Box>
        <Typography>If you are getting this error</Typography>
        <Typography fontWeight="bold" marginTop="8px">
          Sorry, but we&apos;re having trouble signing you in...
        </Typography>
        <Typography marginTop="8px">
          <span>
            It might be because your e-mail has not yet been linked to a Bestinfo account. Please
            click here to&nbsp;
          </span>
          <Link
            color="info.main"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (props.goToCredentialsForm !== undefined) {
                props.goToCredentialsForm();
              }
            }}
          >
            Link email to BI2 account
          </Link>
        </Typography>
        <Typography marginTop="8px">
          <span>We reccomend reading the &nbsp;</span>
          <Link
            color="info.main"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenLoginGuide()}
          >
            login guide
          </Link>
          <span>.</span>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default FlowFailedDialog;
