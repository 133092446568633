import InvalidLengthError from 'Invoice/SampleProforma/_models/Errors/InvalidLengthError';

class InvoiceNumberValueObject {
  private readonly value: string | null;
  private readonly error: Error | null;

  constructor(value: string | null) {
    if (value === null || value.trim().length === 0) {
      this.error = new Error('Invoice number is required');
    } else if (value !== null && value.length > 16) {
      this.error = new InvalidLengthError('Max length is 16');
    } else {
      this.error = null;
    }
    this.value = value;
  }

  public getValue(): string | null {
    return this.value;
  }

  public getError(): Error | null {
    return this.error;
  }
}

export default InvoiceNumberValueObject;
