import { ReactElement } from 'react';

import { BestoneErrorDetail } from 'Services/ext.service/interfaces/BestoneErrorDetail';

import classes from './BestoneErrorDetailList.module.scss';

export interface Props {
  errors?: BestoneErrorDetail[];
}

const BestoneErrorDetailList = (props: Props): ReactElement => {
  if (!props.errors) {
    return <></>;
  }
  return (
    <>
      {props.errors.map((error, i): ReactElement => {
        return (
          <div key={`error-${i}`} className={classes.container}>
            <div className={classes.message}>{error.errorMessage}:</div>
            <div className={classes.ids}>{error.errorIds.join(', ')}</div>
          </div>
        );
      })}
    </>
  );
};

export default BestoneErrorDetailList;
