import { Component, ReactElement } from 'react';

import Err404Pic from 'Assets/images/png/404_dark.png';

import classes from './ErrorPage404.module.scss';

class ErrorPage404 extends Component<unknown> {
  render(): ReactElement {
    return (
      <div className={classes.containerDiv}>
        <div className={classes.pictureDiv}>
          <img src={Err404Pic} alt="404" className={[classes.animated, classes.swing].join(' ')} />
        </div>
        <div>
          <h1>The page you are looking for seems to be missing</h1>
          <p>
            <span>Go back, or return to&nbsp;</span>
            <a href="/">main page</a>
            <span>.</span>
          </p>
        </div>
      </div>
    );
  }
}

export default ErrorPage404;
