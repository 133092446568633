import { ReactElement, MouseEvent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Popover, FormControlLabel, Checkbox } from '@mui/material';

import { useReduxDispatch } from 'Helpers';

import BaseButton from 'Components/Shared/BestButton/BaseButton';
import { FlagInfo } from 'FeatureFlags/interface/FlagInfo';
import { devSettingsActions } from 'Store/actions/devSettings.actions';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { DevSettingsState } from 'Store/reducers/devSettings.reducer';

import { Props } from './_types_';
import classes from './FlagsPopover.module.scss';

const FlagsPopover = (props: Props): ReactElement => {
  const user = useSelector((root: RootReducerState) => root.userReducer?.user);
  const dispatch = useReduxDispatch();
  const devSettings = useSelector(
    (root: RootReducerState): DevSettingsState => root.devSettingsState,
  );
  const [storeInSession, setStoreInSession] = useState(false);
  const featureFlags = props.flags;

  useEffect((): void => {
    setStoreInSession(devSettings.disableAllFF.fromSession);
  }, [devSettings.disableAllFF.fromSession]);

  const [anchorElFeatureFlags, setAnchorElFeatureFlags] = useState<HTMLButtonElement | null>(null);

  const openFeatureFlag = Boolean(anchorElFeatureFlags);
  const featureFlagPopoverId = openFeatureFlag ? 'featureFlagPopoverId' : undefined;

  return (
    <>
      <Button
        aria-describedby={featureFlagPopoverId}
        variant="text"
        onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
          setAnchorElFeatureFlags(evt.currentTarget);
        }}
      >
        {props.text} {!openFeatureFlag ? '⇧' : '⇩'}
      </Button>
      <Popover
        id={featureFlagPopoverId}
        open={openFeatureFlag}
        anchorEl={anchorElFeatureFlags}
        onClose={(): void => setAnchorElFeatureFlags(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>User</td>
              <td>
                <a
                  href={`https://app.launchdarkly.com/default/production/users/${
                    user ? user.usersId : ''
                  }`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {user ? user.fullName : ''}
                </a>
              </td>
            </tr>

            {devSettings.disableAllFF.value && (
              <tr>
                <td colSpan={2} style={{ textAlign: 'center', backgroundColor: 'red' }}>
                  ALL FLAGS ARE DISABLED
                </td>
              </tr>
            )}
            {featureFlags.map((ff: FlagInfo): ReactElement => {
              return (
                <tr
                  key={ff.key}
                  title={!ff.existsOnLD ? 'Feature flag does not exist in Lauch Darkly' : ''}
                >
                  <td>
                    {ff.existsOnLD ? (
                      <a
                        href={`https://app.launchdarkly.com/default/production/features/${ff.key}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {ff.key}
                      </a>
                    ) : (
                      ff.key
                    )}
                  </td>
                  <td align="right">{ff.existsOnLD ? (ff.hasAccess ? '✅' : '❌') : '?'}</td>
                </tr>
              );
            })}
            {props.canDisable && (
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={storeInSession}
                        onChange={(_, checked): void => {
                          setStoreInSession(checked);
                        }}
                      />
                    }
                    label="Store in session"
                  />
                </td>
                <td style={{ textAlign: 'center' }}>
                  <BaseButton
                    onClick={(): void => {
                      dispatch(
                        devSettingsActions.toggleDisableAllFf(
                          !devSettings.disableAllFF.value,
                          storeInSession,
                        ),
                      );
                    }}
                  >
                    {`${devSettings.disableAllFF.value ? 'ENABLE FLAGS' : 'DISABLE ALL FLAGS'}`}
                  </BaseButton>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Popover>
    </>
  );
};

export default FlagsPopover;
