import React, { PropsWithChildren, ReactElement } from 'react';

import { DD_ENV } from 'Helpers';

import type { datadogRum as ddRumType } from '@datadog/browser-rum';
import { useDataDog } from 'Components/Hooks/useDataDogRum';


export const DataDogRumContext = React.createContext<typeof ddRumType | undefined>(undefined);

export const DataDogRumProvider = (props: PropsWithChildren<unknown>): ReactElement => {
  const { datadogRum } = useDataDog({
    version: 'v35.2395005',
    applicationId: '93c20b93-de80-4aa2-9d5c-2900bbf9fa86',
    clientToken: 'pub04e7cf786287c3d616f7d5fdb59ea28a',
    service: 'Vendor Portal',
    env: DD_ENV,
    includeReplay: true,
  });

  return (
    <DataDogRumContext.Provider value={datadogRum}>{props.children}</DataDogRumContext.Provider>
  );
};
