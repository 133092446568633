import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import Icon from 'Assets/images/svg/InvoiceIcon/InvoiceIcon.svg';

const InvoiceIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={Icon} viewBox="0 0 24 24" {...props} />
);

export default InvoiceIcon;
