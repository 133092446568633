import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { OnboardUser } from 'Models/User/_types_/User';
import {
  ModifyMicrosoftUserConfig,
  userServiceApiBaseUrl,
} from 'UserAuthentication/_LoginUserServices/userService';

const SEND_FIRST_TIME_INVITATION = userServiceApiBaseUrl('onboard');
const SEND_INVITATION_FOR_CHANGE_EMAIL = userServiceApiBaseUrl('change-email');

export const useHandleMicrosoftEmail = (
  email: string,
  authToken: string,
  isChangeEmail?: boolean,
): UseLazyBestApiTuple<OnboardUser, ModifyMicrosoftUserConfig> => {
  const url = isChangeEmail ? SEND_INVITATION_FOR_CHANGE_EMAIL : SEND_FIRST_TIME_INVITATION;
  return useLazyBestApi<OnboardUser, ModifyMicrosoftUserConfig>(url, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'USERS-API-VERSION': '2.0',
    },
    method: isChangeEmail ? 'PUT' : 'POST',
    data: {
      email,
    },
  });
};
