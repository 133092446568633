import { CheckboxState, CheckboxStateEnum } from '_types_/CheckBoxState';

import { TableState } from '../_types_/TableState';

import { getSelectableRows } from './getSelectableRows';

export const getAreAllRowsInViewSelected = (state: TableState): CheckboxState => {
  const availableRowKeys = getSelectableRows(state);

  const alreadySelectedRows = availableRowKeys.filter((r) => !!state.selectedRows[r]);

  if (alreadySelectedRows.length === 0) {
    return CheckboxStateEnum.NONE;
  }
  if (alreadySelectedRows.length === availableRowKeys.length) {
    return CheckboxStateEnum.ALL;
  }
  return CheckboxStateEnum.SOME;
};
