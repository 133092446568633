import IdAndNameValueObject from 'Invoice/SampleProforma/_models/ValueObjects/IdAndNameValueObject';

class BuyerContactValueObject {
  private readonly email: string;
  private readonly nameAndId: IdAndNameValueObject;

  constructor(id: number | undefined, name: string | undefined, email: string | undefined) {
    try {
      this.nameAndId = new IdAndNameValueObject(id, name);
    } catch {
      throw new Error('Buyer id and name are required');
    }
    if (email === undefined) {
      throw new Error('Buyer email is required');
    }
    this.email = email;
  }

  public getId(): number {
    return this.nameAndId.getId();
  }

  public getName(): string {
    return this.nameAndId.getName();
  }

  public getEmail(): string {
    return this.email;
  }
}

export default BuyerContactValueObject;
