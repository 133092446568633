import { Key } from 'react';

export enum OrderStateEnum {
  'NEW' = 'New',
  'CONFIRMED' = 'Confirmed',
  'AWAITING_VENDOR' = 'Awaiting vendor',
  'AWAITING_PURCHASER' = 'Awaiting purchaser',
  'COMPLETED' = 'Completed',
  'CANCELLED' = 'Cancelled',
  'none' = 'none',
}

export const selectedOrderStatesMappingToType = (
  selectedOrderStates: Key[]
): (keyof typeof OrderStateEnum)[] => {
  return selectedOrderStates.map((item: Key): keyof typeof OrderStateEnum => {
    switch (item) {
      case 1:
        return 'NEW';
      case 2:
        return 'CONFIRMED';
      case 3:
        return 'AWAITING_VENDOR';
      case 4:
        return 'AWAITING_PURCHASER';
      case 5:
        return 'COMPLETED';
      case 6:
        return 'CANCELLED';
      default:
        return 'none';
    }
  });
};

export const selectedOrderStatesMappingToNumber = (
  selectedOrderStates: (keyof typeof OrderStateEnum)[] | null
): number[] => {
  return selectedOrderStates
    ? selectedOrderStates.map((element: keyof typeof OrderStateEnum): number => {
        switch (element) {
          case 'NEW':
            return 1;
          case 'CONFIRMED':
            return 2;
          case 'AWAITING_VENDOR':
            return 3;
          case 'AWAITING_PURCHASER':
            return 4;
          case 'COMPLETED':
            return 5;
          case 'CANCELLED':
            return 6;
          default:
            return -1;
        }
      })
    : [];
};
