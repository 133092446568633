import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Mutation = {
  __typename?: 'Mutation';
  addProject: ProjectDto;
  deleteProject: ProjectDto;
  removePapskilt: ProjectDto;
  setPapskilt: ProjectDto;
};


export type MutationAddProjectArgs = {
  projectName: Scalars['String']['input'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemovePapskiltArgs = {
  projectId: Scalars['String']['input'];
};


export type MutationSetPapskiltArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
};

export type PapskiltChangedEvent = {
  __typename?: 'PapskiltChangedEvent';
  id: Scalars['String']['output'];
  payload: PapskiltDto;
  projectId: Scalars['String']['output'];
};

export type PapskiltDto = {
  __typename?: 'PapskiltDto';
  message?: Maybe<Scalars['String']['output']>;
};

export type ProjectDto = {
  __typename?: 'ProjectDto';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  papskilt?: Maybe<PapskiltDto>;
};

export type Query = {
  __typename?: 'Query';
  papskilt: PapskiltDto;
  project: ProjectDto;
  projects: Array<ProjectDto>;
};


export type QueryPapskiltArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  papskiltChanged: PapskiltChangedEvent;
};


export type SubscriptionPapskiltChangedArgs = {
  projectId: Scalars['String']['input'];
};

export type GetPapskiltQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;


export type GetPapskiltQuery = { __typename?: 'Query', papskilt: { __typename?: 'PapskiltDto', message?: string | null } };

export type PapskiltChangedSubscriptionVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;


export type PapskiltChangedSubscription = { __typename?: 'Subscription', papskiltChanged: { __typename?: 'PapskiltChangedEvent', payload: { __typename?: 'PapskiltDto', message?: string | null } } };


export const GetPapskiltDocument = gql`
    query getPapskilt($projectId: String!) @api(name: papskilt) {
  papskilt(projectId: $projectId) {
    message
  }
}
    `;

/**
 * __useGetPapskiltQuery__
 *
 * To run a query within a React component, call `useGetPapskiltQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPapskiltQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPapskiltQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetPapskiltQuery(baseOptions: Apollo.QueryHookOptions<GetPapskiltQuery, GetPapskiltQueryVariables> & ({ variables: GetPapskiltQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPapskiltQuery, GetPapskiltQueryVariables>(GetPapskiltDocument, options);
      }
export function useGetPapskiltLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPapskiltQuery, GetPapskiltQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPapskiltQuery, GetPapskiltQueryVariables>(GetPapskiltDocument, options);
        }
export function useGetPapskiltSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPapskiltQuery, GetPapskiltQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPapskiltQuery, GetPapskiltQueryVariables>(GetPapskiltDocument, options);
        }
export type GetPapskiltQueryHookResult = ReturnType<typeof useGetPapskiltQuery>;
export type GetPapskiltLazyQueryHookResult = ReturnType<typeof useGetPapskiltLazyQuery>;
export type GetPapskiltSuspenseQueryHookResult = ReturnType<typeof useGetPapskiltSuspenseQuery>;
export type GetPapskiltQueryResult = Apollo.QueryResult<GetPapskiltQuery, GetPapskiltQueryVariables>;
export const PapskiltChangedDocument = gql`
    subscription papskiltChanged($projectId: String!) @api(name: papskilt) {
  papskiltChanged(projectId: $projectId) {
    payload {
      message
    }
  }
}
    `;

/**
 * __usePapskiltChangedSubscription__
 *
 * To run a query within a React component, call `usePapskiltChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePapskiltChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePapskiltChangedSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePapskiltChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<PapskiltChangedSubscription, PapskiltChangedSubscriptionVariables> & ({ variables: PapskiltChangedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PapskiltChangedSubscription, PapskiltChangedSubscriptionVariables>(PapskiltChangedDocument, options);
      }
export type PapskiltChangedSubscriptionHookResult = ReturnType<typeof usePapskiltChangedSubscription>;
export type PapskiltChangedSubscriptionResult = Apollo.SubscriptionResult<PapskiltChangedSubscription>;