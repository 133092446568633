import { Component, ReactElement } from 'react';

import { SvgIcon } from '@mui/material';

import Icon from 'Assets/images/svg/SourcingIcon/sourcing.svg';

class SourcingIcon extends Component {
  render(): ReactElement {
    return <SvgIcon {...this.props} component={Icon} viewBox="0 0 24 24" />;
  }
}

export default SourcingIcon;
