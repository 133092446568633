import Chip from '@mui/material/Chip';

export interface SearchChipProps<T extends string> {
  label: string;
  isLast: boolean;
  type?: T; // NOTE: Used in SearchBar.tsx
  onDelete: () => void;
}

export const SearchChip = <T extends string>({
  label,
  isLast,
  onDelete,
}: SearchChipProps<T>): React.ReactElement => {
  return (
    <Chip label={label} style={{ marginRight: isLast ? undefined : '.5rem' }} onDelete={onDelete} />
  );
};
