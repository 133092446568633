import { TableState } from '../_types_/TableState';

export const SET_TABLE_NAME = 'SET_TABLE_NAME';

export interface SetTableName {
  type: typeof SET_TABLE_NAME;
  tableName: string;
}

export const doSetTableName = (tableName: string): SetTableName => ({
  type: SET_TABLE_NAME,
  tableName,
});

export const applySetTableName = (newState: TableState, action: SetTableName): TableState => {
  newState.tableName = action.tableName;

  return newState;
};
