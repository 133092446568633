import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { vpFeatureFlags } from 'FeatureFlags/FeatureFlags';
import { FlagInfo } from 'FeatureFlags/interface/FlagInfo';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

export const useFeatureFlags = (): ((
  flags: typeof vpFeatureFlags.getAllFeatureFlags,
  ldFlags: LDFlagSet
) => FlagInfo[]) => {
  const devSettings = useSelector((root: RootReducerState) => root.devSettingsState);
  const disabledAllFF = devSettings.disableAllFF;
  return useCallback(
    (flags: typeof vpFeatureFlags.getAllFeatureFlags, ldFlags: LDFlagSet) => {
      const ldFF: FlagInfo[] = [];

      flags().forEach((flag): void => {
        ldFF.push({
          key: flag,
          hasAccess: disabledAllFF.value ? false : ldFlags[flag] || false,
          existsOnLD: typeof ldFlags[flag] === typeof Boolean(),
        });
      });

      return ldFF;
    },
    [disabledAllFF.value]
  );
};
