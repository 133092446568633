import { ReactElement } from 'react';

import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';

import useDraggable from 'Components/Table/Components/Helpers/useDraggable';
import SelectAll from 'Components/Table/Components/ToolbarActions/SelectAll';

import { TableRecord } from '../state/_types_/TableRecord';
import { getShouldTableCollapse } from '../state/selectors/getShouldTableCollapse';
import { useTableState } from '../state/useTableState';

import TableHeaderCell from './TableHeaderCell';

const TableHeader = <T extends TableRecord>({
  onCheckAllCallback,
}: {
  onCheckAllCallback: (checked: boolean) => void;
}): ReactElement => {
  const { state } = useTableState<T>();

  const {
    dragging,
    dragOver,
    handleDragEnter,
    handleDragOver,
    handleDragStart,
    handleOnDrop,
    handleDragEnd,
  } = useDraggable<T>();

  if (getShouldTableCollapse(state)) {
    return <></>;
  }
  return (
    <MuiTableHead>
      <MuiTableRow>
        {state.tableOptions.expandable && <MuiTableCell padding="checkbox" />}
        {state.tableOptions.selectable &&
          (state.tableOptions.selectAll ? (
            <MuiTableCell padding="checkbox">
              <SelectAll onCheckAllCallback={onCheckAllCallback} />
            </MuiTableCell>
          ) : (
            <MuiTableCell padding="checkbox" />
          ))}

        {state.columns.map((column) => (
          <TableHeaderCell
            dragging={dragging}
            id={column.property}
            key={`tableHeaderCell-${column.property}-${column.label}`}
            column={column}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleOnDrop}
            onDragEnter={handleDragEnter}
            onDragEnd={handleDragEnd}
            dragOver={dragOver}
          />
        ))}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default TableHeader;
