import { createContext } from 'react';

import { SampleProformaHeaderDispatch } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderDispatch';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const SampleProformaHeaderContext =
  createContext<
    | {
        state: SampleProformaHeaderState;
        dispatch: SampleProformaHeaderDispatch;
      }
    | undefined
  >(undefined);
