import { Key } from 'react';

import { validators as BestValidators } from 'Components/Shared/Forms/Validators';

export type BestValidator = (val: Key) => string | undefined;

export const validate = (
  value: Key,
  validators: BestValidator[] | BestValidator | undefined,
  required?: boolean
): string[] | undefined => {
  if (validators || required) {
    let combinedValidators: BestValidator[] = required ? [BestValidators.required] : [];
    if (validators) {
      if (!Array.isArray(validators)) {
        combinedValidators = [...combinedValidators, validators];
      } else {
        combinedValidators = [...combinedValidators, ...validators];
      }
    }

    return combinedValidators
      .map((x: BestValidator): string | undefined => {
        return x(value);
      })
      .filter((x): x is string => {
        return x !== undefined;
      });
  }

  return undefined;
};
