import { TableState } from '../_types_/TableState';

export const SET_TABLE_REFETCH = 'SET_TABLE_REFETCH';

export interface SetTableRefetch {
  type: typeof SET_TABLE_REFETCH;
  shouldRefetch: boolean;
}

export const doSetTableRefetch = (shouldRefetch: boolean): SetTableRefetch => ({
  type: SET_TABLE_REFETCH,
  shouldRefetch: shouldRefetch,
});

export const applySetTableRefetch = (newState: TableState, action: SetTableRefetch): TableState => {
  newState.shouldRefetch = action.shouldRefetch;

  return newState;
};
