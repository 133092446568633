import { Suspense, useMemo } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { STYLES_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { RouteConfig } from '.';

const Styles = lazyImport(() => import('Styles'));

export const useSTYLES_ROUTES = (): RouteConfig => {
  return useMemo(
    () => ({
      label: 'Styles',
      to: STYLES_TO,
      permission: PERMISSION.VP_STYLES_VIEW,
      icon: getIconByIconName('style'),
      color: '#bc8940',
      component: (
        <Suspense fallback={<LazyLoadingMessage view="Styles" />}>
          <Styles />
        </Suspense>
      ),
    }),
    [],
  );
};
