import React, { ReactElement, useCallback, useState } from 'react';

import { Box, Link, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import LoginButton from 'UserAuthentication/Login/LoginButton';

type Props = {
  goToCredentialsForm: () => void;
};

const Welcome = (props: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const isMobile = useIsMobile();

  const handleClickLink = useCallback(() => {
    props.goToCredentialsForm();
  }, [props]);

  return (
    <Paper
      elevation={0}
      sx={{
        height: isMobile ? '100%' : '350px',
        width: isMobile ? '100%' : '700px',
        padding: isMobile ? 1.5 : 5,
      }}
    >
      <Grid container sx={{ height: '100%' }} flexDirection="column" justifyContent="space-between">
        <Grid>
          <Box>
            <Typography fontSize={28} fontWeight={700}>
              Welcome to
            </Typography>
            <Typography fontSize={32} fontWeight={700}>
              BESTSELLER Vendor Portal
            </Typography>
          </Box>
        </Grid>
        <Grid>
          <LoginButton
            setError={setError}
            height="75px"
            setLoading={(isLoading) => setLoading(isLoading)}
            goToCredentialsForm={props.goToCredentialsForm}
          />
        </Grid>
        {!loading && (
          <Grid>
            <Typography fontSize={18}>
              <span>If you are not linked to a Microsoft account, please&nbsp;</span>
              <Link color="info.main" onClick={handleClickLink}>
                click here
              </Link>
            </Typography>
          </Grid>
        )}
        {error.length > 0 && (
          <Grid>
            <Typography>{error}</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default Welcome;
