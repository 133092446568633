import { useEffect } from 'react';


import _ from 'lodash';

import { TableColumn } from 'Components/Table/state/_types_/TableColumn';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { useTableState } from 'Components/Table/state/useTableState';
import { sortArrayByProperty } from 'Helpers/util';

import { doSetTableColumns } from '../../state/actions/setTableColumns';

const useColumnsHelper = <T extends TableRecord>(columns: TableColumn<T>[]): void => {
  const { state, dispatch } = useTableState<T>();

  useEffect(() => {
    if (
      !_.isEqual(
        sortArrayByProperty(columns, 'property'),
        sortArrayByProperty(state.columns, 'property')
      )
    ) {
      dispatch(doSetTableColumns(columns));
    }
  }, [columns, dispatch, state.columns]);
};

export default useColumnsHelper;
