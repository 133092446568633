import { ReactElement, ReactNode } from 'react';

import { PriorityHigh } from '@mui/icons-material';
import AlertErrorOutline from '@mui/icons-material/ErrorOutline';
import Info from '@mui/icons-material/Info';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as ReactDOMClient from 'react-dom/client';

import { ThemeWrapper } from '../../../Theme/index';

const useStyles = () => {
  const theme = useTheme();
  return makeStyles(() => ({
    error: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    info: {
      color: theme.palette.info.main,
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 16,
      },
    },
  }))();
};

type Severity = 'error' | 'info' | 'warning';

interface Props {
  open: boolean;
  title?: ReactNode;
  explanation?: ReactNode;
  giveAnswer: (confirmed: boolean) => void;

  severity?: Severity;
}

const ConfirmDialog = (props: Props): ReactElement => {
  const classes = useStyles();

  const getIcon = () => {
    switch (props.severity) {
      case 'error':
        return <AlertErrorOutline className={classes[props.severity]} />;
      case 'warning':
        return <PriorityHigh className={classes[props.severity]} />;
      default:
        return <Info className={classes[props.severity ?? 'info']} />;
    }
  };

  return (
    <Dialog
      open
      fullWidth
      keepMounted
      aria-labelledby="confirm-dialog-slide-title"
      aria-describedby="confirm-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText
          id="confirm-dialog-slide-description"
          color="textPrimary"
          component="div"
        >
          <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
            {getIcon()}
            <Typography variant="h6">{props.title ?? 'Please confirm'}</Typography>
          </DialogTitle>
          {props.explanation && <DialogContent dividers>{props.explanation} </DialogContent>}
        </DialogContentText>
        <DialogActions>
          <Button variant="contained" onClick={(): void => props.giveAnswer(false)}>
            No
          </Button>
          <Button variant="contained" color="primary" onClick={(): void => props.giveAnswer(true)}>
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;

export const customConfirm = (
  title: ReactNode,
  options?: {
    explanation?: ReactNode;
    severity?: Severity;
  },
): Promise<boolean> =>
  new Promise((res) => {
    const confirmRoot = document.createElement('div');
    confirmRoot.setAttribute('id', 'confirm-dialog');
    const root = ReactDOMClient.createRoot(confirmRoot);

    const giveAnswer = (answer: boolean) => {
      if (confirmRoot) {
        root.unmount();
      }
      res(answer);
    };
    const component = (
      <ThemeWrapper>
        <ConfirmDialog
          title={title}
          giveAnswer={giveAnswer}
          open
          explanation={options?.explanation}
          severity={options?.severity}
        />
      </ThemeWrapper>
    );

    root.render(component);
  });
