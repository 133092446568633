import { PropsWithChildren, ReactElement } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';

import { TableColumn } from 'Components/Table/state/_types_/TableColumn';
import { useTableState } from 'Components/Table/state/useTableState';

import { TableRecord } from '../state/_types_/TableRecord';
import { TableStateDispatch } from '../state/_types_/TableStateDispatch';
import { doExpandTableRow } from '../state/actions/expandRow';
import { getColspan } from '../state/selectors/getColspan';
import { getIsRowExpanded } from '../state/selectors/getIsRowExpanded';
import { getIsRowSelected } from '../state/selectors/getIsRowSelected';
import { getShouldTableCollapse } from '../state/selectors/getShouldTableCollapse';

import SelectRow from './RowActions/SelectRow';
import TableCard from './TableCard';
import TableCell from './TableCell';

interface Props<T extends TableRecord> {
  row: T;
  ExpandedRowContent?: (props: { row: T; dispatch: TableStateDispatch<T> }) => ReactElement;
  customRowClassName?: (row: T, isCollapsed: boolean) => string | undefined;
  hyperControlledSelectedRows?: boolean;
}

// Utility function to get nested property
export const getNestedProperty = (obj: any, path: string): any =>
  path.split('.').reduce((acc, key) => acc?.[key], obj);

const TableRow = <T extends TableRecord>(
  props: PropsWithChildren<Props<T>> & {
    onCheckCallback?: (selectedId: string | number, checked: boolean) => void;
  },
): ReactElement => {
  const { state, dispatch } = useTableState<T>();

  const { primaryKey } = state;
  const primaryKeyOfRow = String(
    primaryKey.includes('.') ? getNestedProperty(props.row, primaryKey) : props.row[primaryKey],
  );
  // Will be set on breakpoint
  const shouldCollapse = getShouldTableCollapse(state);
  const colSpan = getColspan(state);
  const selected = getIsRowSelected(state, primaryKeyOfRow);
  const expanded = getIsRowExpanded(state, primaryKeyOfRow);

  if (shouldCollapse) {
    return (
      <>
        <TableCard<T>
          className={props.customRowClassName?.(props.row, shouldCollapse)}
          key={`tableCard-${primaryKeyOfRow}`}
          row={props.row}
          hasExpandedRow={state.tableOptions.expandable}
        />
        {state.tableOptions.expandable && props.ExpandedRowContent && (
          <MuiTableRow>
            <MuiTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <props.ExpandedRowContent row={props.row} dispatch={dispatch} />
              </Collapse>
            </MuiTableCell>
          </MuiTableRow>
        )}
      </>
    );
  }

  return (
    <>
      <MuiTableRow
        className={props.customRowClassName?.(props.row, shouldCollapse)}
        hover={state.tableOptions.selectable}
        selected={selected}
      >
        {state.tableOptions.expandable && (
          <MuiTableCell padding="checkbox">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                dispatch(doExpandTableRow(primaryKeyOfRow));
              }}
            >
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </MuiTableCell>
        )}
        {state.tableOptions.selectable && (
          <SelectRow
            row={props.row}
            onCheckCallback={props.onCheckCallback}
            hyperControlledSelectedRows={props.hyperControlledSelectedRows}
          />
        )}
        {state.columns.map((column: TableColumn<T>) => (
          <TableCell<T>
            key={`tableColumn-${column.property}-${column.label}`}
            column={column}
            row={props.row}
          />
        ))}
      </MuiTableRow>
      {state.tableOptions.expandable && props.ExpandedRowContent && (
        <MuiTableRow>
          <MuiTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <props.ExpandedRowContent row={props.row} dispatch={dispatch} />
            </Collapse>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </>
  );
};

export default TableRow;
