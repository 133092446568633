import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Paper, TextField, Typography } from '@mui/material';

import getErrorMessage from 'Components/Hooks/useBestApi/getErrorMessage';
import { useHandleMicrosoftEmail } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useHandleMicrosoftEmail';
import { useRevokeAccess } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useRevokeAccess';
import Invited from 'UserAuthentication/Login/LoginScreens/Invited';

type Props = {
  token: string;
  goToWelcome: () => void;
  isInvited?: boolean; // If this prop is true, user is changing email in this screen
};

const EmailForm = (props: Props): ReactElement => {
  const [email, setEmail] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedInvitation, setFetchedInvitation] = useState(false);
  const [invitationHasBeenSent, setInvitationHasBeenSent] = useState(false);

  const [revokeAccess] = useRevokeAccess(props.token);

  const [fetch, { loading, error, data }] = useHandleMicrosoftEmail(
    email ?? '',
    props.token,
    props.isInvited,
  );

  const pattern = useMemo(
    () =>
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    [],
  );

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setEmail(undefined);
        return;
      }
      if (pattern.test(e.target.value)) {
        setIsValidated(true);
        setErrorMessage('');
      } else {
        setIsValidated(false);
        setErrorMessage('Invalid email');
      }
      setEmail(e.target.value);
    },
    [pattern],
  );

  const handleSendInvitation = useCallback(() => {
    if (!email) {
      setErrorMessage('Please fill in the email field');
      return;
    }
    setIsLoading(true);
    fetch();
  }, [email, fetch]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      setErrorMessage(getErrorMessage(error));
      setIsLoading(false);
      return;
    }
    if (data) {
      revokeAccess().then(() => {
        setErrorMessage('');
        setFetchedInvitation(true);
      });
    }
  }, [data, error, loading, revokeAccess]);

  useEffect(() => {
    if (fetchedInvitation && errorMessage.length === 0) {
      setInvitationHasBeenSent(true);
    }
  }, [fetchedInvitation, errorMessage]);

  if (invitationHasBeenSent && email) {
    return <Invited goToWelcome={props.goToWelcome} email={email} />;
  }

  return (
    <Paper elevation={0} sx={{ height: '300px', width: '700px', padding: 5 }}>
      <Box>
        <Typography fontSize={28} fontWeight={700} mb={1}>
          Which email do you want to use to join Microsoft Login system?
        </Typography>
        <Box display="flex" flexDirection="column" mt={2}>
          <TextField
            label="Email"
            value={email ?? ''}
            onChange={handleChangeEmail}
            helperText={errorMessage === '' ? ' ' : errorMessage}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="end">
          <Button
            disableElevation
            variant="contained"
            disabled={!email || !isValidated || isLoading}
            onClick={handleSendInvitation}
          >
            {!isLoading ? 'Send invitation' : 'Loading...'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default EmailForm;
