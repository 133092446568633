import { ReactElement, AnchorHTMLAttributes, ReactNode } from 'react';

import { useHasPermission } from 'Models/Permission/permissionHelper';

import classes from './BestLink.module.scss';
export interface BestLinkProps {
  permission: number;
  noAccess?: 'hidden' | 'text';
  disabled?: boolean;
}

const BestLink = (props: BestLinkProps & AnchorHTMLAttributes<HTMLAnchorElement>): ReactElement => {
  const noSecurity = (noAccess: string, children: ReactNode): ReactElement => {
    return noAccess === 'hidden' ? <></> : <span>{children}</span>;
  };

  const hasPermission = useHasPermission();

  return hasPermission(props.permission) ? (
    props.disabled ? (
      <div className={[props.className, classes.disabled].join(' ')}>{props.children}</div>
    ) : (
      <a {...props}>{props.children}</a>
    )
  ) : (
    noSecurity(props.noAccess ? props.noAccess : '', props.children)
  );
};

export default BestLink;
