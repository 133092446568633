import {
  ToggleDisableAllFf,
  TOGGLE_DISABLE_ALL_FF,
  SET_FAKE_EXT_ERROR,
  SetFakeExtError,
} from 'Store/actions/devSettings.actions';

export interface DevSettingsState {
  disableAllFF: { value: boolean; fromSession: boolean };
  fakeExtError: {
    fullFailure: boolean | undefined;
    partialFailure: boolean | undefined;
  };
}

const initialState: DevSettingsState = {
  disableAllFF: { value: false, fromSession: false },
  fakeExtError: { fullFailure: undefined, partialFailure: undefined },
};

export default (
  state = initialState,
  action: ToggleDisableAllFf & SetFakeExtError
): DevSettingsState => {
  if (action.type === TOGGLE_DISABLE_ALL_FF) {
    return {
      ...state,
      disableAllFF: {
        value: action.value,
        fromSession: action.storeInSession,
      },
    };
  }
  if (action.type === SET_FAKE_EXT_ERROR) {
    return {
      ...state,
      fakeExtError: {
        fullFailure: action.full ? action.full : undefined,
        partialFailure: action.partial ? action.partial : undefined,
      },
    };
  }
  return state;
};
