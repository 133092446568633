export const SET_USER = 'set_user';
export const UPDATE_USER_MDM = 'update_user_mdm';
export const DELETE_USER = 'delete_user';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER_CONFIG = 'SET_USER_CONFIG';
export const USER_SETTINKS_KEY = 'usersettings';
export const CAMPAIGN_SETTINGS_KEY = (campaignId: string): string =>
  `campaign-settings-${campaignId}`;
export const FAKE_PERMISSION = 'fake_permission';

export const CUTOFF_DATE_AD_LOGIN = '2021/04/01';
