import axios from 'axios';
import { properties } from 'Helpers';
import qs from 'qs';

import { User } from 'Models/User/_types_/User';

const branchNumber = sessionStorage.getItem('supportBranch')
  ? sessionStorage.getItem('supportBranch') + '/'
  : '';

 
axios.defaults.paramsSerializer = (params: any): string => {
  return qs.stringify(params, { indices: false, skipNulls: true }); // param=value1&param=value2
};
interface CreateIncidentParams {
  user?: string;
  message: string;
  email: string;
  context: {
    windowArea: string;
    windowLocation: string;
    user?: User;
    browserVersion: string;
    ddSessionUrl: string;
    ddSessionReplayUrl: string;
  };
}

export interface CreateIncidentResponse {
  number: string;
  sysId: string;
}

export const CREATE_SN_INCIDENT =
  properties.serviceApiURL + branchNumber + `servicenow/create-incident`;

export const handleCreateServiceNowIncident = (
  values: CreateIncidentParams
): Promise<CreateIncidentResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        CREATE_SN_INCIDENT,
        {
          short_description: values.message.split('.')[0].split('\n')[0],
          description: values.message,
          caller_id: values.email,
          u_ref_: values.email,
          u_category: 'Vendor Portal',
          assignment_group: 'Team Vendor Portal',
          contact_type: 'Email',
          work_notes: `
DataDog Session Url: [code]<a href="${
            values.context.ddSessionUrl
          }" rel="noopener noreferrer" target="_blank">DataDog Session</a>[/code]
DataDog Session Replay: [code]<a href="${
            values.context.ddSessionReplayUrl
          }" rel="noopener noreferrer" target="_blank">DataDog Session Replay</a>[/code]
Window Area: ${values.context.windowArea}
Window Location: ${values.context.windowLocation}
User: ${values.context.user ? JSON.stringify(values.context.user, undefined, 4) : 'Not logged in'}
Browser Version: ${values.context.browserVersion}
    `,
        },
        {
          headers: { 'SERVICENOW-API-VERSION': '2.0' },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

// TODO: Make users be able to make feature requests in the SupportModal
// export const handleCreateFeatureRequest = () => {
// }
