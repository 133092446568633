import { Suspense, useMemo } from 'react';

import { PERMISSION } from 'Constants/permissions.constants';
import { CLAIM_TO, CREDIT_NOTE_TO } from 'Constants/shared.constants';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import lazyImport from 'Helpers/lazyImport';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { getIconByIconName } from '../../Components/Shared/Icons/Icons';
import NoAccess from '../NoAccess/NoAccess';

import { RouteConfig } from './index';

const Claim = lazyImport(() => import('Claim/Claims'));
const CreditNote = lazyImport(() => import('Claim/CreditNote'));

export const useCLAIM_ROUTES = (): RouteConfig => {
  const getFeatureFlag = useGetFeatureFlag();
  return useMemo(
    () => ({
      label: 'Claims',
      to: CLAIM_TO,
      icon: getIconByIconName('contentPaste'),
      color: 'darkgreen',
      featureFlag: getFeatureFlag('claims'),
      component: (
        <Suspense fallback={<LazyLoadingMessage view="Claims" />}>
          <Claim />
        </Suspense>
      ),
      fallbackComponent: NoAccess,
      permission: PERMISSION.VP_CLAIM_VIEW,
      children: {
        Claim: {
          display: true,
          label: 'Claims',
          to: CLAIM_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Claims" />}>
              <Claim />
            </Suspense>
          ),
          featureFlag: getFeatureFlag('claims'),
          fallbackComponent: NoAccess,
        },
        CreditNote: {
          display: true,
          label: 'Credit Note',
          to: CREDIT_NOTE_TO,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Credit Note" />}>
              <CreditNote />
            </Suspense>
          ),
          featureFlag: getFeatureFlag('credit-note'),
          fallbackComponent: NoAccess,
          permission: PERMISSION.VP_CREDIT_NOTE_VIEW,
        },
      },
    }),
    [getFeatureFlag],
  );
};
