import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import UndeleteCartonNumberSvg from 'Assets/images/svg/DeleteCartonNumber/undeleteCartonNumber.svg';

const UndeleteCartonNumberIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={UndeleteCartonNumberSvg} viewBox="0 0 24 24" {...props} />
);

export default UndeleteCartonNumberIcon;
