import { Grid2 } from '@mui/material';
import { Box } from '@mui/system';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';

interface Props {
  view: string;
}

const LazyLoadingMessage = ({ view }: Props) => {
  return (
    <Grid2 container flexDirection="row" display="flex" justifyContent="center" alignItems="center">
      <Box>
        <Loading message={`Loading ${view}...`} direction="row" />
      </Box>
    </Grid2>
  );
};

export default LazyLoadingMessage;
