import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import classes from './ClearButton.module.scss';

interface PropTypes {
  show: boolean;
  onClick: () => void;
}

export const ClearButton = ({ show, onClick }: PropTypes): React.ReactElement => {
  return (
    <InputAdornment position="end" className={classes.container}>
      {show ? (
        <IconButton onClick={onClick} size="large">
          <Clear />
        </IconButton>
      ) : (
        <></>
      )}
    </InputAdornment>
  );
};
