import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import qs from 'qs';

import { useQueryParams } from 'Components/Hooks/useUrlParams';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { doExpandTableRow } from 'Components/Table/state/actions/expandRow';
import { useTableState } from 'Components/Table/state/useTableState';
import { arraysEqual } from 'Helpers/util';

import { removeUndefinedFromArray } from '../../../../Helpers/util';

export const TABLE_EXP_ID = (tableName: string): string => `${tableName.toLowerCase()}-expandedids`;
export const TABLE_EXP_PARAM_DELIMITER = '.';
export const TABLE_EXP_PARAM_TAB = 'tab-';
/**
 * Handle the expanding and collapsing of rows by appending "{table name}-expandedids" to exisiting query params.
 */
const useTableExpandByUrl = <T extends TableRecord>(): void => {
  const { state, dispatch } = useTableState<T>();

  const [initialLoad, setInitialLoad] = useState(true);
  const [queryExpIds, setQueryExpIds] = useState<string[]>([]);

  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const expId = useMemo(
    () => (!state.tableName ? undefined : `${TABLE_EXP_ID(state.tableName)}`),
    [state.tableName]
  );

  useEffect(() => {
    if (!initialLoad || expId === undefined) {
      return;
    }

    setInitialLoad(false);

    const activeExpandedIds = [queryParams[expId]].flat();

    activeExpandedIds
      .filter(removeUndefinedFromArray)
      .map((r) => r.split('.')[0])
      .forEach((id) => {
        if (id === undefined) {
          return;
        }
        dispatch(doExpandTableRow(id));
      });
  }, [dispatch, expId, initialLoad, queryParams, state.tableName]);

  useEffect((): void => {
    if (
      initialLoad ||
      expId === undefined ||
      arraysEqual(state.expandedRows, queryExpIds) ||
      state.tableOptions.dontAddExpandToUrl
    ) {
      return;
    }

    setQueryExpIds(state.expandedRows ?? []);

    const currentUrlParams = qs.parse(new URLSearchParams(window.location.search).toString());
    const newUpdatedIds = state.expandedRows.map(
      (rowId) =>
        [currentUrlParams[expId]]
          .flat()
          .filter(removeUndefinedFromArray)
          .find((urlParam) => (urlParam as string).split(TABLE_EXP_PARAM_DELIMITER)[0] === rowId) ??
        rowId
    );
    const updatedUrlParams = qs.stringify(
      {
        ...currentUrlParams,
        [expId]: newUpdatedIds ?? null,
      },
      { skipNulls: true, arrayFormat: 'repeat' }
    );

    navigate({ search: updatedUrlParams }, { replace: true });
  }, [
    expId,
    navigate,
    initialLoad,
    queryExpIds,
    state.expandedRows,
    state.tableName,
    state.tableOptions.dontAddExpandToUrl,
  ]);
};

export default useTableExpandByUrl;
