import React, { ReactElement, MouseEvent, useState, useCallback } from 'react';

import { Button, Popover } from '@mui/material';

import BaseButton from 'Components/Shared/BestButton/BaseButton';
import { SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';

const TokenInfo = (): ReactElement => {
  const { authState, authDispatch } = useAuthState();

  const [anchorElFeatureFlags, setAnchorElFeatureFlags] = useState<HTMLButtonElement | null>(null);

  const openTokenInfo = Boolean(anchorElFeatureFlags);
  const buildPopoverId = openTokenInfo ? 'tokenInfoPopoverId' : undefined;

  const expTime = useCallback(() => {
    if ((authState.tokens?.expires ?? 0) > 0) {
      return new Date(authState.tokens?.expires ?? 0).toLocaleString();
    }
    return 'Now';
  }, [authState]);

  return (
    <>
      <Button
        aria-describedby={buildPopoverId}
        variant="text"
        onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
          setAnchorElFeatureFlags(evt.currentTarget);
        }}
      >
        <span>Token info&nbsp;</span>
        <span>{!openTokenInfo ? '⇧' : '⇩'}</span>
      </Button>
      <Popover
        id={buildPopoverId}
        open={openTokenInfo}
        anchorEl={anchorElFeatureFlags}
        onClose={(): void => setAnchorElFeatureFlags(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <table>
          <tr>
            <td colSpan={2}>{authState.tokens?.accessToken}</td>
          </tr>
          <tr>
            <td>Token expires:</td>
            <td>{expTime()}</td>
          </tr>
          <tr>
            <td />
            <td>
              <BaseButton
                onClick={(): void => {
                  if (authState?.tokens) {
                    authDispatch({
                      type: SET_TOKENS,
                      tokens: {
                        ...authState.tokens,
                        expires: 0,
                      },
                    });
                    alert('Token is expired now lol');
                  }
                }}
              >
                Expire token
              </BaseButton>
            </td>
          </tr>
          <tr>
            <td>
              <BaseButton
                onClick={(): void => {
                  if (authState?.tokens) {
                    authDispatch({
                      type: SET_TOKENS,
                      tokens: {
                        ...authState.tokens,
                        accessToken: 'BROKEN',
                        refreshToken: 'ALSO_BROKEN',
                        expires: authState.tokens.expires + 1,
                      },
                    });
                    alert('Token MEGA broken lol');
                  }
                }}
              >
                MEGA Break Token
              </BaseButton>
            </td>
            <td>
              <BaseButton
                onClick={(): void => {
                  if (authState?.tokens) {
                    authDispatch({
                      type: SET_TOKENS,
                      tokens: {
                        ...authState.tokens,
                        accessToken: 'BROKEN',
                        expires: authState.tokens.expires + 1,
                      },
                    });
                    alert('Token broken lol');
                  }
                }}
              >
                Break Token
              </BaseButton>
            </td>
          </tr>
        </table>
      </Popover>
    </>
  );
};

export default TokenInfo;
