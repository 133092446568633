import { ReactElement, useCallback, useEffect, useState } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import {
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SvgIcon,
} from '@mui/material';

import FileSaver from 'file-saver';

import { UseBestApiRequestConfig } from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import BaseButton from 'Components/Shared/BestButton/BaseButton';
import BestLink from 'Components/Shared/BestLink/BestLink';
import BestoneErrorDetailList from 'Components/Shared/BestoneErrorDetailList/BestoneErrorDetailList';
import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { openBlobInNewWindow } from 'Helpers/util';
import fileResMapper from 'Services/ext.service/helpers/fileResMapper';
import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';

import classes from './DownloadableLink.module.scss';

interface Props {
  filename: string;
  fileExt: string;
  attachmentsId: number;
  mimeType: string;
  downloadFunction: UseLazyBestApiTuple<
    BestoneResponse<Blob | string>,
    UseBestApiRequestConfig & { method: 'GET' }
  >;
}

const DownloadableLink = (props: Props): ReactElement => {
  const [showDialog, setShowDialog] = useState(false);
  const [callback, { data, loading }] = props.downloadFunction;

  const [downloaded, setDownloaded] = useState(false);

  const handleFile = (_data: Blob | undefined, mimeType: string, filename: string) => {
    if (!_data) {
      return;
    }
    if (['application/pdf', 'PDF'].includes(mimeType)) {
      openBlobInNewWindow(_data);
    } else {
      FileSaver.saveAs(_data, `${filename}`);
    }
    setDownloaded(true);
  };

  useEffect((): void => {
    if (data && !downloaded) {
      const localBestoneFileResponse = fileResMapper.mapResToBlob(
        props.attachmentsId,
        props.mimeType,
        data
      );
      handleFile(localBestoneFileResponse.data, props.mimeType, props.filename);
    }
  }, [data, props.filename, props.mimeType, props.attachmentsId, downloaded]);

  const downloadFile = useCallback(() => {
    if (!loading) {
      setDownloaded(false);
      callback();
    }
  }, [callback, loading]);

  const downloadLink = useCallback((): ReactElement => {
    if ((data?.errors ?? []).length !== 0) {
      return (
        <Tooltip
          onClick={(e): void => {
            e.preventDefault();
            e.stopPropagation();
            setShowDialog(true);
          }}
          title={
            (data?.errors ?? []).find(
              (err): boolean => err.errorIds.indexOf('' + props.attachmentsId) > -1
            )?.errorMessage ?? 'Error'
          }
          classes={{
            tooltip: classes.errorTooltip,
          }}
          className={classes.clickable}
        >
          <ErrorIcon style={{ fontSize: '1rem' }} className={classes.errorIcon} />
        </Tooltip>
      );
    }
    if (loading) {
      return (
        <Tooltip title="Downloading...">
          <CircularProgress size="1rem" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        className={classes.clickable}
        title="Download"
        onClick={(): void => {
          downloadFile();
        }}
      >
        <SvgIcon style={{ fontSize: '1em' }}>{getIconByIconName('download')}</SvgIcon>
      </Tooltip>
    );
  }, [data?.errors, downloadFile, loading, props.attachmentsId]);

  return (
    <>
      <Dialog
        onClose={(): void => {
          setShowDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Error downloading &quot;{props.filename}&quot;
        </DialogTitle>
        <DialogContent>
          <BestoneErrorDetailList errors={data?.errors ?? []} />
        </DialogContent>
        <DialogActions>
          <BaseButton buttonType="cancel" onClick={(): void => setShowDialog(false)}>
            Close
          </BaseButton>
        </DialogActions>
      </Dialog>
      <div className={classes.linkContainer}>
        <div className={classes.tooltipContainer}>{downloadLink()}</div>
        <BestLink
          disabled={loading}
          permission={PERMISSION.VP_VIEW_ORDER}
          className={classes.sheetLink}
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e: React.MouseEvent): void => {
            e.preventDefault();
            downloadFile();
          }}
        >
          <Tooltip title={props.filename}>
            <div className={classes.linkTextContainer}>
              <div className={classes.linkText}>{props.filename}</div>
            </div>
          </Tooltip>
        </BestLink>
      </div>
    </>
  );
};

export default DownloadableLink;
