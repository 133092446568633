import React from 'react';

import { isLocalEnv } from 'Helpers';

import { datadogRum as datadogRumImport } from '@datadog/browser-rum';
import type { RumInitConfiguration } from '@datadog/browser-rum';
import { includeGqlQueryNameInUrl } from 'Components/Hooks/useDataDogRum/datadogHelpers';
import { vpFilterToQueryParams } from 'Components/Hooks/useDataDogRum/vpFilterToQueryParams';

interface CustomHookParams {
  includeReplay?: boolean;
}

export type UseDataDogParams = CustomHookParams &
  Required<
    Pick<RumInitConfiguration, 'applicationId' | 'clientToken' | 'env' | 'service' | 'version'>
  >;

/**
 * Shamelessly stolen from https://github.com/BESTSELLER/bestone-buying-ui-library/tree/master/src/hooks/useDataDog
 *
 * @description This hook can be used to initialise communication with DataDog.
 * @param {string} [applicationId] - The id of the target application
 * @param {string} [clientToken] - The client token issued by DataDog
 * @param {string} [env] - The environment of the target application
 * @param {string} [service] - The name of the target application (usually declared in package.json)
 * @param {string} [version] - The version of the target application (usually declared in package.json)
 * @param {boolean=} [includeReplay] - Whether or not to record sessions for session replay
 * @example
 * ```ts
 * import packageJson from 'path/to/package.json';
 *
 * // Basic initialisation of DataDog
 * useDataDog({
 *   applicationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
 *   clientToken: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
 *   env: process.env.NODE_ENV,
 *   service: packageJson.name,
 *   version: packageJson.version,
 * });
 *
 * // Initialisation and setting user afterwards
 * const { setDataDogUser } = useDataDog({
 *   applicationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
 *   clientToken: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
 *   env: process.env.NODE_ENV,
 *   service: packageJson.name,
 *   version: packageJson.version,
 * });
 *
 * setDataDogUser(token, user);
 *
 * // Initialisation with access to the datadogRum instance
 * const { datadogRum } = useDataDog({
 *   applicationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
 *   clientToken: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
 *   env: process.env.NODE_ENV,
 *   service: packageJson.name,
 *   version: packageJson.version,
 * });
 *
 * datadogRum.addAction('Test log', { testContext: 'someValue' });
 * // Documentation (datadogRum):
 * // Custom actions: https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/?tab=npm#custom-actions
 * // Custom errors:  https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
 * ```
 */
export const useDataDog = ({
  applicationId,
  clientToken,
  env,
  service,
  version,
  includeReplay = false,
}: UseDataDogParams): {
  datadogRum: typeof datadogRumImport;
} => {
  React.useEffect(() => {
    if (isLocalEnv) {
      return;
    }
    if (!datadogRumImport.getInitConfiguration()) {
      datadogRumImport.init({
        site: 'datadoghq.eu',
        trackUserInteractions: true,
        allowedTracingUrls: [
          'https://vendorportal.bestseller.com',
          'https://vendorportal-test.bestseller.com',
          'https://vendorportal-dev.bestseller.com',
          'https://ambassador.bsoneproduct02-prod.k8s.bestcorp.net',
          'https://ambassador.bsoneproduct02-test.k8s.bestcorp.net',
          'https://ambassador.bsoneproduct02-dev.k8s.bestcorp.net',
        ],
        silentMultipleInit: true,
        applicationId,
        clientToken,
        env,
        service,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        defaultPrivacyLevel: 'allow',
        beforeSend: (event, context) =>
          includeGqlQueryNameInUrl(event, context, vpFilterToQueryParams),
      });

      if (includeReplay) {
        datadogRumImport.startSessionReplayRecording();
      }

      datadogRumImport.setUser({
        buildTime: __BUILD_DATE__,
      });
    }
  }, [applicationId, clientToken, env, includeReplay, service, version]);

  return { datadogRum: datadogRumImport };
};
