import { Key, useState, useEffect } from 'react';

import { UseBestApiRequestConfig } from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { SimpleGetRequest } from 'Components/Hooks/useBestApi/_types_/SimpleGetRequest';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { ordersApiBaseUrl } from 'Orders/_services_';
import { OrderTypeFilter } from 'Orders/_types_/OrderTypeFilter';
import {
  ServerOrderGroupOrder,
  ServerGroupOrderOrder,
  GroupOrderOrder,
} from 'Views/FastConfirm/_types_/index';
import { FastConfirmData } from 'Views/FastConfirm/Orders/FastConfirmTable/FastConfirmTypes';

import { ServerBrandWithProductLines, ProductLine } from '../../_types_/MasterData/MasterDataType';
import { UseBaseApiQueryTuple } from '../../Components/Hooks/useBaseApiQuery/_types_/index';
import { useBaseApiQuery } from '../../Components/Hooks/useBaseApiQuery/index';
import { useBestApi } from '../../Components/Hooks/useBestApi/index';
import {
  TableFilterItems,
  TableFilterChildItem,
} from '../../Components/Shared/TableFilter/TableFilter';

import { FETCH_ORDER_GROUPS_DETAILS, FETCH_ORDER_GROUPS_FILTERS } from './index';

export interface GetFastConfirmDataResponse {
  loading: boolean;
  error: Error | undefined;
  data: FastConfirmData | undefined;
}

type GetOrderGroupsRequestConfig = UseBestApiRequestConfig & {
  params: {
    productLineNumber?: Key[];
  };
  method: 'GET';
};

type GetOrderGroupsFiltersRequestConfig = UseBestApiRequestConfig & {
  params: {
    orderGroupId: string;
  };
  method: 'GET';
};

export const useGetOrderGroupsDetails = (
  orderGroupId: string,
  activeBrandsFilters?: Key[]
): UseBaseApiQueryTuple<ServerOrderGroupOrder, GetOrderGroupsRequestConfig> =>
  useBaseApiQuery<ServerOrderGroupOrder, GetOrderGroupsRequestConfig>(
    FETCH_ORDER_GROUPS_DETAILS(orderGroupId),
    {
      method: 'GET',
      headers: {
        'MULTI-CONFIRM-API-VERSION': '2.0',
      },
      params: {
        productLineNumber: activeBrandsFilters,
      },
    }
  );

export const useGetFastConfirmData = (
  orderGroupId: string,
  activeBrandsFilters?: Key[]
): GetFastConfirmDataResponse => {
  const [localData, setLocalData] = useState<FastConfirmData>();
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [loading, error, data, fetch] = useGetOrderGroupsDetails(orderGroupId, activeBrandsFilters);

  const serverGroupOrderTransformer = (sgoo: ServerGroupOrderOrder): GroupOrderOrder => ({
    ...sgoo,
    readyForLabelProduction: sgoo.readyForLabelProduction === 'Y',
    cargoClosing: sgoo.cargoClosing,
    barcodeSupplierNumber: sgoo.barcodeSupplierNumber ?? undefined,
    careLabelSupplierNumber: sgoo.careLabelSupplierNumber ?? undefined,
    factoryNumber: sgoo.factoryNumber ?? undefined,
  });

  useEffect(() => {
    fetch();
    // Should only be called on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** If first fetch */
  useEffect(() => {
    if (!loading && data && !isFetchingMore && !localData) {
      const orderGroupDetailsViewList: GroupOrderOrder[] = (
        data.orderGroupDetailsViewList ?? []
      ).map(serverGroupOrderTransformer);
      setLocalData({
        barcodeLabelSuppliers: data.barCodeLabelSupplierCompanies,
        careLabelSupplier: data.careLabelSupplierCompanies,
        factories: data.subSupplierCompanies,
        orderGroupDetailsViewList,
      });
    }
  }, [data, isFetchingMore, loading, localData]);

  /** If fetching more data */
  useEffect(() => {
    if (isFetchingMore && !loading && data) {
      setLocalData((prevVals: FastConfirmData | undefined): FastConfirmData | undefined => {
        if (!prevVals) {
          return prevVals;
        }

        setIsFetchingMore(false);

        const orderGroupList = data.orderGroupDetailsViewList.map(serverGroupOrderTransformer);
        return {
          ...prevVals,
          orderGroupDetailsViewList: [...prevVals.orderGroupDetailsViewList, ...orderGroupList],
        };
      });
    }
  }, [data, isFetchingMore, loading]);

  return {
    loading: loading,
    error,
    data: localData,
  };
};

export const useGetOrderGroupFilters = (orderGroupId: string): TableFilterItems[] | undefined => {
  const [filters, setFilters] = useState<TableFilterItems[] | undefined>();

  const [_loading, _error, data] = useBestApi<
    ServerBrandWithProductLines[],
    GetOrderGroupsFiltersRequestConfig
  >(FETCH_ORDER_GROUPS_FILTERS, {
    method: 'GET',
    headers: {
      'MULTI-CONFIRM-API-VERSION': '1.0',
    },
    params: { orderGroupId: orderGroupId },
  });

  useEffect(() => {
    const returnedData = (data ?? []).map(
      (brand: ServerBrandWithProductLines): TableFilterItems => {
        return {
          id: brand.brandNumber,
          name: brand.brandName,
          children: brand.productLines.map((pl: ProductLine): TableFilterChildItem => {
            return {
              id: pl.productLineNumber,
              name: pl.productLineName,
            };
          }),
        };
      }
    );
    setFilters(returnedData);
  }, [data]);

  return filters;
};

export const useGetOrderFilterType = (): UseLazyBestApiTuple<OrderTypeFilter[], SimpleGetRequest> =>
  useLazyBestApi<OrderTypeFilter[], SimpleGetRequest>(ordersApiBaseUrl('ordertypes'), {
    method: 'GET',
    headers: {
      'ORDERS-API-VERSION': '1.0',
    },
  });
