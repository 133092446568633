import { useDispatch } from 'react-redux';

import { applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reduxPromise from 'redux-promise';

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'Store/reducers/rootReducer';

import { isDevEnv } from './properties';

export const middlewares = [reduxPromise];

if (isDevEnv) {
  middlewares.push(createLogger());
}

let composeEnhancers = compose;

if (isDevEnv) {
  composeEnhancers = composeWithDevTools({}) || compose;
}
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers({}).concat(enhancer),
});

export type reduxDispatch = typeof store.dispatch;

export const useReduxDispatch = (): reduxDispatch => useDispatch();
