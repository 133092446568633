import MerchandiserOfficeValueObject from 'Invoice/SampleProforma/_models/ValueObjects/MerchandiserOfficeValueObject';
import SupplierValueObject from 'Invoice/SampleProforma/_models/ValueObjects/SupplierValueObject';

class SupplierOrMechandiserEntity {
  private readonly supplierValue: SupplierValueObject | null;
  private readonly merchandiserValue: MerchandiserOfficeValueObject | null;

  constructor(
    supplierValue: SupplierValueObject | null,
    merchandiserValue: MerchandiserOfficeValueObject | null,
  ) {
    if (supplierValue === null && merchandiserValue === null) {
      throw new Error('Supplier or Merchandiser is required');
    }
    if (supplierValue !== null && merchandiserValue !== null) {
      throw new Error('Supplier and Merchandiser cannot be both present');
    }
    this.supplierValue = supplierValue;
    this.merchandiserValue = merchandiserValue;
  }

  public getSuppliervalue(): SupplierValueObject | null {
    return this.supplierValue;
  }

  public getMerchandiserValue(): MerchandiserOfficeValueObject | null {
    return this.merchandiserValue;
  }

  public getObject(): SupplierValueObject | MerchandiserOfficeValueObject | null {
    if (this.supplierValue !== null) {
      return this.supplierValue;
    }
    return this.merchandiserValue;
  }

  public getObjectId(): number | string | null {
    if (this.supplierValue !== null) {
      return this.supplierValue.getMdmId().getValue();
    }
    return this.merchandiserValue?.getId() ?? null;
  }

  public getObjectName(): string | null {
    if (this.supplierValue !== null) {
      return this.supplierValue.getName();
    }
    return this.merchandiserValue?.getName() ?? null;
  }
}

export default SupplierOrMechandiserEntity;
