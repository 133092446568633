import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Timestamp: { input: string; output: string; }
};

export type DeleteInput = {
  releaseId?: InputMaybe<Scalars['String']['input']>;
  secret: Scalars['String']['input'];
};

export type GoReleaseInput = {
  releaseId?: InputMaybe<Scalars['String']['input']>;
  secret: Scalars['String']['input'];
};

export type MinReleaseDate = {
  __typename?: 'MinReleaseDate';
  date?: Maybe<Scalars['Timestamp']['output']>;
};

export type MinReleaseDateInput = {
  date: Scalars['Timestamp']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  add: Release;
  delete: Scalars['String']['output'];
  release: Release;
  unrelease: Release;
  updateMinReleaseDate: MinReleaseDate;
};


export type MutationAddArgs = {
  input: ReleaseInput;
};


export type MutationDeleteArgs = {
  input: DeleteInput;
};


export type MutationReleaseArgs = {
  input: GoReleaseInput;
};


export type MutationUnreleaseArgs = {
  input: UnReleaseInput;
};


export type MutationUpdateMinReleaseDateArgs = {
  input: MinReleaseDateInput;
};

export type Query = {
  __typename?: 'Query';
  minReleaseDate: MinReleaseDate;
  release?: Maybe<Release>;
  releases: Array<Maybe<Release>>;
};


export type QueryReleaseArgs = {
  id: Scalars['String']['input'];
};

export type Release = {
  __typename?: 'Release';
  id: Scalars['String']['output'];
  items: Array<ReleaseItem>;
  name: Scalars['String']['output'];
  releaseDate?: Maybe<Scalars['Timestamp']['output']>;
  releaseIndex: Scalars['Int']['output'];
  released: Scalars['Boolean']['output'];
};

export type ReleaseInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  items: Array<ReleaseItemInput>;
  name: Scalars['String']['input'];
  secret: Scalars['String']['input'];
};

export type ReleaseItem = {
  __typename?: 'ReleaseItem';
  description: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ReleaseItemInput = {
  content: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  minReleaseDate?: Maybe<MinReleaseDate>;
};

export type UnReleaseInput = {
  releaseId?: InputMaybe<Scalars['String']['input']>;
  secret: Scalars['String']['input'];
};

export type MinReleaseDateQueryVariables = Exact<{ [key: string]: never; }>;


export type MinReleaseDateQuery = { __typename?: 'Query', minReleaseDate: { __typename?: 'MinReleaseDate', date?: string | null } };

export type MinReleaseChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MinReleaseChangedSubscription = { __typename?: 'Subscription', minReleaseDate?: { __typename?: 'MinReleaseDate', date?: string | null } | null };

export type GetReleasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReleasesQuery = { __typename?: 'Query', releases: Array<{ __typename?: 'Release', name: string, releaseIndex: number, released: boolean, releaseDate?: string | null, items: Array<{ __typename?: 'ReleaseItem', index: number, title: string, description: string }> } | null> };


export const MinReleaseDateDocument = gql`
    query MinReleaseDate @api(name: releaseTool) {
  minReleaseDate {
    date
  }
}
    `;

/**
 * __useMinReleaseDateQuery__
 *
 * To run a query within a React component, call `useMinReleaseDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinReleaseDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinReleaseDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useMinReleaseDateQuery(baseOptions?: Apollo.QueryHookOptions<MinReleaseDateQuery, MinReleaseDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MinReleaseDateQuery, MinReleaseDateQueryVariables>(MinReleaseDateDocument, options);
      }
export function useMinReleaseDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MinReleaseDateQuery, MinReleaseDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MinReleaseDateQuery, MinReleaseDateQueryVariables>(MinReleaseDateDocument, options);
        }
export function useMinReleaseDateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MinReleaseDateQuery, MinReleaseDateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MinReleaseDateQuery, MinReleaseDateQueryVariables>(MinReleaseDateDocument, options);
        }
export type MinReleaseDateQueryHookResult = ReturnType<typeof useMinReleaseDateQuery>;
export type MinReleaseDateLazyQueryHookResult = ReturnType<typeof useMinReleaseDateLazyQuery>;
export type MinReleaseDateSuspenseQueryHookResult = ReturnType<typeof useMinReleaseDateSuspenseQuery>;
export type MinReleaseDateQueryResult = Apollo.QueryResult<MinReleaseDateQuery, MinReleaseDateQueryVariables>;
export const MinReleaseChangedDocument = gql`
    subscription MinReleaseChanged @api(name: releaseTool) {
  minReleaseDate {
    date
  }
}
    `;

/**
 * __useMinReleaseChangedSubscription__
 *
 * To run a query within a React component, call `useMinReleaseChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMinReleaseChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinReleaseChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMinReleaseChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MinReleaseChangedSubscription, MinReleaseChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MinReleaseChangedSubscription, MinReleaseChangedSubscriptionVariables>(MinReleaseChangedDocument, options);
      }
export type MinReleaseChangedSubscriptionHookResult = ReturnType<typeof useMinReleaseChangedSubscription>;
export type MinReleaseChangedSubscriptionResult = Apollo.SubscriptionResult<MinReleaseChangedSubscription>;
export const GetReleasesDocument = gql`
    query GetReleases @api(name: releaseTool) {
  releases {
    name
    releaseIndex
    items {
      index
      title
      description
    }
    released
    releaseDate
  }
}
    `;

/**
 * __useGetReleasesQuery__
 *
 * To run a query within a React component, call `useGetReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReleasesQuery(baseOptions?: Apollo.QueryHookOptions<GetReleasesQuery, GetReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleasesQuery, GetReleasesQueryVariables>(GetReleasesDocument, options);
      }
export function useGetReleasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleasesQuery, GetReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleasesQuery, GetReleasesQueryVariables>(GetReleasesDocument, options);
        }
export function useGetReleasesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReleasesQuery, GetReleasesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReleasesQuery, GetReleasesQueryVariables>(GetReleasesDocument, options);
        }
export type GetReleasesQueryHookResult = ReturnType<typeof useGetReleasesQuery>;
export type GetReleasesLazyQueryHookResult = ReturnType<typeof useGetReleasesLazyQuery>;
export type GetReleasesSuspenseQueryHookResult = ReturnType<typeof useGetReleasesSuspenseQuery>;
export type GetReleasesQueryResult = Apollo.QueryResult<GetReleasesQuery, GetReleasesQueryVariables>;