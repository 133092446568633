import { useEffect, useRef } from 'react';

import { doSetTableSearch } from '../../state/actions/setSearch';
import { useTableState } from '../../state/useTableState';

const useTableSearchHelper = (search?: {
  onApplySearch: (searchValue: string | undefined) => void;
  value: string | undefined;
}): void => {
  const { state, dispatch } = useTableState();
  const didSetTableSearch = useRef<boolean>(false);

  useEffect(() => {
    // Should only be run once. After initially setting the search, changes will be available via `search.onApplySearch`
    if (!didSetTableSearch.current) {
      if (search && state.searchValue !== search.value) {
        dispatch(doSetTableSearch(search.value));
      }
      didSetTableSearch.current = true;
    }
  }, [dispatch, search, state.searchValue]);

  useEffect(() => {
    if (search && state.searchValue !== search.value) {
      search?.onApplySearch(state.searchValue);
    }
  }, [search, state.searchValue]);
};

export default useTableSearchHelper;
