import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableState } from '../_types_/TableState';

export const CHANGE_TABLE_ROW = 'CHANGE_TABLE_ROW';

export interface ChangeTableRow<T extends TableRecord> {
  type: typeof CHANGE_TABLE_ROW;
  row: T;
}

export const doChangeTableRow = <T extends TableRecord>(row: T): ChangeTableRow<T> => ({
  type: CHANGE_TABLE_ROW,
  row,
});

export const applyChangeTableRow = (
  newState: TableState,
  action: ChangeTableRow<TableRecord>
): TableState => {
  const foundRow = newState.rows.find((r) => {
    return r[newState.primaryKey] === action.row[newState.primaryKey];
  });

  Object.assign(foundRow ?? {}, action.row);

  return newState;
};
