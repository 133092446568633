import React, { PropsWithChildren, ReactElement, useState } from 'react';

import { Button, Popover } from '@mui/material';

type Props = {
  label: string;
};

const DevbarButton = ({ label, children }: PropsWithChildren<Props>): ReactElement => {
  const buttonId = label.replaceAll('', '-');
  const [achorEl, setAnchorEl] = useState<Element | null>(null);
  const isOpen = Boolean(achorEl);
  return (
    <>
      <Button
        aria-describedby={buttonId}
        variant="text"
        onClick={(evt): void => {
          setAnchorEl(evt.currentTarget);
        }}
      >
        <span>{label}</span>
        <span>{!isOpen ? '⇧' : '⇩'}</span>
      </Button>
      <Popover
        id={buttonId}
        open={isOpen}
        anchorEl={achorEl}
        onClose={(): void => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default DevbarButton;
