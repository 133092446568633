import { BaseAction } from './_types_/BaseAction';

export const SUSPENSE_SET_AREA = 'SUSPENSE_SET_AREA';

export interface SuspenseSetArea extends BaseAction {
  area: string;
}
const setArea = (area: string): SuspenseSetArea => {
  return { type: SUSPENSE_SET_AREA, area };
};

export const suspenseActions = {
  setArea,
};
