import { SuspenseSetArea, SUSPENSE_SET_AREA } from 'Store/actions/suspense.actions';

export interface SuspenseState {
  area: string;
}
const initialState: SuspenseState = { area: '' };

export default (state = initialState, action: SuspenseSetArea): SuspenseState => {
  if (action.type === SUSPENSE_SET_AREA) {
    return { ...state, area: action.area };
  }

  return state;
};
