import { Key } from 'react';

import { BaseAction } from './_types_/BaseAction';

export const EXPAND = 'EXPAND';
export const EXPAND_MULTIPLE = 'EXPAND_MULTIPLE';
export const COLLAPSE = 'COLLAPSE';
export const COLLAPSE_ALL = 'COLLAPSE_ALL';
export const COLLAPSE_MULTIPLE = 'COLLAPSE_MULTIPLE';

export interface ExpandTableRowAction extends BaseAction {
  tableId: Key;
  rowId: Key;
}

export interface ExpandMultipleTableRowAction extends BaseAction {
  tableId: Key;
  rowIds: Key[];
}
export interface CollapseMultipleTableRowAction extends BaseAction {
  tableId: Key;
  rowIds: Key[];
}
export interface CollapseAllRowsAction extends BaseAction {
  tableId: Key;
}

export type TableExpandState = ExpandTableRowAction &
  CollapseAllRowsAction &
  ExpandMultipleTableRowAction;

const expandRow = (tableId: Key, rowId: Key): ExpandTableRowAction => {
  return {
    type: EXPAND,
    tableId,
    rowId,
  };
};

const expandMultipleRows = (tableId: Key, rowIds: Key[]): ExpandMultipleTableRowAction => {
  return {
    type: EXPAND_MULTIPLE,
    tableId,
    rowIds,
  };
};

const collapseMultipleRows = (tableId: Key, rowIds: Key[]): CollapseMultipleTableRowAction => {
  return {
    type: COLLAPSE_MULTIPLE,
    tableId,
    rowIds,
  };
};

const collapseRow = (tableId: Key, rowId: Key): ExpandTableRowAction => {
  return {
    type: COLLAPSE,
    tableId,
    rowId,
  };
};

const collapseAllRows = (tableId: Key): CollapseAllRowsAction => {
  return {
    type: COLLAPSE_ALL,
    tableId,
  };
};

export type TableAction = ExpandTableRowAction;

export const tableActions = {
  expandRow,
  expandMultipleRows,
  collapseRow,
  collapseAllRows,
  collapseMultipleRows,
};
