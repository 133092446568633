import { Key } from 'react';

import objectHash from 'object-hash';
import { v4 as uuid } from 'uuid';

import {
  LineInfo,
  LineInfoGroup,
} from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/LineInfo';


export const calculateGroupId = (
  line: LineInfo | LineInfoGroup
): { groupId: string; rebalanced: boolean } => {
  return calculateGroupIdByProperties({
    orderNumber: (line as LineInfo)?.order?.number ?? (line as LineInfoGroup)?.orderNumber,
    styleNumber: line.style?.number,
    composition: line.composition?.qualityCompositions.join(','),
    gender: line.gender?.id,
    hsCode: line.hsCode.value,
    manufacturer: line.manufacturer?.id,
    masterCategory: line.masterCategory?.id,
    sampleType: line.sampleType?.id,
    styleType: line.styleType?.id,
  });
};

export const calculateGroupIdByProperties = (line: {
  orderNumber: Key | undefined;
  styleNumber: Key | undefined;
  sampleType: Key | undefined;
  gender: Key | undefined;
  manufacturer: Key | undefined;
  masterCategory: Key | undefined;
  styleType: Key | undefined;
  composition: Key | undefined;
  hsCode: Key | undefined;
}): { groupId: string; rebalanced: boolean } => {
  if (
    line.sampleType === undefined ||
    line.gender === undefined ||
    line.manufacturer === undefined ||
    line.masterCategory === undefined ||
    line.styleType === undefined ||
    line.composition === undefined ||
    line.hsCode === undefined
  ) {
    return { groupId: uuid(), rebalanced: false };
  }
  return {
    groupId: objectHash({
      orderNumber: line.orderNumber,
      styleNumber: line.styleNumber,
      sampleType: line.sampleType,
      gender: line.gender,
      manufacturer: line.manufacturer,
      masterCategory: line.masterCategory,
      styleType: line.styleType,
      composition: line.composition,
      hsCode: line.hsCode,
    }),
    rebalanced: true,
  };
};
