import { Key } from 'react';

import { TableState } from '../_types_/TableState';

export const SELECT_TABLE_ROW = 'SELECT_TABLE_ROW';

export interface SelectTableRow {
  type: typeof SELECT_TABLE_ROW;
  selectedPrimaryKey: string;
}

export const doSelectTableRow = (selectedPrimaryKey: Key): SelectTableRow => ({
  type: SELECT_TABLE_ROW,
  selectedPrimaryKey: String(selectedPrimaryKey),
});

export const applySelectTableRow = (newState: TableState, action: SelectTableRow): TableState => {
  if (newState.selectedRows[action.selectedPrimaryKey]) {
    delete newState.selectedRows[action.selectedPrimaryKey];
  } else {
    const tableRecord = newState.rows.find(
      (r) => String(r[newState.primaryKey]) === action.selectedPrimaryKey
    );
    if (tableRecord) {
      newState.selectedRows[action.selectedPrimaryKey] = tableRecord;
    }
  }

  return newState;
};
