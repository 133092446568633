import { ReactElement, PropsWithChildren, useMemo } from 'react';
import { Link as NavLink } from 'react-router-dom';

import { MenuOpen } from '@mui/icons-material';
import {
  IconButton,
  Drawer,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
  ListItemButton,
} from '@mui/material';
import { Box } from '@mui/system';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useIsMobile } from '@bestseller-bit/frontend-community.utilities.is-mobile';
import { useBreadCrumbs } from 'Components/Hooks/useBreadCrumbs';
import navClasses from 'Components/UI/Navigation/Navigation.module.scss';
import { DRAWER_WIDTH } from 'Components/UI/Navigation/NavigationBar/styles';
import { ButtonWithNavigationMenu } from 'Components/UI/Navigation/NavigationMenu/ButtonWithNavigationMenu';
import { useRoutes } from 'Views/routes';

import { useHasPermission } from '../../../../Models/Permission/permissionHelper';

const NavigationDrawer = ({
  handleDrawerClose,
  open,
}: PropsWithChildren<{
  handleDrawerClose: () => void;
  open: boolean;
}>): ReactElement => {
  const routes = useRoutes();
  const hasPermission = useHasPermission();
  const breadCrumbs = useBreadCrumbs();
  const flags = useFlags();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const openInNewTab = (to: string): void => {
    const newWindow = window.open(to, '_blank');
    newWindow?.focus();
  };

  const permanent = useMemo(() => !isMobile, [isMobile]);

  const drawerStyles = {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    overflowX: open ? 'visible' : 'hidden',
    width: open ? DRAWER_WIDTH : theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: open ? DRAWER_WIDTH : theme.spacing(9),
    },
  };

  const paperStyles = {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    overflowX: open ? 'visible' : 'hidden',
    width: open ? DRAWER_WIDTH : theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: open ? DRAWER_WIDTH : theme.spacing(9),
    },
  };

  return (
    <Drawer
      open={open}
      sx={drawerStyles}
      PaperProps={{ sx: paperStyles }}
      variant={permanent ? 'permanent' : 'temporary'}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
        }}
      >
        <IconButton onClick={handleDrawerClose} size="large">
          <MenuOpen />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {Object.keys(routes).map((routeId, index) => {
          const route = routes[routeId];
          const isSelected = breadCrumbs.findIndex((r) => r.label === route.label) > -1;

          if (
            !hasPermission(route.permission) ||
            route.display === false ||
            (route.featureFlag && !flags[route.featureFlag as string])
          ) {
            return null;
          }

          if (
            route.children &&
            Object.keys(route.children ?? {}).some(
              (r) =>
                route.children?.[r].display !== false &&
                !!route.children?.[r].to &&
                (route.children?.[r].featureFlag
                  ? !!flags[route.children?.[r].featureFlag as string]
                  : true),
            )
          ) {
            return (
              <ButtonWithNavigationMenu
                key={`listButtonWithNav-${routeId}-${index}`}
                routeId={routeId}
                index={index}
                icon={route.icon}
                to={route.to}
                open={open}
                color={route.color}
                selected={isSelected}
              />
            );
          }

          const Icon = route.icon;
          return (
            <ListItemButton
              key={route.label}
              selected={isSelected}
              className={navClasses.listItem}
              {...(route.openInNewTab
                ? { onClick: () => openInNewTab(route.to) }
                : { component: NavLink, to: route.to })}
            >
              {route.icon && (
                <Tooltip title={route.label}>
                  <ListItemIcon
                    style={isSelected ? { color: route.color } : {}}
                    className={navClasses.listItemIcon}
                  >
                    {Icon}
                  </ListItemIcon>
                </Tooltip>
              )}
              {open && <ListItemText primary={route.label} />}
            </ListItemButton>
          );
        })}
      </List>
      <Box sx={{ flexGrow: 1 }} />
    </Drawer>
  );
};

export default NavigationDrawer;
