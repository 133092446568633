import { useEffect } from 'react';

import _ from 'lodash';

import { useTableState } from 'Components/Table/state/useTableState';


import { PageableProps } from '../../../Shared/Table/_types_/TableMeta';
import { doSetTablePagination } from '../../state/actions/setTablePagination';

const usePaginationHelper = (
  pagination?: PageableProps & {
    onPageChange: (page: number) => void;
    onPageSizeChange: (pagesize: number) => void;
  }
): void => {
  const { state, dispatch } = useTableState();
  useEffect(() => {
    if (pagination) {
      const { onPageChange, onPageSizeChange, ...pageable } = pagination;
      if (!_.isEqual(pageable, state.pagination)) {
        dispatch(doSetTablePagination(pageable));
      }
    }
  }, [dispatch, pagination, state.pagination]);

  useEffect(() => {
    if (
      pagination &&
      state.pagination &&
      !isNaN(+state.pagination.currentPage) &&
      +state.pagination.currentPage !== +pagination.currentPage
    ) {
      pagination?.onPageChange(+state.pagination.currentPage);
    }
  }, [pagination, state.isLoading, state.pagination]);

  useEffect(() => {
    if (
      pagination &&
      state.pagination &&
      !isNaN(+state.pagination.pageSize) &&
      +state.pagination.pageSize !== +pagination.pageSize
    ) {
      pagination?.onPageSizeChange(+state.pagination?.pageSize);
    }
  }, [pagination, state.isLoading, state.pagination]);
};

export default usePaginationHelper;
