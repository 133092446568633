import { ReactElement, useMemo } from 'react';

import SearchableDropdown, {
  SearchableDropdownElement,
} from 'Components/Shared/SearchableDropdown/SearchableDropdown';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableColumn } from '../../state/_types_/TableColumn';
import { SearchableSelectFilter } from '../../state/_types_/TableFilters';
import { doChangeFilter } from '../../state/actions/changeFilter';
import { useTableState } from '../../state/useTableState';
interface Props<T extends TableRecord> {
  filter: SearchableSelectFilter;
  column: TableColumn<T>;
  height?: number;
}

const TableSearchableSelectFilter = <T extends TableRecord>({
  filter: {
    searchCallback,
    searchTerm,
    loading,

    elements = [
      {
        title: 'No items available',
        value: -1,
      },
    ],
  },
  column,
}: Props<T>): ReactElement => {
  const { state, dispatch } = useTableState<T>();

  const handleClick = (values: SearchableDropdownElement[] | null) => {
    dispatch(
      doChangeFilter({
        property: column.property,
        filterType: 'searchableSelect',
        value: values ? values.map((x) => x.value.toString()) : [],
      })
    );
  };

  const currentFilter = useMemo(
    () => [state.filters[column.property]?.value].flat() as (string | number)[],
    [column.property, state.filters]
  );

  return (
    <SearchableDropdown<true, false, false>
      searchCallback={
        searchCallback !== undefined
          ? { callback: searchCallback, searchTerm: searchTerm }
          : undefined
      }
      disableCloseOnSelect
      elements={elements.map((x) => ({ name: x.title, value: x.value }))}
      loading={loading !== undefined ? loading : false}
      value={elements
        .filter(
          (e) =>
            (currentFilter ?? []).findIndex((c) => {
              return c === e.value;
            }) > -1
        )
        .map((x) => ({ name: x.title, value: x.value }))}
      onChange={(elem) => {
        handleClick(elem);
      }}
      variant="outlined"
      multiple
      width="100%"
    />
  );
};

export default TableSearchableSelectFilter;
