import { dirtybitConstants } from 'Constants/dirtybit.constants';
import { DirtyBitAction, DirtyBitType } from 'Store/actions/_types_/DirtyBitAction';

import { DirtyBitState } from './_types_/DirtyBitState';

const initialState: DirtyBitState = { dirtyElements: [] };

export function dirtybit(state = initialState, action: DirtyBitAction): DirtyBitState {
  switch (action.type) {
    case dirtybitConstants.SET: {
      const elemIndex = state.dirtyElements
        .map(function (e: DirtyBitType): string {
          return e.target;
        })
        .indexOf(action.elem.target);
      if (action.elem.isDirty && elemIndex === -1) {
        return {
          ...state,
          dirtyElements: [...state.dirtyElements, action.elem],
        };
      } else if (!action.elem.isDirty && elemIndex !== -1) {
        const newElements = [...state.dirtyElements];
        newElements.splice(elemIndex, 1);
        return {
          ...state,
          dirtyElements: newElements,
        };
      }
      return state;
    }
    case dirtybitConstants.CLEAR: {
      return { ...state, dirtyElements: [] };
    }
    default:
      return state;
  }
}
