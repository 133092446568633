import { BaseAction } from './_types_/BaseAction';

export const TOGGLE_DISABLE_ALL_FF = 'TOGGLE_DISABLE_ALL_FF';
export const SET_FAKE_EXT_ERROR = 'SET_FAKE_EXT_ERROR';

export interface ToggleDisableAllFf extends BaseAction {
  value: boolean;
  storeInSession: boolean;
}

export interface SetFakeExtError extends BaseAction {
  full: boolean | undefined;
  partial: boolean | undefined;
}

const toggleDisableAllFf = (value: boolean, storeInSession: boolean): ToggleDisableAllFf => {
  if (storeInSession) {
    if (!value) {
      sessionStorage.removeItem('disableAllFF');
    } else {
      sessionStorage.setItem('disableAllFF', 'true');
    }
  }
  return { type: TOGGLE_DISABLE_ALL_FF, value, storeInSession: storeInSession };
};

const setFakeExtError = (
  full: boolean | undefined,
  partial: boolean | undefined
): SetFakeExtError => {
  return { type: SET_FAKE_EXT_ERROR, full, partial };
};

export const devSettingsActions = {
  toggleDisableAllFf,
  setFakeExtError,
};
