import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button, Link, Paper, TextField, Typography } from '@mui/material';

import { LOGIN_GUIDE_URL } from 'Constants/shared.constants';
import { useCheckProfileCredentials } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useCheckProfileCredentials';
import AlreadyInvited from 'UserAuthentication/Login/LoginScreens/AlreadyInvited';
import EmailForm from 'UserAuthentication/Login/LoginScreens/EmailForm';

type Props = {
  goToWelcome: () => void;
};

const CredentialsForm = (props: Props): ReactElement => {
  const [username, setUsername] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [token, setToken] = useState<string | undefined>();
  const [goToEmailForm, setGoToEmailForm] = useState<boolean>(false);
  const [goToAlreadyInvited, setGoToAlreadyInvited] = useState<boolean>(false);
  const [inviteStatus, setInviteStatus] = useState<string | undefined>();
  const [adEmail, setAdEmail] = useState<string | undefined>();

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const beBranch = useMemo(() => queryParams.get('beBranch') ?? undefined, [queryParams]);

  const [checkCredentials, { loading, error, data }] = useCheckProfileCredentials(
    username ?? '',
    password ?? '',
    beBranch,
  );

  const handleOpenLoginGuide = useCallback(() => {
    const newWindow = window.open(LOGIN_GUIDE_URL, '_blank');
    newWindow?.focus();
  }, []);

  const handleChangeUsername = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setUsername(undefined);
      return;
    }
    setUsername(e.target.value);
  }, []);

  const handleChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setPassword(undefined);
      return;
    }
    setPassword(e.target.value);
  }, []);

  const handleCheckCredentials = useCallback(() => {
    if (!username || !password) {
      setErrorMessage('Please fill in both fields');
      return;
    }

    checkCredentials();
  }, [checkCredentials, password, username]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      setErrorMessage('Invalid credentials');
      return;
    }
    if (data) {
      setToken(data.access_token);
      setErrorMessage('');
      if (data.adInfo?.hasADLogin) {
        setAdEmail(data.email);
        setInviteStatus(data.invite_status);
        setGoToAlreadyInvited(true);
        return;
      }
      setGoToEmailForm(true);
    }
  }, [data, error, loading]);

  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') {
        return;
      }
      handleCheckCredentials();
    },
    [handleCheckCredentials],
  );

  const handleGoBack = useCallback(() => {
    props.goToWelcome();
  }, [props]);

  if (goToAlreadyInvited && adEmail && token) {
    return (
      <AlreadyInvited
        invitedStatus={inviteStatus}
        email={adEmail}
        goToWelcome={props.goToWelcome}
        token={token}
      />
    );
  }

  if (goToEmailForm && token) {
    return <EmailForm token={token} goToWelcome={props.goToWelcome} />;
  }

  return (
    <Paper elevation={0} sx={{ height: '300px', width: '700px', padding: 5 }}>
      <Box>
        <Typography fontSize={28} fontWeight={700} mb={1}>
          Which account would you like to use?
        </Typography>
        <Box display="flex" flexDirection="column" rowGap={1}>
          <TextField label="Username" value={username ?? ''} onChange={handleChangeUsername} />
          <TextField
            label="Password"
            type="password"
            value={password ?? ''}
            onChange={handleChangePassword}
            onKeyDown={handleEnterPress}
          />
        </Box>
        <Box py={1}>
          <Typography color="error" variant="body2">
            {errorMessage}
          </Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Box>
            <Link sx={{ cursor: 'pointer' }} onClick={handleOpenLoginGuide}>
              Login Guide
            </Link>
          </Box>
          <Box>
            <Button
              disableElevation
              variant="contained"
              onClick={handleCheckCredentials}
              disabled={!username || !password}
            >
              Use this account
            </Button>
            <Button disableElevation variant="outlined" sx={{ ml: 1 }} onClick={handleGoBack}>
              Go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default CredentialsForm;
