import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { SuspenseState } from 'Store/reducers/suspense.reducer';

import classes from './Splash.module.scss';

const DisplayArea = (): ReactElement => {
  const suspenseState = useSelector((root: RootReducerState): SuspenseState => root.suspenseState);
  const [area, setArea] = useState('');
  useEffect((): void => {
    setArea(suspenseState.area);
  }, [suspenseState]);
  return (
    <div className={classes.text}>
      <span>Loading {area.toLocaleLowerCase()}...</span>
    </div>
  );
};

export default DisplayArea;
