let ssoApplicationID;

const hostname = window && window.location && window.location.hostname;

const backendHost = window && window.env && window.env.backendHost;

const releaseToolGQLHost = 'https://vp-release-tool-server.bestseller.com';
const papskiltGQLHost = 'https://papskilt.bestseller.tech';

export const showBranch =
  hostname === 'ambassador.bsoneproduct02-dev.k8s.bestcorp.net' ||
  hostname === 'vendorportal-dev.bestseller.com';

export const isLocalEnv = hostname === 'localhost';

export const isDevEnv =
  showBranch ||
  isLocalEnv ||
  hostname === 'vendorportal-test.bestseller.com' ||
  hostname === 'ambassador.bsoneproduct02-test.k8s.bestcorp.net';

const stylesGQLHost = (props: { stylesBranch?: string }): string => {
  return `${backendHost}${
    props.stylesBranch !== undefined && props.stylesBranch.length > 0
      ? `${props.stylesBranch}/`
      : ''
  }styles-bff`;
};

switch (hostname) {
  case 'localhost':
  case 'vendorportal-dev.bestseller.com':
  case 'ambassador.bsoneproduct02-dev.k8s.bestcorp.net': {
    ssoApplicationID = '8267e5c3-ce8c-405f-bfb8-3e4c2efbaeae';
    break;
  }

  case 'vendorportal-test.bestseller.com': {
    ssoApplicationID = '907455a6-f0ca-4566-8a6f-bb8a5764e685';
    break;
  }
  default:
    ssoApplicationID = '0093ace3-2963-46a0-ab5e-4cbe73f4344f';
}

export const DD_ENV = isDevEnv ? 'dev' : 'prod';

export const REACT_APP_DEPLOY_VERSION = window && window.env && window.env.REACT_APP_DEPLOY_VERSION;
export const PUBLIC_URL = window && window.env && window.env.PUBLIC_URL;

export const properties = {
  hostname,
  qglHosts: {
    releaseToolGQLHost,
    papskiltGQLHost,
    stylesGQLHost,
  },
  loginApiURL: backendHost,
  serviceApiURL: backendHost,
  ssoApplicationID,
  ldclientSideID: '5d5bc1057a261808778304eb',
  REACT_APP_DEPLOY_VERSION,
  PUBLIC_URL,
};

export const basicAuthToken = (): string =>
  btoa('bff-clientid:220a6db6-5db2-4383-9a50-1d25151e1243');

export const clientSecret = '220a6db6-5db2-4383-9a50-1d25151e1243';
