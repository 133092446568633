import { ReactElement } from 'react';

import { Clear } from '@mui/icons-material';

import classes from './CloseDialogButton.module.scss';

interface PropType {
  onClose: () => void;
}

const CloseDialogButton = (props: PropType): ReactElement<PropType> => {
  return (
    <div className={classes.closeIcon}>
      <Clear onClick={(): void => props.onClose()} />
    </div>
  );
};

export default CloseDialogButton;
