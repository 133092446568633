import { lazy, ComponentType, LazyExoticComponent, PropsWithChildren } from 'react';

import { datadogRum } from '@datadog/browser-rum';

const componentLoader = <T>(lazyComponent: () => Promise<T>, attemptsLeft: number): Promise<T> => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
  );
  window.localStorage.setItem('page-has-been-force-refreshed', 'false');

  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        datadogRum.addAction('componentLoader', {
          pageHasAlreadyBeenForceRefreshed: pageHasAlreadyBeenForceRefreshed,
          attemptsLeft: attemptsLeft,
        });
        if (!pageHasAlreadyBeenForceRefreshed) {
          // Assuming that the user is not on the latest version of the application.
          // Let's refresh the page immediately.
          window.localStorage.setItem('page-has-been-force-refreshed', 'true');
          datadogRum.addAction(`Reloading faied too many times: Force Reload`, undefined);
          return window.location.reload();
        }

        // let us retry after x ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            datadogRum.addError(`componentLoader: Tried too many times`, {
              error: { ...error, source: 'network' },
            });
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500 - 150 * attemptsLeft); //increase wait time for each fail
      });
  });
};

export default <T extends ComponentType<PropsWithChildren<Record<string, unknown>>>>(
  factory: () => Promise<{ default: T }>,
  retires = 10
): LazyExoticComponent<T> => {
  return lazy<T>(() => componentLoader(factory, retires).then((component) => component));
};
