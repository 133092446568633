import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ListAltIcon from '@mui/icons-material/ListAlt';
import { Chip, Paper } from '@mui/material';

import { properties } from 'Helpers';

import useLocalStorage from 'Components/Hooks/useLocalStorage';
import SupportLink from 'Components/UI/HeaderPage/SupportLink';
import { UserInfo } from 'Components/UI/HeaderPage/UserInfo/UserInfo';
import NavigationBar from 'Components/UI/Navigation/NavigationBar/index';
import NavigationDrawer from 'Components/UI/Navigation/NavigationDrawer/index';
import { GUIDE, GUIDE_URL } from 'Constants/shared.constants';
import { isDevEnv } from 'Helpers/properties';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

import classes from './Navigation.module.scss';

const Actions = (): ReactElement => {
  const user = useSelector((root: RootReducerState): UserReducerType => root.userReducer);

  const returnedActions: (ReactElement | undefined)[] = [<UserInfo key="user-info-chip" />];

  if (isDevEnv) {
    returnedActions.push(
      <Chip
        key="deploy-version-chip"
        className={[classes.chip, classes.deployVersionChip].join(' ')}
        label={properties.REACT_APP_DEPLOY_VERSION}
      />,
    );
  }

  const handleOpenGuide = () => {
    const newWindow = window.open(GUIDE_URL, '_blank');
    newWindow?.focus();
  };

  if (user !== null) {
    returnedActions.push(
      <div key="user-actions-chip" className={classes.userActionsChip}>
        <Paper sx={{ display: { xs: 'none', md: 'flex' }, backgroundColor: 'transparent' }}>
          {user.user && (
            <Chip
              className={classes.chip}
              icon={<ListAltIcon fontSize="small" />}
              label={GUIDE}
              onClick={handleOpenGuide}
            />
          )}
          <SupportLink isLoggedIn={!!user.user} />
        </Paper>
      </div>,
    );
  }
  return <div className={classes.actions}>{[...returnedActions].reverse()}</div>;
};

const Navigation = (): ReactElement => {
  const [open, setOpen] = useLocalStorage(true, 'navigationBarOpen');
  const { authState } = useAuthState();

  const location = useLocation();
  const isAdScreen = useMemo(
    () => location.pathname.includes('adlogin') === true,
    [location.pathname],
  );

  const handleDrawerOpen = (): void => setOpen(true);
  const handleDrawerClose = (): void => setOpen(false);

  return (
    <>
      <NavigationBar
        Actions={Actions}
        {...(!authState.tokens || isAdScreen
          ? {
              noSidebar: true,
            }
          : {
              open,
              handleDrawerOpen,
            })}
      />
      {authState.tokens && !isAdScreen && (
        <NavigationDrawer open={open} handleDrawerClose={handleDrawerClose} />
      )}
    </>
  );
};

export default Navigation;
