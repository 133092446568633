import { ReactElement, useState } from 'react';

import { Chip } from '@mui/material';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import navClasses from 'Components/UI/Navigation/Navigation.module.scss';
import SupportModal from 'Components/UI/SupportModal/SupportModal';
import SupportModalIndex from 'Components/UI/SupportModal/SupportModalIndex';


type Props = {
  isLoggedIn: boolean;
};
const SupportLink = ({ isLoggedIn }: Props): ReactElement => {
  const [modalOpen, setModelOpen] = useState<boolean>(false);

  const handleClick = (): void => setModelOpen(true);
  const handleClose = (): void => setModelOpen(false);

  return (
    <>
      <Chip
        icon={getIconByIconName('support')}
        label="Support"
        onClick={handleClick}
        className={navClasses.chip}
      />
      {modalOpen &&
        (isLoggedIn ? (
          <SupportModal handleClose={handleClose} />
        ) : (
          <SupportModalIndex handleClose={handleClose} />
        ))}
    </>
  );
};

export default SupportLink;
