import React from 'react';

import { AuthHandlerDispatch } from 'Hooks/useAuthHandler/state/_types/AuthHandlerDisptach';
import { authState } from 'Hooks/useAuthHandler/state/_types/AuthState';
import { AuthHandlerContext } from 'Hooks/useAuthHandler/state/authHandlerContext';

export const useAuthState = (): {
  authState: authState;
  authDispatch: AuthHandlerDispatch;
} => {
  const context = React.useContext(AuthHandlerContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthHandlerContextProvider');
  }
  return { authState: context.state, authDispatch: context.dispatch };
};
