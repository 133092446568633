import { useContext } from 'react';

import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableState } from './_types_/TableState';
import { TableStateDispatch } from './_types_/TableStateDispatch';
import { TableStateContext } from './TableStateContext';

type UseTableState<T extends TableRecord> = {
  state: TableState<T>;
  dispatch: TableStateDispatch<T>;
};

export const useTableState = <T extends TableRecord>(): UseTableState<T> => {
  const context = useContext(TableStateContext);
  if (context === undefined) {
    throw new Error('useTableState must be used within a TableStateProvider');
  }
  return context as UseTableState<T>;
};
