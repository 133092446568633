import { ReactNode } from 'react';

import { SET_ERROR, CLEAR_ERROR, SET_INFO, SET_WARNING } from 'Constants/error.constants';
import { ToastAction } from 'Store/actions/toast.actions';

export type toastType = 'Info' | 'Warning' | 'Error';

export interface ToastState {
  type?: toastType;
  message?: ReactNode;
  sticky?: boolean;
}

const initialState: ToastState = {
  type: undefined,
  message: undefined,
  sticky: undefined,
};

export default (state = initialState, action: ToastAction): ToastState => {
  switch (action.type) {
    case SET_INFO: {
      return {
        ...state,
        message: action.message,
        type: 'Info',
        sticky: action.sticky,
      };
    }
    case SET_WARNING: {
      return {
        ...state,
        message: action.message,
        type: 'Warning',
        sticky: action.sticky,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        message: action.message,
        type: 'Error',
        sticky: action.sticky,
      };
    }
    case CLEAR_ERROR: {
      return { ...state, message: undefined };
    }
    default:
      return state;
  }
};
