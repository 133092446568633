import { forwardRef, Key, ReactElement, Ref } from 'react';

import { PageableProps } from 'Components/Shared/Table/_types_/TableMeta';
import { SelectedAction } from 'Components/Table/state/_types_/SelectedTableActions';
import { TableFilters } from 'Components/Table/state/_types_/TableFilters';
import { TableStateDispatch } from 'Components/Table/state/_types_/TableStateDispatch';

import { NestedKeyOf } from '../../_types_/NestedKeyOf';

import { TableColumn } from './state/_types_/TableColumn';
import { TableOptions } from './state/_types_/TableOptions';
import { TableRecord } from './state/_types_/TableRecord';
import { TableSortOptions } from './state/_types_/TableSortOptions';
import { TableStateContext } from './state/TableStateContext';
import { useTableStateReducer } from './state/useTableStateReducer';
import ExportedTable from './Table';

export interface TableProps<T extends TableRecord> {
  title?: string;
  tableName: string;
  primaryKey: NestedKeyOf<T>;
  rows: T[];
  columns: TableColumn<T>[];
  options?: Partial<TableOptions>;
  standardActions?: ReactElement[];
  selectedActions?: SelectedAction<T>[];
  selectedElementIds?: Key[];
  hyperControlledSelectedRows?: boolean;
  onSelectionChange?: (selectedId: string | number, checked: boolean) => void;
  pagination?: PageableProps & {
    onPageChange: (page: number) => void;
    onPageSizeChange: (pagesize: number) => void;
  };
  sort?: TableSortOptions[];
  loading?: boolean;
  onShouldRefreshData?: () => void;
  onApplyFilters?: (filters: TableFilters<T>) => void;
  search?: {
    onApplySearch: (searchValue: string | undefined) => void;
    value: string | undefined;
  };
  defaultSearchLabel?: string;
  defaultSearch?: string;
  error?: Error;
  customStyling?: {
    customRowClassName?: (row: T, isCollapsed: boolean) => string | undefined;
  };
  ExpandedRowContent?: (props: { row: T; dispatch: TableStateDispatch<T> }) => ReactElement;
  scrollToEnd?: boolean;
}

/**
 * Hacks it so an interface can actually be indexed.
 * Otherwise TS will complain
 */
export type IndexSignatureHack<T> = {
  [K in keyof T]: IndexSignatureHack<T[K]>;
};

export interface ExpandTableRef {
  expand: (id: string) => void;
}

const Table = <T extends TableRecord>(
  props: TableProps<T>,
  ref: Ref<ExpandTableRef>,
): ReactElement => {
  const [state, dispatch] = useTableStateReducer();

  return (
    <TableStateContext.Provider value={{ state, dispatch }}>
      <ExportedTable<T> {...props} ref={ref} />
    </TableStateContext.Provider>
  );
};
const TableWithRef = forwardRef(Table) as <T extends TableRecord>(
  p: TableProps<T> & { ref?: Ref<ExpandTableRef> },
) => ReactElement;

export default TableWithRef;
