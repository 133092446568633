import { SearchValue } from 'Components/Shared/SearchBar/_types';
import { SearchChipProps } from 'Components/Shared/SearchBar/SearchChip/SearchChip';

const getSearchValue = (search: string): string => search.replace(/^.*:/i, '');
const tagMatch = (searchValue: string): string | undefined =>
  searchValue.toLowerCase().match(/^.*?:/g)?.[0];

const getMatch = (searchValue: string | undefined): string | undefined => {
  if (searchValue === undefined) {
    return;
  }

  const regexMatch = RegExp(/^.*:/i, 'g');
  const match = searchValue.match(regexMatch)?.[0];
  return match?.replace(':', '');
};

const generateLabel = (label: string | undefined, value: string): string => {
  const parsedLabel = label ? `${label}: ` : '';
  return `${parsedLabel}${value.trim()}`;
};

const addChipElement = <S extends string>(
  array: Partial<SearchChipProps<S>>[],
  chipType: S | undefined,
  label: string | undefined,
  value: string
): Partial<SearchChipProps<S>>[] => {
  const newChipArray = [...array.filter((chip) => chip.type !== chipType)];
  newChipArray.push({
    label: generateLabel(label, value),
    type: chipType,
  });
  return newChipArray;
};

const getLabel = <S extends string>(
  label: string | undefined,
  chipType: S | undefined,
  defaultSearchField: SearchValue & {
    searchField?: S;
  }
): string | undefined => label ?? chipType ?? defaultSearchField.label;

export { getSearchValue, tagMatch, getMatch, generateLabel, addChipElement, getLabel };
