import { useSelector } from 'react-redux';

import { guaranteeArray } from 'Helpers/util';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

import { Permission } from './_types_/Permission';

export const useHasPermission = (): ((permissionIds: number | number[] | undefined) => boolean) => {
  const user = useSelector((root: RootReducerState) => root.userReducer.user);
  return (permissionIds: number | number[] | undefined) => {
    const permissionIdAsArray = guaranteeArray(permissionIds);
    let hasAccess = false;
    permissionIdAsArray.forEach((permissionId: number | undefined) => {
      if (permissionId === undefined || hasAccess) {
        hasAccess = true;
        return;
      }
      const foundPermissions = (user?.permissions ?? []).filter(
        (permission: Permission): boolean => permission.permissionsId === permissionId
      );
      hasAccess = foundPermissions && foundPermissions.length > 0;
    });

    return hasAccess;
  };
};
