interface Props {
  value: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}
export default (props: Props): string => {
  const { value, maximumFractionDigits, minimumFractionDigits } = props;
  return new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: minimumFractionDigits ?? 2,
    maximumFractionDigits: maximumFractionDigits ?? 2,
  }).format(value);
};
