import { Suspense, useMemo } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import {
  PACKING_NOTE_TO,
  PACKING_NOTE_DETAIL_TO,
  CREATE_PACKING_NOTE_TO,
} from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import NewsList from 'Views/News/NewsList';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { RouteConfig } from '.';

const PackingNotesDetails = lazyImport(() => import('PackingNotes/Areas/PackingNotesDetails'));

const CreatePackingNote = lazyImport(
  () => import('PackingNotes/Actions/CreatePackingNote/CreatePackingNotes/index'),
);

const PackingNotesOverview = lazyImport(() => import('PackingNotes'));

export const usePACKING_NOTE_ROUTES = (): RouteConfig => {
  return useMemo(
    () => ({
      label: 'Packing Notes',
      to: PACKING_NOTE_TO,
      icon: getIconByIconName('packingNote'),
      color: '#2b4f62',
      component: (
        <Suspense fallback={<LazyLoadingMessage view="Packing notes" />}>
          <PackingNotesOverview />
        </Suspense>
      ),

      permission: PERMISSION.VP_PACKING_NOTE_VIEW,
      children: {
        Detail: {
          label: 'Packing note detail for :ids',
          display: false,
          to: `${PACKING_NOTE_DETAIL_TO}/:ids`,
          permission: PERMISSION.VP_PACKING_NOTE_VIEW,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Packing note details" />}>
              <PackingNotesDetails />
            </Suspense>
          ),
        },

        CreatePackingNote: {
          label: 'Create Packing Note for :orderNumber',
          display: false,
          to: `${CREATE_PACKING_NOTE_TO}/:orderNumber`,
          permission: PERMISSION.VP_PACKING_NOTE_CREATE,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Create packing note" />}>
              <CreatePackingNote />
            </Suspense>
          ),
        },
        PackingNote: {
          label: ':orderNumber',
          display: false,
          to: `${PACKING_NOTE_TO}/:orderNumber`,
          component: (
            <Suspense fallback={<LazyLoadingMessage view="Packing note for order" />}>
              <CreatePackingNote />
            </Suspense>
          ),
          permission: PERMISSION.VP_PACKING_NOTE_VIEW,
          fallbackComponent: NewsList,
        },
      },
    }),
    [],
  );
};
