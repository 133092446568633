import { useRef, useEffect, useState } from 'react';

import { UseBestApiTupleConfigs } from 'Components/Hooks/_types_/UseBestApiTupleConfigs';
import { useBaseApiQuery } from 'Components/Hooks/useBaseApiQuery';

import { UseBestApiTuple } from './_types_';

export * from './_types_';

/**
 * A hook that provides a mapped API response using the provided mapper function.
 *
 * @template Response - The type of the original API response.
 * @template MappedResponse - The type of the mapped response.
 * @template Config - The type of the configuration object, extending `UseBestApiTupleConfigs`.
 * @template ErrorType - The type of the error, defaults to `Error`.
 *
 * @param {string} apiEndpoint - The API endpoint to fetch data from.
 * @param {Config} config - The configuration object for the API request.
 * @param {(response: Response | undefined) => MappedResponse | undefined} [mapper] - A function to map the original API response to the desired format.
 * @param {{ data: MappedResponse | (() => MappedResponse); delay?: number }} [fakeData] - Optional fake data to be used instead of making an actual API request. Can be a value or a function returning the value. Optionally, a delay can be specified.
 *
 * @returns {UseBestApiTuple<MappedResponse, Config, ErrorType>} - A tuple containing the mapped response, loading state, and error state.
 */
export const useMappedBestApi = <
  Response,
  MappedResponse,
  Config extends UseBestApiTupleConfigs,
  ErrorType = Error
>(
  apiEndpoint: string,
  config: Config,
  mapper?: (response: Response | undefined) => MappedResponse | undefined,
  fakeData?: { data: MappedResponse | (() => MappedResponse); delay?: number }
): UseBestApiTuple<MappedResponse, Config, ErrorType> => {
  return useBestApi<Response, Config, ErrorType, MappedResponse>(
    apiEndpoint,
    config,
    fakeData,
    mapper
  );
};

/**
 * @param apiEndpoint - the endpoint to be called
 * @param {boolean} config - One of the configs from UseBestApiTupleConfigs
 * @param {Response} fakeData - return this fake data
 * @returns UseBestApiTuple
 */
export const useBestApi = <
  Response,
  Config extends UseBestApiTupleConfigs,
  ErrorType = Error,
  MappedResponse = Response
>(
  apiEndpoint: string,
  config: Config,
  fakeData?: { data: MappedResponse | (() => MappedResponse); delay?: number },
  mapper?: (response: Response | undefined) => MappedResponse | undefined
): UseBestApiTuple<MappedResponse, Config, ErrorType> => {
  const latestConfig = useRef(config);
  useEffect((): void => {
    latestConfig.current = config;
  });

  const latestFakeData = useRef(fakeData);

  useEffect((): void => {
    latestFakeData.current = fakeData;
  }, [fakeData]);

  const latestApiEndpoint = useRef(apiEndpoint);
  useEffect((): void => {
    latestApiEndpoint.current = apiEndpoint;
  }, [apiEndpoint]);

  const [loading, error, response, query] = useBaseApiQuery<
    Response,
    Config,
    ErrorType,
    MappedResponse
  >(latestApiEndpoint.current, latestConfig.current, latestFakeData.current, mapper);

  const fetch = useRef(query);

  const [_error, _setError] = useState<ErrorType | undefined>();

  useEffect((): void => {
    _setError(error);
  }, [error]);

  useEffect(() => {
    if (config.method === 'GET' && !config.onlyCallFromRefetch) {
      _setError(undefined);
      fetch.current(latestConfig.current, latestFakeData.current).catch((e) => {
        _setError(e);
      });
    }
  }, [config.onlyCallFromRefetch, config.method]);

  const refetch = async (): Promise<MappedResponse | undefined> => {
    _setError(undefined);
    return fetch.current(latestConfig.current, latestFakeData.current);
  };

  switch (config.method) {
    case 'GET':
      return [loading, _error, response, refetch, 'GET'];
    case 'DELETE':
    case 'PUT':
    case 'PATCH':
    case 'POST':
      return [loading, _error, response, fetch.current, config.method];
  }
};
