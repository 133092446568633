import CryptoJS from 'crypto-js';

import { datadogRum } from '@datadog/browser-rum';
import {
  SET_USER,
  DELETE_USER,
  SET_USER_CONFIG,
  FAKE_PERMISSION,
  LOGOUT_USER,
  UPDATE_USER_MDM,
} from 'Constants/user.constants';
import sha256 from 'crypto-js/sha256';
import { Permission } from 'Models/Permission/_types_/Permission';
import { User } from 'Models/User/_types_/User';
import { UserActionType } from 'Models/User/_types_/UserActionType';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { SetUser, SetUserConfig, UpdateUserMdM } from 'Store/actions/user.actions';

import { getPermissionsArray } from '../../Constants/permissions.constants';
import { FakePermission } from '../actions/user.actions';

const initialState: UserReducerType = { user: null, config: null };

const saveUserToStorage = (jsonString: string): void => {
  if (JSON.parse(jsonString)) {
    sessionStorage.setItem('user', jsonString);
  }
};

const permissionUpdater = (permissionId: number, permissions: Permission[]): Permission[] => {
  const newPermissions = [...permissions];

  const currentPermissionIndex = newPermissions.findIndex(
    (userPermission) => userPermission.permissionsId === permissionId
  );
  if (currentPermissionIndex > -1) {
    permissions.splice(currentPermissionIndex, 1);
  } else {
    const newPermission = getPermissionsArray().find(
      (systemPermission) => systemPermission.permissionsId === permissionId
    );
    if (newPermission) {
      permissions.push(newPermission);
    }
  }
  return newPermissions;
};

export default (
  state = initialState,
  action: UserActionType | SetUserConfig | FakePermission | SetUser | UpdateUserMdM
): UserReducerType => {
  switch (action.type) {
    case SET_USER: {
      const actionUser = (action as SetUser).user;
      actionUser.userIdHash = sha256('' + actionUser.usersId).toString(CryptoJS.enc.Hex);
      const jsonString = JSON.stringify((action as SetUser).user);
      try {
        saveUserToStorage(jsonString);
      } catch {
        datadogRum.addError('Invalied JSON to store in user in localStorage/sessionStorage', {
          jsonString,
        });
      }
      return {
        ...state,
        user: actionUser,
      };
    }
    case UPDATE_USER_MDM: {
      if (state.user === null || state.user === undefined) {
        return state;
      }
      return {
        ...state,
        user: { ...state.user, vendorMdmIdentifier: (action as UpdateUserMdM).newMdM },
      };
    }

    case FAKE_PERMISSION: {
      if (!state.user) {
        return { ...state };
      }
      const permissionId = (action as FakePermission).permissionId;

      const newUser: User = {
        ...state.user,
        permissions: [...state.user.permissions],
      };

      return {
        ...state,
        user: {
          ...newUser,
          permissions: permissionUpdater(permissionId, newUser.permissions),
        },
      };
    }
    case LOGOUT_USER:
    case DELETE_USER: {
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      return { ...state, user: null };
    }
    case SET_USER_CONFIG: {
      return { ...state, config: (action as SetUserConfig).config };
    }
    default:
      return state;
  }
};
