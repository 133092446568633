import { LDFlagSet } from 'launchdarkly-js-client-sdk';

import { vpFeatureFlags } from './FeatureFlags';

export const ldFlagsMapper = (fromJest?: boolean): LDFlagSet => {
  const localFlags = [...vpFeatureFlags.getAllFeatureFlags()].filter((x: string): boolean =>
    isNaN(+x)
  );
  const ldFlags: LDFlagSet = {};
  localFlags.forEach((x: string): void => {
    ldFlags[x] = fromJest ? true : false;
  });
  return ldFlags;
};
