import { WebToken } from '_types_/Token';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { MAP_TOKEN } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useMapMicrosoftTokenToProfileToken';
import { PostMapMicrosoftTokenConfig } from 'UserAuthentication/_LoginUserServices/loginService';

export const useMapCurrentProfileTokenToSelectedProfileToken = (
  bi2networkId?: string,
): UseLazyBestApiTuple<WebToken, PostMapMicrosoftTokenConfig> => {
  return useLazyBestApi<WebToken, PostMapMicrosoftTokenConfig>(MAP_TOKEN, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'USERS-API-VERSION': '2.0',
    },
    data: {
      grant_type: 'password',
      client_id: 'bff-clientid',
      NETWORK_ID: bi2networkId,
    },
    method: 'POST',
  });
};
