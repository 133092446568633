import React, { ReactElement, useCallback } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Paper, Typography } from '@mui/material';

type Props = {
  goToWelcome: () => void;
  email: string;
};

const Invited = (props: Props): ReactElement => {
  const handleClick = useCallback(() => {
    props.goToWelcome();
  }, [props]);

  return (
    <Paper elevation={0} sx={{ height: '300px', width: '700px', padding: 5 }}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" alignItems="center" mb={4} columnGap={1}>
          <CheckCircleIcon color="success" />
          <Typography mb={2} color="primary.main" fontSize={28} fontWeight="bold" m={0}>
            You have been invited
          </Typography>{' '}
        </Box>
        <Typography mb={1}>
          In order to use Vendor Portal, please check the mailbox for {props.email} and look for our
          invitation. Follow the instructions, and you will be able to sign in with Microsoft.
        </Typography>
        <Box mt={2} display="flex" justifyContent="end">
          <Button disableElevation variant="contained" onClick={handleClick}>
            Go back to login screen
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Invited;
