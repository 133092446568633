import { TableState } from '../_types_/TableState';

export const SET_TABLE_LOADING = 'SET_TABLE_LOADING';

export interface SetTableLoading {
  type: typeof SET_TABLE_LOADING;
  isLoading: boolean;
}

export const doSetTableLoading = (isLoading: boolean): SetTableLoading => ({
  type: SET_TABLE_LOADING,
  isLoading,
});

export const applySetTableLoading = (newState: TableState, action: SetTableLoading): TableState => {
  newState.isLoading = action.isLoading;

  return newState;
};
