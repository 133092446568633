import { MultipleActionItem } from 'Models/Orders/MultipleSelectActions/MultipleSelectActions';
import { OrdersAction, SetSelectedOrders } from 'Store/actions/orders/orders.actions';

import { SET_SELECTED_ORDERS, CLEAR_SELECTED_ORDERS } from '../actions/orders/orders.actions';

export interface OrdersState {
  selectedOrders: MultipleActionItem[];
}

const INITIAL_STATE: OrdersState = {
  selectedOrders: [],
};

const applySetSelectedOrders = (state: OrdersState, { orders }: SetSelectedOrders): OrdersState => {
  const returnedState = { ...state };

  orders.forEach((order) => {
    const alreadySelected = returnedState.selectedOrders.some((r) => r.id === order.id);

    if (alreadySelected) {
      returnedState.selectedOrders = returnedState.selectedOrders.filter((r) => r.id !== order.id);
    } else {
      returnedState.selectedOrders = [...returnedState.selectedOrders, order];
    }
  });

  return returnedState;
};

const applyClearSelectedOrders = (state: OrdersState): OrdersState => {
  const returnedState = { ...state };
  returnedState.selectedOrders = [];
  return returnedState;
};

export default (state = INITIAL_STATE, action: OrdersAction): OrdersState => {
  let returnedState = { ...state };
  switch (action.type) {
    case SET_SELECTED_ORDERS:
      returnedState = applySetSelectedOrders(returnedState, action as SetSelectedOrders);
      break;
    case CLEAR_SELECTED_ORDERS:
      returnedState = applyClearSelectedOrders(returnedState);
      break;
    default:
      break;
  }
  return returnedState;
};
