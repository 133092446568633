import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { customConfirm } from 'Components/Shared/ConfirmDialog';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

export interface CampaignConfig {
  [user: string]: {
    closeCount: number;
    neverShow: boolean;
  };
}

interface Props {
  CAMPAIGN_KEY: string;
}
const useCampaignConfigHandler = ({
  CAMPAIGN_KEY,
}: Props): { show: boolean; handleHide: () => void; userLoggedIn: boolean } => {
  const userKey = useSelector((root: RootReducerState): UserReducerType => root.userReducer).user
    ?.userIdHash;
  const flags = useFlags();

  const getFeatureFlag = useGetFeatureFlag();
  const [config, setConfig] = useState<CampaignConfig | undefined>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!flags[getFeatureFlag('show-campaign')] || config === undefined || userKey === undefined) {
      setShow(flags[getFeatureFlag('show-campaign')]);
      return;
    }

    setShow((prevVal) =>
      prevVal !== !config[userKey].neverShow ? !config[userKey].neverShow : prevVal
    );
  }, [config, flags, getFeatureFlag, userKey]);

  const generatedPayload = useCallback(
    (userKey: string | undefined, neverShow: boolean | undefined): CampaignConfig | undefined => {
      if (userKey === undefined) {
        return;
      }
      if (config === undefined) {
        return { [userKey]: { neverShow: false, closeCount: 0 } };
      }
      return {
        [userKey]: {
          ...config[userKey],
          neverShow: neverShow ? neverShow : config[userKey]?.neverShow ?? false,
          closeCount:
            config[userKey]?.closeCount !== undefined ? config[userKey].closeCount + 1 : 0,
        },
      };
    },
    [config]
  );

  const saveConfig = useCallback(
    (payload: CampaignConfig | undefined) => {
      if (userKey === undefined) {
        return;
      }
      setConfig((prevVal) =>
        prevVal === undefined || userKey === undefined || prevVal[userKey] === undefined
          ? payload
          : prevVal
      );
    },
    [userKey]
  );

  useEffect(() => {
    const storedConfig = localStorage.getItem(CAMPAIGN_KEY);
    if (storedConfig !== null && config === undefined) {
      const parsedConfig: CampaignConfig = JSON.parse(storedConfig);
      saveConfig(parsedConfig);
      return;
    }
    saveConfig(generatedPayload(userKey, undefined));
  }, [CAMPAIGN_KEY, config, generatedPayload, userKey, saveConfig]);

  const enrichedPayload = useCallback(
    (payload: CampaignConfig | undefined) => {
      const storedConfig = localStorage.getItem(CAMPAIGN_KEY);
      if (storedConfig !== null) {
        const parsedConfig: CampaignConfig = JSON.parse(storedConfig);
        return JSON.stringify({ ...parsedConfig, ...payload });
      }
      return JSON.stringify(payload);
    },
    [CAMPAIGN_KEY]
  );

  const handleConfirmNeverShow = useCallback(async () => {
    if (userKey === undefined || config === undefined) {
      return;
    }
    let _neverShow = false;
    const confirmed = await customConfirm('Do you want to never see this again?');
    if (confirmed) {
      _neverShow = true;
    }
    localStorage.setItem(CAMPAIGN_KEY, enrichedPayload(generatedPayload(userKey, _neverShow)));
    return Promise.resolve();
  }, [userKey, config, CAMPAIGN_KEY, enrichedPayload, generatedPayload]);

  const handleHide = useCallback(async () => {
    if (userKey === undefined) {
      setShow(false);
      return;
    }
    if (config !== undefined && config[userKey].closeCount >= 2) {
      await handleConfirmNeverShow();
    } else {
      localStorage.setItem(CAMPAIGN_KEY, enrichedPayload(generatedPayload(userKey, undefined)));
    }
    setShow(false);
  }, [CAMPAIGN_KEY, config, enrichedPayload, generatedPayload, handleConfirmNeverShow, userKey]);

  return { show, handleHide, userLoggedIn: userKey !== undefined };
};

export default useCampaignConfigHandler;
