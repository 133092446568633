import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import { User } from 'Models/User/_types_/User';
import { BestoneResponse } from 'Services/ext.service/interfaces/BestoneFileResponse';
import {
  GetUserConfig,
  userServiceApiBaseUrl,
} from 'UserAuthentication/_LoginUserServices/userService';

const GET_PROFILE = userServiceApiBaseUrl('info');

const getIsBestsellerUser = (user: User): boolean => {
  if (user.userType === 'BESTSELLER') {
    return true;
  }

  if (user.canChangeVendorMdmIdentifier && user.canHaveEmptyVendorMdmIdentifier) {
    return true;
  }

  return false;
};

export const transformUser = (profileData: User | BestoneResponse<User>): User => {
  if ('data' in profileData && profileData.data) {
    return {
      ...profileData.data,
      hasADLogin: true,
      adEmail: profileData.data.email,
      inviteStatus: profileData.data.inviteStatus,
      isBestsellerUser: getIsBestsellerUser(profileData.data),
    };
  }

  return {
    ...(profileData as User),
    hasADLogin: true,
    adEmail: (profileData as User).email,
    inviteStatus: (profileData as User).inviteStatus,
    isBestsellerUser: getIsBestsellerUser(profileData as User),
  };
};

export const useGetProfile = (
  token: string,
): UseLazyBestApiTuple<User | BestoneResponse<User>, GetUserConfig> => {
  return useLazyBestApi<User | BestoneResponse<User>, GetUserConfig>(GET_PROFILE, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      Authorization: 'Bearer ' + token,
      provider: 'basic',
      'USERS-API-VERSION': '2.0',
    },
    method: 'GET',
  });
};
