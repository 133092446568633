import { OrderAttachmentType } from 'Models/Documents/_types_';
import { SelectionInterface } from 'Models/Documents/_types_/SelectPackingNote';
import { ShippingInfoGroup } from 'Models/Documents/_types_/ShippingInfoGroup';
import {
  DocumentsActions,
  SET_SELECTED_ORDERS,
  SET_SELECTED_DOCUMENT_TYPE,
  CLEAR_DOCUMENTS_STATE,
  ClearDocumentsStateAction,
  SetSelectedOrdersAction,
  SetSelectedDocumentTypeAction,
  SET_SAVING,
  SetSaving,
  INIT_UPLOAD_STATE,
  InitUploadStateAction,
  TOGGLE_ALL_PN,
  ToggleAllPnAction,
  TOGGLE_UPLOAD_PN_STATE,
  ToggleUploadPnAction,
} from 'Store/actions/documents/documents.actions';

export interface DocumentsState {
  selectedOrders: string[];
  documentReferences: SelectionInterface[];
  saving: boolean;
  selectedDocumentType?: OrderAttachmentType;
  shippingDocGroup?: ShippingInfoGroup;
}

const INITIAL_STATE: DocumentsState = {
  selectedDocumentType: undefined,
  documentReferences: [],
  saving: false,
  selectedOrders: [],
};

const applySetSelectedOrders = (
  state: DocumentsState,
  action: SetSelectedOrdersAction,
): DocumentsState => {
  const returnedState = { ...state };
  returnedState.selectedOrders = action.selectedOrders;
  returnedState.shippingDocGroup = action.shippingDocGroup;
  return returnedState;
};

const applySetSelectedDocumentType = (
  state: DocumentsState,
  action: SetSelectedDocumentTypeAction,
): DocumentsState => {
  const returnedState = { ...state };
  returnedState.selectedDocumentType = action.selectedDocumentType;
  return returnedState;
};

const applyClearDocumentsState = (
  _state: DocumentsState,
  _action: ClearDocumentsStateAction,
): DocumentsState => ({
  ..._state,
  selectedDocumentType: undefined,
  documentReferences: [],
  saving: false,
});

const applySetSaving = (_state: DocumentsState, _action: SetSaving) => ({
  ..._state,
  saving: _action.saving,
});

const applyInitUploadState = (_state: DocumentsState, _action: InitUploadStateAction) => ({
  ..._state,
  documentReferences: _action.elements,
});

const applyToggleAllPn = (_state: DocumentsState, _action: ToggleAllPnAction) => ({
  ..._state,
  documentReferences: [..._state.documentReferences].map((x): SelectionInterface => {
    if (x.orderNumber === _action.orderNumber) {
      if (!_action.checked) {
        return { ...x, packingNotesIds: [] };
      }
      return { ...x, packingNotesIds: _action.packingNotesIds };
    }
    return { ...x };
  }),
});

const applyUploadPnState = (_state: DocumentsState, _action: ToggleUploadPnAction) => ({
  ..._state,
  documentReferences: [..._state.documentReferences].map((x): SelectionInterface => {
    if (x.orderNumber === _action.orderNumber) {
      if (!_action.checked) {
        return {
          ...x,
          packingNotesIds: x.packingNotesIds.filter((y) => y !== _action.packingNoteId),
        };
      } else {
        return {
          ...x,
          packingNotesIds: [...x.packingNotesIds, _action.packingNoteId],
        };
      }
    }
    return x;
  }),
});

export default (
  state: DocumentsState = INITIAL_STATE,
  action: DocumentsActions = { type: '' },
): DocumentsState => {
  let returnedState = { ...state };

  switch (action.type) {
    case SET_SELECTED_ORDERS:
      returnedState = applySetSelectedOrders(returnedState, action as SetSelectedOrdersAction);
      break;
    case SET_SELECTED_DOCUMENT_TYPE:
      returnedState = applySetSelectedDocumentType(
        returnedState,
        action as SetSelectedDocumentTypeAction,
      );
      break;
    case CLEAR_DOCUMENTS_STATE:
      returnedState = applyClearDocumentsState(returnedState, action as ClearDocumentsStateAction);
      break;
    case SET_SAVING:
      returnedState = applySetSaving(returnedState, action as SetSaving);
      break;
    case INIT_UPLOAD_STATE:
      returnedState = applyInitUploadState(returnedState, action as InitUploadStateAction);
      break;
    case TOGGLE_ALL_PN:
      returnedState = applyToggleAllPn(state, action as ToggleAllPnAction);
      break;
    case TOGGLE_UPLOAD_PN_STATE:
      returnedState = applyUploadPnState(state, action as ToggleUploadPnAction);
      break;
    default:
      break;
  }

  return returnedState;
};
