import { useState, useCallback } from 'react';

import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { doSetTableColumns } from 'Components/Table/state/actions/setTableColumns';
import { useTableState } from 'Components/Table/state/useTableState';

export interface DragOver {
  id: string;
  index: number;
  dropRight: boolean;
}
const useDraggable = <T extends TableRecord>(): {
  dragging: boolean;
  dragOver: DragOver | undefined;
  handleDragStart: (e: React.DragEvent, id: string) => void;
  handleDragOver: (e: React.DragEvent, id: string) => void;
  handleDragEnter: (id: string) => void;
  handleOnDrop: (e: React.DragEvent, id: string) => void;
  handleDragEnd: () => void;
} => {
  const { state, dispatch } = useTableState<T>();
  const [dragging, setDragging] = useState(false);

  const [dragOver, setDragOver] = useState<DragOver | undefined>();

  const handleDragStart = useCallback(
    (e: React.DragEvent, id: string) => {
      setDragging(true);
      const idx = state.columns.findIndex((x) => x.property === id);
      e.dataTransfer?.setData('colIdx', '' + idx);
    },
    [state.columns]
  );

  const handleDragOver = (e: React.DragEvent, id: string) => {
    e.preventDefault();
    const boundingClient = e.currentTarget.getBoundingClientRect();

    // this is pure magic!
    // Calculate if the dragged element is most to the left, or the right of the parent element
    const dropRight =
      boundingClient.width / 2 + 1 > boundingClient.left + boundingClient.width - e.clientX;

    setDragOver((prevVal) => {
      if (
        prevVal?.id === id ||
        e.currentTarget === null ||
        e.currentTarget === undefined ||
        (prevVal?.dropRight !== undefined && prevVal?.dropRight === dropRight)
      ) {
        return prevVal;
      }

      return prevVal === undefined
        ? prevVal
        : {
            ...prevVal,
            dropRight,
          };
    });
  };

  const handleDragEnter = (id: string) => {
    const idx = state.columns.findIndex((x) => x.property === id);

    setDragOver((prevVal) => ({
      id,
      index: idx,
      dropRight: prevVal && prevVal?.index > idx ? true : false,
    }));
  };

  const handleOnDrop = useCallback(
    (e: React.DragEvent, id: string) => {
      const droppedColIdx = state.columns.findIndex((x) => x.property === id);
      const draggedColIdxString = e.dataTransfer?.getData('colIdx');

      if (!draggedColIdxString) {
        setDragging(false);
        setDragOver(undefined);

        return;
      }
      const draggedColIdx = +draggedColIdxString;
      if (droppedColIdx === draggedColIdx) {
        setDragging(false);
        setDragOver(undefined);

        return;
      }
      const tempCols = [...state.columns];

      tempCols.splice(+draggedColIdx, 1);

      if (!dragOver?.dropRight) {
        tempCols.splice(droppedColIdx, 0, state.columns[draggedColIdx]);
      } else {
        tempCols.splice(droppedColIdx + 1, 0, state.columns[draggedColIdx]);
      }

      dispatch(doSetTableColumns(tempCols));
      setDragOver(undefined);
      setDragging(false);
    },
    [state.columns, dragOver?.dropRight, dispatch]
  );

  const handleDragEnd = () => {
    setDragOver(undefined);
    setDragging(false);
  };
  return {
    handleDragEnd,
    dragOver,
    handleDragStart,
    handleDragOver,
    handleDragEnter,
    handleOnDrop,
    dragging,
  };
};

export default useDraggable;
