import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import axios, { AxiosResponse } from 'axios';

import { useMsal } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { WebToken } from '_types_/Token';
import { LOGOUT, SET_ERROR_MESSAGE } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { clearMicrosoftAuthCookies } from 'UserAuthentication/Login/LoginButton/helpers/clearAuthCookies';

const LOGGER_KEY = `useRefreshToken`;

export const useRefreshToken = (): {
  refreshToken: () => Promise<AxiosResponse<WebToken>>;
  logoutUser: () => void;
} => {
  const { authState, authDispatch } = useAuthState();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const logoutUser = useCallback((): void => {
    clearMicrosoftAuthCookies();
    authDispatch({
      type: SET_ERROR_MESSAGE,
      errorMessage: 'Unauthorized user',
    });
    instance.logout();
    authDispatch({ type: LOGOUT });

    if (navigate !== undefined) {
      navigate(authState.config.loginpath);
    }
  }, [authDispatch, authState.config.loginpath, instance, navigate]);

  const refreshToken = useCallback((): Promise<AxiosResponse<WebToken>> => {
    if (!authState?.config.refreshUrl) {
      datadogRum.addError(`${LOGGER_KEY}: No refresh url provided`);
      throw new Error('No refresh url provided');
    }
    if (authState.tokens?.refreshToken === undefined) {
      datadogRum.addError(`${LOGGER_KEY}: No refreshToken provided`);
      authDispatch({ type: LOGOUT });
      throw new Error('No refreshToken provided');
    }

    datadogRum.addAction('Refresh token');

    const cleanAxios = axios.create();
    return cleanAxios.post(authState.config.refreshUrl, null, {
      params: {
        grant_type: 'refresh_token',
        refresh_token: authState.tokens.refreshToken,
      },
      headers: {
        Authorization: `Basic ${authState.config.refreshTokenToken}`,
      },
    });
  }, [
    authDispatch,
    authState.config.refreshTokenToken,
    authState.config.refreshUrl,
    authState.tokens?.refreshToken,
  ]);

  return { refreshToken, logoutUser };
};
