import { gql } from 'graphql-tag';

export const GET_RELEASES = gql`
  query GetReleases @api(name: releaseTool) {
    releases {
      name
      releaseIndex
      items {
        index
        title
        description
      }
      released
      releaseDate
    }
  }
`;
