import { UpdateDetailsColor } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const updateDetailsColor = (
  updatedState: SampleProformaHeaderState,
  action: UpdateDetailsColor
): SampleProformaHeaderState => {
  const updatedLines = [...updatedState.lineInfoGroup].map((x) => {
    if (x.groupId === action.groupId) {
      x.lines = x.lines.map((line) => {
        if (line.lineId === action.lineId) {
          return {
            ...line,
            color: action.value,
          };
        }
        return line;
      });
    }
    return x;
  });

  return {
    ...updatedState,
    lineInfoGroup: updatedLines,
  };
};
