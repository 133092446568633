import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableColumn } from '../_types_/TableColumn';
import { TableFilters } from '../_types_/TableFilters';
import { TableState } from '../_types_/TableState';

export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';

export interface SetTableColumnsAction<T extends TableRecord> {
  type: typeof SET_TABLE_COLUMNS;
  columns: TableColumn<T>[];
}

export const doSetTableColumns = <T extends TableRecord>(
  columns: TableColumn<T>[]
): SetTableColumnsAction<T> => ({
  type: SET_TABLE_COLUMNS,
  columns,
});

export const applySetTableColumns = (
  newState: TableState,
  action: SetTableColumnsAction<TableRecord>
): TableState => {
  newState.columns = action.columns;

  newState.columns.forEach((r) => {
    // Sets the table filters with the value passed through the column
    if (r.filter && r.filter.values) {
      newState.filters[r.property] = {
        type: newState.filters[r.property]?.type ?? r.filter.type,
        value: newState.filters[r.property]?.value ?? r.filter.values,
        // Hacky because typescript
      } as TableFilters<TableRecord>[typeof r.property];
    }
  });

  return newState;
};
