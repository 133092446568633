import { UseBestApiRequestConfig } from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { UseLazyBestApiTuple, useLazyBestApi } from 'Components/Hooks/useLazyBestApi';
import { packingNoteBaseUrl } from 'PackingNotes/_services_';
export const TRANSMIT_PACKING_NOTE = (packingNotesId: number): string =>
  packingNoteBaseUrl(`packing-notes/packing-notes/${packingNotesId}/transmit`);

type TransmitPackingNoteRequestConfig = UseBestApiRequestConfig & {
  method: 'PATCH';
};

export const useTransmitPackingNote = (
  packingNoteNumber: number
): UseLazyBestApiTuple<void, TransmitPackingNoteRequestConfig> => {
  return useLazyBestApi<void, TransmitPackingNoteRequestConfig>(
    TRANSMIT_PACKING_NOTE(packingNoteNumber),
    {
      method: 'PATCH',
      headers: { 'PACKING-NOTES-API-VERSION': '1.0' },
      localErrorHandler: true,
    }
  );
};
