import qs from 'qs';

export const vpFilterToQueryParams = (variables: Record<string, unknown>): string => {
  if (!variables['filters']) {
    return '';
  }
  const keys = Object.keys(variables['filters'] as Record<string, unknown>);
  const result: { [key: string]: unknown } = {};

  keys.forEach((key) => {
    if (variables === null || variables['filters'] === null) {
      return;
    }

    if (typeof (variables['filters'] as Record<string, unknown>)[key] === 'object') {
      const filters = (variables['filters'] as Record<string, unknown>)[key] as Record<
        string,
        unknown
      >;
      if (
        filters === null ||
        (filters === undefined && (!filters['value'] || !filters['values']))
      ) {
        return;
      }

      const value = filters['value'];
      const values = filters['values'];
      if (values) {
        const valuesAsArray = values as [];
        if (value) {
          result[key] = [value, ...valuesAsArray].join(',');
        } else {
          result[key] = [valuesAsArray].join(',');
        }
      } else {
        result[key] = value;
      }
    } else {
      result[key] = (variables['filters'] as Record<string, unknown>)[key];
    }
  });

  const queryParams = qs.stringify(result);
  return queryParams;
};
