import { ReactElement, useCallback, useMemo } from 'react';

import { Box, Checkbox, Typography } from '@mui/material';

import { doExpandTableRow } from 'Components/Table/state/actions/expandRow';
import { getIsRowExpanded } from 'Components/Table/state/selectors/getIsRowExpanded';
import { useTableState } from 'Components/Table/state/useTableState';

const ExpandAllButton = (): ReactElement => {
  const { state, dispatch } = useTableState();

  const areAllOpen = useMemo(
    () => state.rows.length === state.expandedRows.length,
    [state.expandedRows.length, state.rows.length]
  );

  // Function to handle the action of opening all rows
  const handleOpenAll = useCallback(() => {
    // Iterate over each row in the state
    state.rows.forEach((row) => {
      // Convert the primary key of the row to a string
      const id = String(row[state.primaryKey]);

      // Check if all rows are open or if the current row is not expanded
      if (areAllOpen || !getIsRowExpanded(state, id)) {
        // If either condition is true, dispatch an action to expand the row
        dispatch(doExpandTableRow(id));
      }
    });
  }, [areAllOpen, dispatch, state]);

  return (
    <Box display="flex" alignItems="center">
      <Checkbox checked={areAllOpen} onChange={handleOpenAll} disabled={state.rows.length === 0} />
      <Typography>Expand all</Typography>
    </Box>
  );
};

export default ExpandAllButton;
