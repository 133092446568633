import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PERMISSION } from 'Constants/permissions.constants';
import { INBOUND_INVOICE_TO, SAMPLE_PROFORMA_INVOICE_TO } from 'Constants/shared.constants';
import { useHasPermission } from 'Models/Permission/permissionHelper';
import NoAccess from 'Views/NoAccess/NoAccess';

const Invoice = (): ReactElement => {
  const navigate = useNavigate();
  const hasPermission = useHasPermission();

  useEffect((): void => {
    if (hasPermission(PERMISSION.VP_INVOICE_INBOUND_VIEW)) {
      navigate(INBOUND_INVOICE_TO, { replace: true });
      return;
    }
    if (hasPermission(PERMISSION.VP_PROFORMA_INVOICE_VIEW)) {
      navigate(SAMPLE_PROFORMA_INVOICE_TO, { replace: true });
    }
  }, [hasPermission, navigate]);
  return <NoAccess />;
};

export default Invoice;
