import { ReactElement, useMemo } from 'react';

import { Typography, Button, Box } from '@mui/material';

import { Dialog } from '@bestseller-bit/frontend-community.components.dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  token: string | undefined;
}

const ContactSupportDialog = (props: Props): ReactElement => {
  const mailTitle = 'Unable to login (403)';
  const mailBody = useMemo(
    (): string =>
      props.token !== undefined
        ? `
    %0D%0A
    %0D%0A
    %0D%0A
  -----------------------------------------------------
  %0D%0A
  PLEASE KEEP THIS:
  %0D%0A
  ${props.token}
  %0D%0A
  -----------------------------------------------------`
        : '',
    [props.token],
  );
  const mailParams = useMemo(() => `?subject=${mailTitle}&body=${mailBody}`, [mailTitle, mailBody]);

  const buttons = useMemo(() => {
    return [
      {
        label: 'Close',
        onClick: props.onClose,
      },
    ];
  }, [props.onClose]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title="Unable to log you in"
      titleVariant="h5"
      dividers
      buttons={buttons}
    >
      <Box>
        <Typography fontWeight="bold">We are unable to log you in.</Typography>
        <Typography marginTop="8px">
          <span>{`It might be because there's an issue with your account. `}</span>
        </Typography>
        <Typography marginTop="8px">
          <Button
            href={`mailto:techsourcing@bestseller.com${mailParams}`}
            variant="outlined"
            style={{ cursor: 'pointer' }}
            color="info"
          >
            Please click here to contact support.
          </Button>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default ContactSupportDialog;
