import { AxiosError } from 'axios';

interface CustomError extends Error {
  response?: {
    data?: {
      message?: string;
    };
  };
}

const isCustomError = (error: any): error is CustomError => {
  return (
    error?.response && error.response?.data && typeof error.response.data?.message === 'string'
  );
};

const getErrorMessage = (
  error: Error | AxiosError | CustomError | undefined,
  fallback?: string,
): string => {
  if (error === undefined) {
    return '';
  }

  let message: string | undefined = undefined;
  if (isCustomError(error) && error.response?.data?.message) {
    message = error.response.data.message;
  } else {
    message = error.message;
  }
  return message ?? fallback ?? 'Unknown error';
};

export default getErrorMessage;
