import { properties } from 'Helpers';

import {
  deleteResourceRequestConfig,
  modifyResourceRequestConfig,
} from 'Components/Hooks/_types_/UseBestApiRequestConfig';

const addBeBranchToUrl = (beBranch?: string): string => (beBranch ? `${beBranch}/` : '');

export const loginServiceApiBaseUrl = (path: string, beBranch?: string): string =>
  `${properties.loginApiURL}${addBeBranchToUrl(beBranch)}${path}`;

export type DeleteLoginConfig = deleteResourceRequestConfig & {
  method: 'DELETE';
};

export type PostCheckCredentialsConfig = modifyResourceRequestConfig<{
  username: string;
  password: string;
  grant_type: string;
  client_secret: string;
  client_id: string;
}> & {
  method: 'POST';
};

export type PostMapMicrosoftTokenConfig = modifyResourceRequestConfig<{
  grant_type: string;
  client_id: string;
  NETWORK_ID?: string;
}> & {
  method: 'POST';
};
