import wait from 'waait';

export interface FakeData<Response> {
  data: Response | (() => Response);
  delay?: number;
}

export const fakeDataHandler = <Response>(
  fakeData: FakeData<Response>
): Promise<Response | PromiseLike<Response | undefined> | undefined> => {
  const fakeResult = fakeData.data instanceof Function ? fakeData.data() : fakeData.data;
  return wait(fakeData.delay ?? 5000).then((): Response => {
    return fakeResult;
  });
};
