import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const DRAWER_WIDTH = 240;

export const useStyles = () => {
  const theme = useTheme();
  const styles = makeStyles(() => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#3A3A3A',
      maxHeight: 64,
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    breadCrumbs: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    logoContainer: {
      display: 'flex',
      flex: 1,
    },
    menuIcon: {
      color: '#fff',
      transform: 'rotate(180deg)',
    },
  }));
  return styles;
};
