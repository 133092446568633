import { TableState } from '../_types_/TableState';

export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';

export interface ChangePageSize {
  type: typeof CHANGE_PAGE_SIZE;
  pageSize: number;
}

export const doChangePageSize = (pageSize: number): ChangePageSize => ({
  type: CHANGE_PAGE_SIZE,
  pageSize,
});

export const applyChangePageSize = (newState: TableState, action: ChangePageSize): TableState => {
  if (newState.pagination) {
    newState.pagination.pageSize = action.pageSize;
  }

  return newState;
};
