import React, { ReactElement } from 'react';

import { Button, Paper, Alert, AlertTitle } from '@mui/material';

import { doSetTableRefetch } from '../../state/actions/setShouldRefetch';
import { useTableState } from '../../state/useTableState';

interface Props {
  error: Error;
}

const TableError = ({ error }: Props): ReactElement => {
  const { dispatch } = useTableState();
  return (
    <Paper square>
      <Alert
        severity="error"
        action={
          <Button
            onClick={() => dispatch(doSetTableRefetch(true))}
            color="primary"
            variant="outlined"
          >
            Retry
          </Button>
        }
      >
        <AlertTitle>Encountered an error: </AlertTitle>
        {error.message}
      </Alert>
    </Paper>
  );
};

export default TableError;
