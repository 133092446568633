import { AxiosPromise, AxiosResponse } from 'axios';

import { FETCH_NEWS_ACTION, FETCH_NEWS_TYPES_ACTION } from 'Constants/news.constants';
import { News, NewsType } from 'Models/News/_types_/News';
import { newsService } from 'Services/news.service';

export interface NewsAction {
  type: string;
  payload: News[] | NewsType[];
}

function fetchNews(news: News[]): NewsAction {
  return {
    type: FETCH_NEWS_ACTION,
    payload: news,
  };
}

function fetchNewsTypes(): Promise<NewsAction> {
  return newsService.fetchNewsTypes().then((response: NewsType[]): NewsAction => {
    return {
      type: FETCH_NEWS_TYPES_ACTION,
      payload: response,
    };
  });
}

function addFreshNews(newsElem: News): AxiosPromise<News> {
  return newsService.addFreshNews(newsElem);
}

function deleteNews(newsId: number): AxiosPromise {
  return newsService.deleteNews(newsId);
}

function updateExistingNews(newsElem: News): AxiosPromise<AxiosResponse> {
  return newsService.updateExistingNews(newsElem);
}

export const newsActions = {
  fetchNews,
  fetchNewsTypes,
  addFreshNews,
  updateExistingNews,
  deleteNews,
};
