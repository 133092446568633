import { TableRecord } from '../_types_/TableRecord';
import { TableState } from '../_types_/TableState';

export const SET_TABLE_ROWS = 'SET_TABLE_ROWS';

export interface SetTableRowsAction<T extends TableRecord> {
  type: typeof SET_TABLE_ROWS;
  rows: T[];
}

export const doSetTableRows = <T extends TableRecord>(rows: T[]): SetTableRowsAction<T> => ({
  type: SET_TABLE_ROWS,
  rows,
});

export const applySetTableRows = <T extends TableRecord>(
  newState: TableState<T>,
  action: SetTableRowsAction<T>
): TableState => {
  newState.rows = action.rows;

  return newState;
};
