import { FETCH_NEWS_ACTION, FETCH_NEWS_TYPES_ACTION } from 'Constants/news.constants';
import { NewsState, ServerNews, News, NewsType } from 'Models/News/_types_/News';
import { NewsAction, NewsTypeAction } from 'Store/actions/_types_/NewsAction';

const initialState: NewsState = {
  news: [],
  newsTypes: [],
};

//TODO: move out of reducer (it makes more sens to have this logic inside the component that needs the news)
export default (state = initialState, action: NewsAction | NewsTypeAction): NewsState => {
  switch (action.type) {
    case FETCH_NEWS_ACTION: {
      const news = ((action as NewsAction)?.payload ?? []).map(
        (serverNews: ServerNews): News => ({
          id: serverNews.newsId || 0,
          attachmentMimeType: serverNews.attachmentMimeType,
          createdBy: serverNews.createdBy || 0,
          goLiveDate: new Date(serverNews.goLiveDate),
          picture: serverNews.pictures || null,
          salutation: serverNews.salutation,
          signature: serverNews.signature,
          text: serverNews.newsText,
          title: serverNews.newsTitle,
          picturesId: serverNews.picturesId || null,
          hasAttachment: serverNews.hasAttachment,
          newsType: {
            newsTypesId: serverNews.newsType.newsTypesId,
            description: serverNews.newsType.description ? serverNews.newsType.description : '',
            newsTypeName: serverNews.newsType.newsTypeName ? serverNews.newsType.newsTypeName : '',
          },
        }),
      );

      return { ...state, news: [...news] };
    }
    case FETCH_NEWS_TYPES_ACTION: {
      const newsTypes = (action as NewsTypeAction).payload.map(
        (newsType: NewsType): NewsType => newsType,
      );
      return { ...state, newsTypes: [...newsTypes] };
    }
    default:
      return state;
  }
};
