import { ReactElement } from 'react';
import ReactHtmlParser, { Transform } from 'react-html-parser';

import AttachmentIcon from '@mui/icons-material/Attachment';
import { Chip } from '@mui/material';
import Typography from '@mui/material/Typography';

import striptags from 'striptags';

import IconButton from 'Components/Shared/BestButton/IconButton';
import DownloadableLink from 'Components/Shared/DownloadableLink/DownloadableLink';
import { PERMISSION } from 'Constants/permissions.constants';
import { DateCell } from 'Helpers/resolver/DateResolver';
import { NewsProps, News } from 'Models/News/_types_/News';
import NewsViewerModal from 'Models/News/NewsViewerModal/NewsViewerModal';
import { useGetNewsAttachment } from 'Services/news.service/hooks';

import classes from './NewsItem.module.scss';

const NewsItem = (props: NewsProps): ReactElement => {
  const newsItem: News = props.news;
  const hasPicture = newsItem.picture && newsItem.picture.picture;
  const hasAttachment = newsItem.hasAttachment;
  const downloadableFunction = useGetNewsAttachment(newsItem.id);

  //Prepare for i18n
  const DeleteString = 'Delete';
  const AttachmentString = 'Attachment';

  const actions = (
    <IconButton
      onClick={(): void => props.deleteHandler(props.news)}
      colorSchema={{ background: 'none', foreground: 'white' }}
      permission={PERMISSION.VP_DELETE_NEWS}
      iconName="delete"
      className={classes.buttonInBox}
      tooltip={DeleteString}
      size="large"
    />
  );

  const picture = hasPicture ? (
    <img
      alt="complex"
      src={`data:image/jpeg;base64,${props.news.picture ? props.news.picture.picture : ''}`}
    />
  ) : null;

  const blackBar = [
    classes.blackBarWrapper,
    hasPicture ? classes.withImage : classes.withoutImage,
  ].join(' ');

  const downloadAttachmentExpanded = hasAttachment ? (
    <DownloadableLink
      mimeType={newsItem.attachmentMimeType}
      fileExt={''}
      key={newsItem.id}
      filename={AttachmentString}
      attachmentsId={newsItem.id}
      downloadFunction={downloadableFunction}
    />
  ) : null;

  const downloadAttachmentShrunk = hasAttachment ? (
    <Chip clickable={true} label={AttachmentString} icon={<AttachmentIcon />} />
  ) : null;

  //Apparenly there's some funky data currently in the DB containing <o:p>...</o:p> tag
  //This makes react freak out. So it's best not to render it
  const fixStrangeData: Transform | undefined = (node): ReactElement | undefined | null => {
    //Shitty interface for Transform
    //Access property 'name' on node {object}
    const key = 'name';
    const o = node[key as keyof typeof node];
    if (o === 'o:p') {
      return null;
    }
  };

  const expandedNewsElement = (news: News, showButtons: boolean): ReactElement => {
    return (
      <div className={classes.paper}>
        <div className={classes.header}>
          {picture}
          <div className={blackBar}>
            <div className={classes.dateWrapper}>
              <div>{DateCell(news.goLiveDate)}</div>
              {showButtons ? <div className={classes.actions}>{actions}</div> : null}
            </div>
          </div>
        </div>
        <div className={classes.newsText}>
          <Typography variant="h6">{news.title}</Typography>
          <Typography variant="body1">{news.salutation}</Typography>
          <Typography component="div" variant="body2">
            {ReactHtmlParser(news.text, {
              transform: fixStrangeData,
            })}
          </Typography>
          <Typography>{news.signature}</Typography>
        </div>
        {downloadAttachmentExpanded}
      </div>
    );
  };

  const shrunkNewsElement = (news: News): ReactElement => {
    const maxCharacters = 100;
    let newsType: string = news.newsType.newsTypeName;
    if (newsType.length > 0) {
      newsType = newsType.substring(0, newsType.length - 5);
    }
    let htmlFreeText: string = striptags(news.text).replace(/&nbsp;/g, ' ');
    const expandedNewsClickable: boolean =
      htmlFreeText.length > maxCharacters || news.picture !== null;

    if (htmlFreeText.length > maxCharacters) {
      htmlFreeText = htmlFreeText.substring(0, maxCharacters) + '...';
    }

    return (
      <NewsViewerModal modal={expandedNewsElement(news, false)} clickable={expandedNewsClickable}>
        <div className={classes.shrunkNewsContainer}>
          <div className={classes.shrunkNews}>
            <Typography
              variant="h6"
              className={[classes.title, classes.fontSize].join(' ')}
              component="div"
            >
              {news.title}
            </Typography>
            <div className={classes.captionContainer}>
              <Typography variant="caption" component="div">
                <i>
                  <span>{DateCell(news.goLiveDate)}</span>
                </i>
                <span className={classes.newsType}>{newsType}</span>
              </Typography>
            </div>
            <div className={classes.textContainer}>{htmlFreeText}</div>
            {downloadAttachmentShrunk}
          </div>
        </div>
      </NewsViewerModal>
    );
  };

  return (
    <>
      {props.dashboardContext ? shrunkNewsElement(newsItem) : expandedNewsElement(newsItem, true)}
    </>
  );
};

export default NewsItem;
