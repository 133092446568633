import { useEffect, useMemo, useState } from 'react';

import { Avatar, Box } from '@mui/material';

import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { getMyProfilePicture } from 'UserProfile/helpers/getProfilePicture';
import { useGetFirstAndLastLetter } from 'UserProfile/helpers/useGetFirstAndLastLetters';

interface Props {
  width?: number;
  height?: number;
}
const ProfileAvatar = ({ width = 80, height = 80 }: Props) => {
  const [userImageUrl, setUserImageUrl] = useState<string | undefined>();
  const { authState } = useAuthState();

  const accessToken = useMemo(
    () => authState.microsoftAccessToken,
    [authState.microsoftAccessToken],
  );

  useEffect(() => {
    if (!userImageUrl) {
      getMyProfilePicture(accessToken ?? '')
        .then((pictureUrl) => {
          setUserImageUrl(pictureUrl);
        })
        .catch(() => {
          setUserImageUrl('/broken-image.jpg');
        });
    }
  }, [accessToken, userImageUrl]);
  const useGetFirstAndLast = useGetFirstAndLastLetter();
  return (
    <Box>
      {userImageUrl ? (
        <Avatar sx={{ width: width, height: height }} src={userImageUrl} />
      ) : (
        <Avatar sx={{ width: height, height: width }}>{useGetFirstAndLast}</Avatar>
      )}
    </Box>
  );
};
export default ProfileAvatar;
