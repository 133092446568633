import axios, { AxiosResponse, AxiosPromise } from 'axios';
import { properties } from 'Helpers';

import DateFnsUtils from '@date-io/date-fns';
import { News, ServerNews } from 'Models/News/_types_/News';
import { NewsType } from 'Models/News/_types_/NewsType';

const branchNumber = sessionStorage.getItem('newsBranch')
  ? sessionStorage.getItem('newsBranch') + '/'
  : '';

export const FETCH_NEWS = properties.serviceApiURL + branchNumber + 'news/newsitems';
export const FETCH_NEWS_BY_ID = (newsId: number): string =>
  properties.serviceApiURL + branchNumber + 'news/newsitems/' + newsId;
export const DELETE_UPDATE_NEWS = (newsId: number): string =>
  properties.serviceApiURL + branchNumber + 'news/newsitems/' + newsId;
export const ADD_NEWS = properties.serviceApiURL + branchNumber + 'news/newsitems/';
export const FETCH_NEWS_TYPES = properties.serviceApiURL + branchNumber + 'news/newstypes';
export const FETCH_NEWS_ATTCHMENT = (newsId: number): string =>
  properties.serviceApiURL + branchNumber + 'news/newsattachment/' + newsId;

function GeneratePayload(newsElem: News): ServerNews {
  const ourPicturesId = newsElem.picturesId ? newsElem.picturesId : null;
  const payload: ServerNews = {
    newsTitle: newsElem.title,
    newsTypesId: newsElem.newsType.newsTypesId,
    newsText: newsElem.text,
    newsType: {
      newsTypesId: newsElem.newsType.newsTypesId,
      description: newsElem.newsType.description || '',
      newsTypeName: newsElem.newsType.newsTypeName || '',
    },
    salutation: newsElem.salutation,
    signature: newsElem.signature,
    goLiveDate: new DateFnsUtils().formatByString(newsElem.goLiveDate, 'yyyy-MM-dd'),
    createdDate: 0,
    changedDate: 0,
    deleted: 'N',
    attachment: '',
    attachmentMimeType: '',
    picturesId: ourPicturesId,
    hasAttachment: false,
  };

  if (newsElem.picture) {
    payload.pictures = {
      changedDate: 0,
      createdDate: 0,
      picture: newsElem.picture.picture,
      pictureName: newsElem.picture.pictureName,
      sourceSystemGuid: 'string',
      sourceSystemsId: 0,
      thumbnail: newsElem.picture.thumbnail,
    };
  } else {
    payload.pictures = null;
    payload.picturesId = ourPicturesId;
  }
  return payload;
}

function fetchNews(): Promise<News[]> {
  return axios
    .get(FETCH_NEWS, {
      headers: {
        'NEWS-API-VERSION': '2.0',
      },
    })
    .then((news: AxiosResponse<News[]>): News[] => news.data);
}

function fetchNewsById(id: number): Promise<News> {
  return axios
    .get(FETCH_NEWS_BY_ID(id), {
      headers: {
        'NEWS-API-VERSION': '2.0',
      },
    })
    .then((news: AxiosResponse<ServerNews>): News => {
      return {
        id: news.data.newsId || 0,
        attachmentMimeType: news.data.attachmentMimeType,
        createdBy: news.data.createdBy || 0,
        goLiveDate: new Date(news.data.goLiveDate),
        picture: news.data.pictures || null,
        picturesId: news.data.picturesId || null,
        salutation: news.data.salutation,
        signature: news.data.signature,
        text: news.data.newsText,
        hasAttachment: news.data.hasAttachment,
        title: news.data.newsTitle,
        newsType: {
          newsTypesId: news.data.newsType.newsTypesId,
          description: news.data.newsType.description || '',
          newsTypeName: news.data.newsType.newsTypeName || '',
        },
      };
    });
}

async function fetchNewsTypes(): Promise<NewsType[]> {
  return await axios
    .get(FETCH_NEWS_TYPES, {
      headers: {
        'NEWS-API-VERSION': '1.0',
      },
    })
    .then((newstypes: AxiosResponse<NewsType[]>): NewsType[] => newstypes.data);
}

function addFreshNews(newsElem: News): AxiosPromise<News> {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'NEWS-API-VERSION': '1.0',
    },
  };

  return axios({
    url: ADD_NEWS,
    method: 'post',
    data: JSON.stringify({ ...GeneratePayload(newsElem) }),
    headers: requestOptions.headers,
  });
}

function updateExistingNews(newsElem: News): AxiosPromise<AxiosResponse> {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'NEWS-API-VERSION': '1.0',
    },
  };

  return axios({
    url: DELETE_UPDATE_NEWS(newsElem.id),
    method: 'put',
    data: JSON.stringify({ ...GeneratePayload(newsElem) }),
    headers: requestOptions.headers,
  });
}

function deleteNews(newsId: number): AxiosPromise {
  const body = {
    newsId: newsId,
  };
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'NEWS-API-VERSION': '1.0',
    },
    body: JSON.stringify(body),
  };

  return axios({
    url: DELETE_UPDATE_NEWS(newsId),
    method: 'delete',
    data: JSON.stringify(body),
    headers: requestOptions.headers,
  });
}

export const newsService = {
  fetchNews,
  fetchNewsById,
  fetchNewsTypes,
  addFreshNews,
  updateExistingNews,
  deleteNews,
};
