import { Key } from 'react';

import { TableState } from 'Components/Table/state/_types_/TableState';

export const CONTROL_SELECTED_ROWS = 'CONTROL_SELECTED_ROWS';

export interface ControlSelectedRows {
  type: typeof CONTROL_SELECTED_ROWS;
  selectedArray: string[];
}

export const doControlSelectedRows = (selectedArray: Key[]): ControlSelectedRows => ({
  type: CONTROL_SELECTED_ROWS,
  selectedArray: selectedArray.map((key) => String(key)),
});

export const applyKeepSelectedRowsOfThisArray = (
  state: TableState,
  action: ControlSelectedRows
): TableState => {
  const controlledSelectedRows = action.selectedArray;
  controlledSelectedRows.forEach((key) => {
    if (!state.selectedRows[key]) {
      const tableRecord = state.rows.find((r) => String(r[state.primaryKey]) === key);
      if (tableRecord) {
        state.selectedRows[key] = tableRecord;
      }
    }
  });
  Object.keys(state.selectedRows).forEach((key) => {
    if (!controlledSelectedRows.includes(key)) {
      delete state.selectedRows[key];
    }
  });
  return state;
};
