import { useEffect, useMemo } from 'react';

import _ from 'lodash';

import { TableOptions } from '../../state/_types_/TableOptions';
import { doSetTableOptions, DEFAULT_ROWS_PER_PAGE } from '../../state/actions/setTableOptions';
import { useTableState } from '../../state/useTableState';

const useTableOptionsHelper = (_options: Partial<TableOptions>): void => {
  const { dispatch, state } = useTableState();
  const {
    toolbar = true,
    stickyHeader = true,
    paper = true,
    selectable = false,
    selectAll = false,
    searchBar = false,
    disableRefetchButton = false,
    responsive = true,
    showTableName = true,
    responsiveBreakpoint = undefined,
    rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE,
    expandable = false,
    draggable = true,
    dontAddExpandToUrl = false,
    showExpandAllButton = false,
  } = _options;

  const options = useMemo(
    () => ({
      toolbar,
      stickyHeader,
      paper,
      selectable,
      selectAll,
      searchBar,
      disableRefetchButton,
      responsive,
      responsiveBreakpoint,
      rowsPerPageOptions,
      expandable,
      showTableName,
      draggable,
      dontAddExpandToUrl,
      showExpandAllButton,
    }),
    [
      toolbar,
      stickyHeader,
      paper,
      selectable,
      selectAll,
      searchBar,
      disableRefetchButton,
      responsive,
      responsiveBreakpoint,
      rowsPerPageOptions,
      expandable,
      showTableName,
      draggable,
      dontAddExpandToUrl,
      showExpandAllButton,
    ]
  );

  useEffect(() => {
    if (!_.isEqual(options, state.tableOptions)) {
      dispatch(doSetTableOptions(options));
    }
  }, [dispatch, options, state.tableOptions]);
};

export default useTableOptionsHelper;
