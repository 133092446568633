import { CartonContentRow } from 'PackingNotes/_types_/CartonHeaderType';

export const NO_COLOUR = 'NO_COLOUR';
export const NO_STYLE_VARIANT = 'NO_STYLE_VARIANT';

export const rowsWithId = (rows?: CartonContentRow[]): CartonContentRow[] => {
  return (rows ?? []).map((x, i): CartonContentRow => {
    return {
      ...x,
      id: x.colour?.split(' ').join('_') + '-' + (x.length ?? '' + i).replace('"', ''),
    };
  });
};
