import React, { ReactElement, useMemo } from 'react';

import { LinearProgress } from '@mui/material';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableRow from '@mui/material/TableRow';

import { useTableState } from '../state/useTableState';

const TableLoader = (): ReactElement => {
  const { state } = useTableState();

  const rowCount = useMemo((): number => {
    let count = state.columns.length;
    if (state.tableOptions.expandable) {
      count++;
    }
    if (state.tableOptions.selectable) {
      count++;
    }
    return count;
  }, [state.columns.length, state.tableOptions.expandable, state.tableOptions.selectable]);

  return (
    <MuiTableBody>
      <MuiTableRow>
        <td colSpan={rowCount}>
          {state.isLoading && <LinearProgress aria-colspan={state.columns.length} />}
        </td>
      </MuiTableRow>
    </MuiTableBody>
  );
};

export default TableLoader;
