import { WebToken } from '_types_/Token';
import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import {
  loginServiceApiBaseUrl,
  PostMapMicrosoftTokenConfig,
} from 'UserAuthentication/_LoginUserServices/loginService';

export const MAP_TOKEN = loginServiceApiBaseUrl('accesstokenprovider/user/mapToken');

export interface MapTokenError extends Error {
  code: string | undefined;
  token: string;
}

export const useMapMicrosoftTokenToProfileToken = (
  token: string,
): UseLazyBestApiTuple<WebToken, PostMapMicrosoftTokenConfig> => {
  return useLazyBestApi<WebToken, PostMapMicrosoftTokenConfig>(MAP_TOKEN, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + token,
      Provider: 'AD',
      'USERS-API-VERSION': '2.0',
    },
    data: {
      grant_type: 'password',
      client_id: 'bff-clientid',
    },
    method: 'POST',
  });
};
