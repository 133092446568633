import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import MultisplitIcon from 'Assets/images/svg/MultiSplit/MultiSplit.svg';

const MultiSplitIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={MultisplitIcon} {...props} viewBox={'0 0 1000 1000'} />
);

export default MultiSplitIcon;
