import { basicAuthToken, clientSecret } from 'Helpers';

import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import {
  loginServiceApiBaseUrl,
  PostCheckCredentialsConfig,
} from 'UserAuthentication/_LoginUserServices/loginService';

type Response = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  adInfo: {
    hasADLogin: boolean;
    adlogin: boolean;
  };
  email: string;
  invite_status: string | undefined;
};

const CHECK_CREDENTIALS = (beBranch?: string) =>
  loginServiceApiBaseUrl('accesstokenprovider/oauth/token', beBranch);

export const useCheckProfileCredentials = (
  username: string,
  password: string,
  beBranch: string | undefined,
): UseLazyBestApiTuple<Response, PostCheckCredentialsConfig> => {
  return useLazyBestApi<Response, PostCheckCredentialsConfig>(CHECK_CREDENTIALS(beBranch), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${basicAuthToken()}`,
    },
    data: {
      username,
      password,
      grant_type: 'password',
      client_secret: clientSecret,
      client_id: 'bff-clientid',
    },
    method: 'POST',
  });
};
