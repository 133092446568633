import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import ExcelSvgIcon from 'Assets/images/svg/csv.svg';

const CsvIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={ExcelSvgIcon} {...props} />
);

export default CsvIcon;
