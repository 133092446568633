import { ReactElement, useEffect } from 'react';
import { Link as NavLink } from 'react-router-dom';

import { NavigateNext } from '@mui/icons-material';
import { Typography, Breadcrumbs, Link } from '@mui/material';

import { useBreadCrumbs } from 'Components/Hooks/useBreadCrumbs';
import navClasses from 'Components/UI/Navigation/Navigation.module.scss';

export const BreadCrumbs = ({ className }: { className?: string }): ReactElement => {
  const breadCrumbs = useBreadCrumbs();

  // NOTE: Updates the page's title (seen in browser tab)
  useEffect(() => {
    if (breadCrumbs[breadCrumbs.length - 1]) {
      document.title = `Vendor Portal - ${breadCrumbs[breadCrumbs.length - 1].label}`;
    } else {
      document.title = `Vendor Portal`;
    }
  }, [breadCrumbs]);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" className={navClasses.breadCrumb} />}
      className={className}
    >
      <div />
      {breadCrumbs.map((crumb, i) => {
        if (breadCrumbs.length - 1 === i || !crumb.to) {
          return (
            <Typography
              className={navClasses.breadCrumb}
              key={`${i}-bc-${crumb.label}`}
              color="textSecondary"
            >
              {crumb.label}
            </Typography>
          );
        }
        return (
          <Link
            className={navClasses.breadCrumb}
            key={`${i}-bc-${crumb.label}`}
            to={crumb.to}
            component={NavLink}
          >
            {crumb.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
