import { ReactElement, ReactNode } from 'react';

import { Typography, TypographyProps, Skeleton } from '@mui/material';

const SkeletonizedTypography = (props: TypographyProps): ReactElement => {
  if (([undefined, null, ''] as ReactNode[]).includes(props.children)) {
    return <Skeleton width={250} />;
  }

  return <Typography {...props}>{props.children}</Typography>;
};

export default SkeletonizedTypography;
