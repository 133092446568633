import { useSelector } from 'react-redux';

import classes from 'Components/UI/Devbar/Devbar.module.scss';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';


export function useGetDatabaseName(): string {
  const user = useSelector((root: RootReducerState) => root.userReducer?.user);
  let dbName = 'N/A';
  if (user && user.dbEnvironment) {
    dbName = user.dbEnvironment.databaseName;
  }
  return dbName;
}

export function useGetDatabaseClass(): string {
  const user = useSelector((root: RootReducerState) => root.userReducer?.user);
  if (!user) {
    return classes.container;
  }
  switch ('Y') {
    case user.dbEnvironment.devEnv:
      return classes.containerDev;
    case user.dbEnvironment.testEnv:
      return classes.containerTest;
    case user.dbEnvironment.prodEnv:
      return classes.containerProd;
    default:
      return classes.container;
  }
}
