import { Suspense } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { USER_MANAGEMENT_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { RouteConfig } from '.';

const UserManagementComponent = lazyImport(() => import('UserManagement'));

export const USER_MANAGEMENT_ROUTES: RouteConfig = {
  label: 'User Management',
  to: USER_MANAGEMENT_TO,
  icon: getIconByIconName('userManagement'),
  color: '#c46ec1',
  component: (
    <Suspense fallback={<LazyLoadingMessage view="User Management" />}>
      <UserManagementComponent />
    </Suspense>
  ),
  featureFlag: 'vp-user-management',
  // permission in backend not implemented yet
};
