import { Component, ReactElement } from 'react';

import { SvgIcon } from '@mui/material';

import Icon from 'Assets/images/svg/3DIcon/3dIcon.svg';

class _3DIcon extends Component {
  render(): ReactElement {
    return <SvgIcon {...this.props} component={Icon} viewBox="0 0 24 24" />;
  }
}

export default _3DIcon;
