import { Collection } from 'Invoice/_types_/SampleInvoiceResponse';
import HeaderEntity from 'Invoice/SampleProforma/_models/Entities/HeaderEntity';
import BrandValueObject from 'Invoice/SampleProforma/_models/ValueObjects/BrandValueObject';
import BuyerContactValueObject from 'Invoice/SampleProforma/_models/ValueObjects/BuyerValueObject';
import CarrierValueObject from 'Invoice/SampleProforma/_models/ValueObjects/CarrierValueObject';
import CurrencyValueObject from 'Invoice/SampleProforma/_models/ValueObjects/CurrencyValueObject';
import FactoryValueObject from 'Invoice/SampleProforma/_models/ValueObjects/FactoryValueObject';
import IncotermsValueObject from 'Invoice/SampleProforma/_models/ValueObjects/IncotermsValueObject';
import MerchandiserOfficeValueObject from 'Invoice/SampleProforma/_models/ValueObjects/MerchandiserOfficeValueObject';
import OriginCriterionValueObject from 'Invoice/SampleProforma/_models/ValueObjects/OriginCriterionValueObject';
import PlaceOfDeliveryValueObject from 'Invoice/SampleProforma/_models/ValueObjects/PlaceOfDeliveryValueObject';
import SupplierValueObject from 'Invoice/SampleProforma/_models/ValueObjects/SupplierValueObject';
import { NameAndId, Variant } from 'Invoice/SampleProforma/_types/OrderLineGroup';
import { SELECT_TYPE } from 'Invoice/SampleProforma/CreateSampleProforma/BasedOnModal/_types';
import {
  LineColor,
  LineInfoGroup,
} from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/LineInfo';

export const RESET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE =
  'RESET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE';
export const SET_SAMPLE_PROFORMA_HEADER_ALL_FIELDS = 'SET_SAMPLE_PROFORMA_HEADER_ALL_FIELDS';

export const SET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE = 'SET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE';
export const SET_SAMPLE_PROFORMA_HEADER_SUPPLIER = 'SET_SAMPLE_PROFORMA_HEADER_SUPPLIER';
export const SET_SAMPLE_PROFORMA_HEADER_MERCHANDISER = 'SET_SAMPLE_PROFORMA_HEADER_MERCHANDISER';
export const SET_SAMPLE_PROFORMA_HEADER_INVOICE_NUMBER =
  'SET_SAMPLE_PROFORMA_HEADER_INVOICE_NUMBER';
export const SET_SAMPLE_PROFORMA_HEADER_FACTORY = 'SET_SAMPLE_PROFORMA_HEADER_FACTORY';
export const SET_SAMPLE_PROFORMA_HEADER_BRAND = 'SET_SAMPLE_PROFORMA_HEADER_BRAND';
export const SET_SAMPLE_PROFORMA_HEADER_BUYER = 'SET_SAMPLE_PROFORMA_HEADER_BUYER';
export const SET_SAMPLE_PROFORMA_HEADER_PLACE_OF_DELIVERY =
  'SET_SAMPLE_PROFORMA_HEADER_PLACE_OF_DELIVERY';
export const SET_SAMPLE_PROFORMA_HEADER_CURRENCY = 'SET_SAMPLE_PROFORMA_HEADER_CURRENCY';
export const SET_SAMPLE_PROFORMA_HEADER_INCOTERMS = 'SET_SAMPLE_PROFORMA_HEADER_INCOTERMS';
export const SET_SAMPLE_PROFORMA_HEADER_ORIGIN_CRITERION =
  'SET_SAMPLE_PROFORMA_HEADER_ORIGIN_CRITERION';
export const SET_SAMPLE_PROFORMA_HEADER_CARRIER = 'SET_SAMPLE_PROFORMA_HEADER_CARRIER';

export const SET_SAMPLE_PROFORMA_HEADER_LINE_INFO = 'SET_SAMPLE_PROFORMA_HEADER_LINE_INFO';
export const ADD_SAMPLE_PROFORMA_HEADER_LINE_INFO = 'ADD_SAMPLE_PROFORMA_HEADER_LINE_INFO';
export const ADD_SAMPLE_PROFORMA_HEADER_LINE_DETAIL_INFO =
  'ADD_SAMPLE_PROFORMA_HEADER_LINE_DETAIL_INFO';
export const UPDATE_SAMPLE_PROFORMA_HEADER_LINE_INFO = 'UPDATE_SAMPLE_PROFORMA_HEADER_LINE_INFO';
export const REMOVE_SAMPLE_PROFORMA_LINE = 'REMOVE_SAMPLE_PROFORMA_LINE';

export const SET_SAMPLE_PROFORMA_FOOTER_TOTAL_QUANTITY =
  'SET_SAMPLE_PROFORMA_FOOTER_TOTAL_QUANTITY';
export const SET_SAMPLE_PROFORMA_FOOTER_NET_WEIGHT = 'SET_SAMPLE_PROFORMA_FOOTER_NET_WEIGHT';
export const SET_SAMPLE_PROFORMA_FOOTER_GROSS_WEIGHT = 'SET_SAMPLE_PROFORMA_FOOTER_GROSS_WEIGHT';
export const SET_SAMPLE_PROFORMA_HEADER_META_REBALANCE =
  'SET_SAMPLE_PROFORMA_HEADER_META_REBALANCE';

export const UPDATE_DETAILS_PRICE_PR_PCS = 'UPDATE_DETAILS_PRICE_PR_PCS';
export type UpdateDetailsPricePrPcs = {
  type: typeof UPDATE_DETAILS_PRICE_PR_PCS;
  groupId: string;
  lineId: string;
  value: number | undefined;
  hasError: boolean;
};
export const UPDATE_DETAILS_QUANTITY = 'UPDATE_DETAILS_QUANTITY';
export type UpdateDetailsQuantity = {
  type: typeof UPDATE_DETAILS_QUANTITY;
  groupId: string;
  lineId: string;
  value: number | undefined;
  hasError: boolean;
};

export const SET_DETAILS_COMMENT = 'SET_DETAILS_COMMENT';
export type SetDetailsComment = {
  type: typeof SET_DETAILS_COMMENT;
  groupId: string;
  lineId: string;
  value: string | undefined;
};

export const UPDATE_DETAILS_COLLECTION = 'UPDATE_DETAILS_COLLECTION';
export type UpdateDetailsCollection = {
  type: typeof UPDATE_DETAILS_COLLECTION;
  groupId: string;
  lineId: string;
  value: (Collection & { from: SELECT_TYPE }) | undefined;
};
export const UPDATE_DETAILS_COLOR = 'UPDATE_DETAILS_COLOR';
export type UpdateDetailsColor = {
  type: typeof UPDATE_DETAILS_COLOR;
  groupId: string;
  lineId: string;
  value: LineColor | undefined;
};
export const UPDATE_DETAILS_VARIANT = 'UPDATE_DETAILS_VARIANT';
export type UpdateDetailsVariant = {
  type: typeof UPDATE_DETAILS_VARIANT;
  groupId: string;
  lineId: string;
  value: Variant | undefined;
};

export const UPDATE_DETAILS_SIZE = 'UPDATE_DETAILS_SIZE';
export type UpdateDetailsSize = {
  type: typeof UPDATE_DETAILS_SIZE;
  groupId: string;
  lineId: string;
  value: NameAndId | undefined;
};

export type ResetProformaHeaderInitialState = {
  type: typeof RESET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE;
};
export type SampleProformaHeaderInitialState = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_INITIAL_STATE;
  dataType: SELECT_TYPE;
  initialState: HeaderEntity | undefined;
};
export type SetSampleProformaHeaderSupplier = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_SUPPLIER;
  supplier: SupplierValueObject | undefined;
};
export type SetSampleProformaHeaderMerchandiser = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_MERCHANDISER;
  merchandiser: MerchandiserOfficeValueObject | undefined;
};
export type SetSampleProformaHeaderInvoiceNumber = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_INVOICE_NUMBER;
  value: string | undefined;
};
export type SetSampleProformaHeaderFactory = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_FACTORY;
  value: FactoryValueObject | undefined;
};
export type SetSampleProformaHeaderBrand = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_BRAND;
  value: BrandValueObject | undefined;
};
export type SetSampleProformaHeaderBuyer = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_BUYER;
  value: BuyerContactValueObject | undefined;
};
export type SetSampleProformaHeaderPlaceOfDelivery = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_PLACE_OF_DELIVERY;
  value: PlaceOfDeliveryValueObject | undefined;
};
export type SetSampleProformaHeaderCurrency = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_CURRENCY;
  value: CurrencyValueObject | undefined;
};
export type SetSampleProformaHeaderIncoterms = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_INCOTERMS;
  value: IncotermsValueObject | undefined;
};
export type SetSampleProformaHeaderOriginCriterion = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_ORIGIN_CRITERION;
  value: OriginCriterionValueObject | undefined;
};
export type SetSampleProformaHeaderLineInfo = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_LINE_INFO;
  headerInfo: HeaderEntity | undefined;
  lineInfoGroup: LineInfoGroup[];
  dataType: SELECT_TYPE;
  groupName: string | undefined;
};
export type AddSampleProformaHeaderLineInfo = {
  type: typeof ADD_SAMPLE_PROFORMA_HEADER_LINE_INFO;
  lineInfoGroup: LineInfoGroup[];
};

export type AddSampleProformaHeaderLineDetailInfo = {
  type: typeof ADD_SAMPLE_PROFORMA_HEADER_LINE_DETAIL_INFO;
  groupId: string;
  from: SELECT_TYPE;
};

export type UpdateSampleProformaHeaderLineInfo<K extends keyof LineInfoGroup> = {
  type: typeof UPDATE_SAMPLE_PROFORMA_HEADER_LINE_INFO;
  lineId: string;
  key: K;
  value: LineInfoGroup[K];
};

export type SetSampleProformaHeaderCarrier = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_CARRIER;
  value: CarrierValueObject | undefined;
};

export type RemoveSampleProformaLineGroup = {
  type: typeof REMOVE_SAMPLE_PROFORMA_LINE;
  lineId: string;
  fromDetails: false;
};

export type RemoveSampleProformaLineDetails = {
  type: typeof REMOVE_SAMPLE_PROFORMA_LINE;
  lineId: string;
  fromDetails: true;
  groupId: string;
};

export type SetSampleProformaFooterTotalQuantity = {
  type: typeof SET_SAMPLE_PROFORMA_FOOTER_TOTAL_QUANTITY;
  value: number | undefined;
};
export type SetSampleProformaFooterNetWeight = {
  type: typeof SET_SAMPLE_PROFORMA_FOOTER_NET_WEIGHT;
  value: number | undefined;
};
export type SetSampleProformaFooterGrossWeight = {
  type: typeof SET_SAMPLE_PROFORMA_FOOTER_GROSS_WEIGHT;
  value: number | undefined;
};

export type SampleProformaHeaderAllFields = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_ALL_FIELDS;
  headerInfo: HeaderEntity | undefined;
};

export type SetSampleProformaHeaderMetaRebalance = {
  type: typeof SET_SAMPLE_PROFORMA_HEADER_META_REBALANCE;
  value: boolean;
};

export type SampleProformaHeaderActions<K extends keyof LineInfoGroup> =
  | ResetProformaHeaderInitialState
  | SampleProformaHeaderInitialState
  | SetSampleProformaHeaderSupplier
  | SetSampleProformaHeaderMerchandiser
  | SetSampleProformaHeaderInvoiceNumber
  | SetSampleProformaHeaderFactory
  | SetSampleProformaHeaderBrand
  | SetSampleProformaHeaderBuyer
  | SetSampleProformaHeaderPlaceOfDelivery
  | SetSampleProformaHeaderCurrency
  | SetSampleProformaHeaderIncoterms
  | SetSampleProformaHeaderOriginCriterion
  | SetSampleProformaHeaderLineInfo
  | AddSampleProformaHeaderLineInfo
  | SetSampleProformaHeaderCarrier
  | RemoveSampleProformaLineGroup
  | RemoveSampleProformaLineDetails
  | UpdateSampleProformaHeaderLineInfo<K>
  | SetSampleProformaFooterTotalQuantity
  | SetSampleProformaFooterNetWeight
  | SetSampleProformaFooterGrossWeight
  | SampleProformaHeaderAllFields
  | AddSampleProformaHeaderLineDetailInfo
  | UpdateDetailsPricePrPcs
  | UpdateDetailsQuantity
  | UpdateDetailsCollection
  | UpdateDetailsColor
  | UpdateDetailsSize
  | UpdateDetailsVariant
  | SetDetailsComment
  | SetSampleProformaHeaderMetaRebalance;
