import { TableState } from '../_types_/TableState';

export const SET_SHOULD_TABLE_COLLAPSE = 'SET_SHOULD_TABLE_COLLAPSE';

export interface SetShouldTableCollapse {
  type: typeof SET_SHOULD_TABLE_COLLAPSE;
  shouldCollapse: boolean;
}

export const doSetShouldTableCollapse = (shouldCollapse: boolean): SetShouldTableCollapse => ({
  type: SET_SHOULD_TABLE_COLLAPSE,
  shouldCollapse,
});

export const applySetShouldTableCollapse = (
  newState: TableState,
  action: SetShouldTableCollapse
): TableState => {
  newState.shouldCollapse = action.shouldCollapse;

  return newState;
};
