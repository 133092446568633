import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from 'Config/adAuthConfig';
import { useLoginUser } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useLoginUser';
import { errorHandler } from 'UserAuthentication/Login/LoginButton/helpers/errorHandler';

type automaticSignInState = 'COMPLETED' | 'PENDING' | 'NOT_STARTED' | 'FAILED';

interface Props {
  beBranch?: string;
  redirectToHome?: boolean;
}

const useAutoLogin = (props?: Props) => {
  const navigate = useNavigate();

  const { instance } = useMsal();
  const account = useAccount();

  const [microsoftToken, setMicrosoftToken] = useState<string | undefined>();
  const [microsoftAccessToken, setMicrosoftAccessToken] = useState<string | undefined>();

  const { user, errors } = useLoginUser(microsoftToken, microsoftAccessToken);

  const [hasError, setHasError] = useState(false);
  const [automaticSignInState, setAutomaticSignInState] =
    useState<automaticSignInState>('NOT_STARTED');

  useEffect(() => {
    if (errors.tokenError !== undefined || errors.profileError !== undefined) {
      setAutomaticSignInState('FAILED');
    }
    if (!account) {
      setAutomaticSignInState('COMPLETED');
    }
  }, [errors.profileError, errors.tokenError, account]);

  const tryToAutomaticallySignIn = useCallback(async () => {
    if (hasError || automaticSignInState === 'FAILED') {
      return;
    }

    if (automaticSignInState !== 'NOT_STARTED') {
      return;
    }

    if (account === null) {
      setAutomaticSignInState('COMPLETED');
      return;
    }

    setAutomaticSignInState('PENDING');

    try {
      const adResponse = await instance.acquireTokenSilent({ ...loginRequest, account });
      try {
        setMicrosoftAccessToken(adResponse.accessToken);
        setMicrosoftToken(adResponse.idToken);
        if (props?.redirectToHome && user) {
          navigate('/');
        }
        if (errors.tokenError !== undefined || errors.profileError !== undefined) {
          setHasError(true);
          setAutomaticSignInState('FAILED');
          errorHandler(errors.tokenError);
        }
        if (user && errors.tokenError === undefined && errors.profileError === undefined) {
          setAutomaticSignInState('COMPLETED');
        }
      } catch (e) {
        setHasError(true);
        setAutomaticSignInState('FAILED');
        errorHandler(e);
      }
    } catch (e) {
      setHasError(true);
      setAutomaticSignInState('FAILED');
      errorHandler(e);
    }
  }, [
    account,
    automaticSignInState,
    errors.profileError,
    errors.tokenError,
    hasError,
    instance,
    navigate,
    props?.redirectToHome,
    user,
  ]);

  return {
    tryToAutomaticallySignIn,
    automaticSignInState,
  };
};

export default useAutoLogin;
