import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import { Box, Button, Paper, Typography } from '@mui/material';

import EmailForm from 'UserAuthentication/Login/LoginScreens/EmailForm';

type Props = {
  invitedStatus: string | undefined;
  email: string;
  goToWelcome: () => void;
  token: string;
};

const AlreadyInvited = (props: Props): ReactElement => {
  const isInvitedStatus = useMemo(() => {
    return props.invitedStatus === 'Invited' || props.invitedStatus === 'Invite Error : ';
  }, [props.invitedStatus]);

  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);

  const handleGoToWelcome = useCallback(() => {
    props.goToWelcome();
  }, [props]);

  const handleGoToEmailForm = useCallback(() => {
    setShowEmailForm(true);
  }, []);

  const titleMessage = useMemo(() => {
    return isInvitedStatus
      ? 'Complete the creation of Microsoft account'
      : 'Sign in with Microsoft already available';
  }, [isInvitedStatus]);

  const bodyMessage = useMemo(() => {
    return isInvitedStatus
      ? `We have detected that an invitation has been already sent to ${props.email}. Check your mailbox and follow the instructions to complete the creation of your Microsoft account. If you prefer to use a different email, please click on the button CHANGE EMAIL`
      : 'We have detected that your user is already able to access to Vendor Portal using our new Microsoft login system. Please, go back to login screen and use LOG IN WITH MICROSOFT button.';
  }, [isInvitedStatus, props.email]);

  if (showEmailForm) {
    return (
      <EmailForm token={props.token} goToWelcome={handleGoToWelcome} isInvited={isInvitedStatus} />
    );
  }

  return (
    <Paper elevation={0} sx={{ height: '300px', width: '700px', padding: 5 }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        textAlign="justify"
      >
        <Typography fontSize={28} fontWeight={700} mb={1}>
          {titleMessage}
        </Typography>
        <Typography mb={1}>{bodyMessage}</Typography>
        <Box display="flex" columnGap={2} justifyContent="end">
          {isInvitedStatus && (
            <Button variant="outlined" onClick={handleGoToEmailForm}>
              Change email
            </Button>
          )}
          <Button variant="outlined" onClick={handleGoToWelcome}>
            Go back to login screen
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default AlreadyInvited;
