import { TableState } from '../_types_/TableState';

export const SET_PRIMARY_KEY = 'SET_PRIMARY_KEY';

export interface SetPrimaryKey {
  type: typeof SET_PRIMARY_KEY;
  primaryKey: string;
}

export const doSetPrimaryKey = (primaryKey: string): SetPrimaryKey => ({
  type: SET_PRIMARY_KEY,
  primaryKey: primaryKey,
});

export const applySetPrimaryKey = (newState: TableState, action: SetPrimaryKey): TableState => {
  newState.primaryKey = action.primaryKey;
  return newState;
};
