import { ReactElement } from 'react';

import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';

import { useHasPermission } from 'Models/Permission/permissionHelper';

import { BaseButtonProps } from './_types_/index';
import classes from './BaseButton.module.scss';

export * from './_types_';

const propsShapeClassTranslator = (shape: string): string => {
  switch (shape) {
    case 'circle':
      return classes.circle;
    case 'none':
      return '';
    case 'square':
    default:
      return classes.square;
  }
};
const propsTypeClassTranslator = (type: string): string => {
  switch (type) {
    case 'cancel':
      return classes.cancel;
    case 'active':
      return classes.active;
    case 'hidden':
      return classes.hidden;
    case 'outlined':
      return classes.outlined;
    case 'ok':
    default:
      return classes.ok;
  }
};

const BaseButton = (props: BaseButtonProps & LoadingButtonProps): ReactElement => {
  const {
    buttonType,
    buttonShape,
    securityType,
    permission,
    disableButton,
    noMargin,
    padding,
    noMinWidth,
    component,
    flex,
    lineHeight,
    ...rest
  } = props;
  let securityApplied = false;
  const hasPermission = useHasPermission();
  permission ? (securityApplied = !hasPermission(permission)) : (securityApplied = false);

  if (props.hidden) {
    return <></>;
  }

  const getButtonTypeClass = (): string => {
    if (securityApplied) {
      return propsTypeClassTranslator(securityType ? securityType : 'hidden');
    } else {
      return propsTypeClassTranslator(buttonType ? buttonType : 'ok');
    }
  };

  return (
    <Tooltip title={props.tooltip ? props.tooltip : ''}>
      <span
        style={{
          display: component === 'flex' ? 'flex' : '',
          flex: flex ? flex : '',
        }}
      >
        <LoadingButton
          {...rest}
          size={props.size ? props.size : 'medium'}
          variant={props.variant ?? 'contained'}
          disabled={disableButton || (securityApplied && securityType === 'disabled')}
          className={[
            props.className,
            propsShapeClassTranslator(buttonShape ? buttonShape : 'square'),
            classes.baseButton,
            noMargin ? classes.noMargin : null,

            noMinWidth ? classes.noMinWidth : null,
            getButtonTypeClass(),
          ].join(' ')}
          style={Object.assign(
            {},
            props.style ?? {},
            lineHeight && { lineHeight: lineHeight },
            padding && { padding: padding }
          )}
        >
          {props.children}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

export default BaseButton;
