import { combineReducers } from 'redux';

import dataCacheState from './dataCache.reducer';
import devSettingsState from './devSettings.reducer';
import { dirtybit } from './dirtybit.reducer';
import documentsState from './documents/documents.reducer';
import newsReducer from './news.reducer';
import ordersState from './orders.reducer';
import suspenseState from './suspense.reducer';
import tableReducer from './table.reducer';
import errorReducer from './toast.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  newsReducer,
  dirtybit,
  errorReducer,
  suspenseState,
  tableReducer,
  dataCacheState,
  devSettingsState,
  documentsState,
  userReducer,
  ordersState,
});

export default rootReducer;
