import { areAllValuesTheSame } from 'Helpers/util';
import {
  LineInfoGroup,
  LineInfoGroupDetail,
} from 'Invoice/SampleProforma/CreateSampleProforma/SampleProformaCreationLine/_types/LineInfo';

export type ExtendedLineInfoGroup = Omit<LineInfoGroup, 'lines'> & {
  lineMap?: Map<string, LineInfoGroupDetail>;
};

/**
 * Converts a group to the extended format with a map of line info group details.
 * The map is created from the lines in the group, with the lineId as the key and the line as the value.
 *
 * @param {LineInfoGroup} group - The group to convert.
 * @returns {ExtendedLineInfoGroup} - Returns the group in the extended format.
 */
const convertGroupToExtendedFormat = (group: LineInfoGroup): ExtendedLineInfoGroup => {
  const lineMap = new Map(group.lines.map((line) => [line.lineId, line]));
  return { ...group, lineMap };
};

/**
 * Merges the lines of a new group into an existing group.
 * If a line in the new group already exists in the existing group (based on lineId), it is not added.
 * If a line in the new group doesn't exist in the existing group, it is added.
 * The lines in the merged group are sorted by lineNumber.
 *
 * @param {ExtendedLineInfoGroup} existingGroup - The existing group to merge lines into.
 * @param {LineInfoGroup} newGroup - The new group whose lines are to be merged.
 * @returns {ExtendedLineInfoGroup} - Returns the existing group with the merged lines.
 */
const mergeLinesIntoGroup = (
  existingGroup: ExtendedLineInfoGroup,
  newGroup: LineInfoGroup
): ExtendedLineInfoGroup => {
  const lineMap =
    existingGroup.lineMap !== undefined
      ? new Map(existingGroup.lineMap)
      : new Map<string, LineInfoGroupDetail>();
  newGroup.lines.forEach((line) => {
    if (!lineMap.has(line.lineId)) {
      lineMap.set(line.lineId, line);
    }
  });

  return { ...existingGroup, lineMap };
};

/**
 * Checks if the given array of lines is sorted by lineNumber.
 *
 * @param {LineInfoGroupDetail[]} lines - The array of lines to check.
 * @returns {boolean} - Returns true if the lines are sorted, false otherwise.
 */
const isSorted = (lines: LineInfoGroupDetail[]): boolean => {
  return lines.every(
    (line, i, arr) => i === 0 || (arr[i - 1].lineNumber ?? 0) <= (line.lineNumber ?? 0)
  );
};

/**
 * Sorts the given array of lines by lineNumber.
 *
 * @param {LineInfoGroupDetail[]} lines - The array of lines to sort.
 * @returns {LineInfoGroupDetail[]} - Returns the sorted array of lines.
 */
const sortLines = (lines: LineInfoGroupDetail[]): LineInfoGroupDetail[] => {
  return lines.sort((a, b) => (a.lineNumber ?? 0) - (b.lineNumber ?? 0));
};

/**
 * Assigns a proforma line number to each line in the given array of lines.
 * The proforma line number is the index of the line in the array plus one.
 *
 * @param {LineInfoGroupDetail[]} lines - The array of lines to assign proforma line numbers to.
 * @returns {LineInfoGroupDetail[]} - Returns the array of lines with assigned proforma line numbers.
 */
const assignProformaLineNumber = (lines: LineInfoGroupDetail[]): LineInfoGroupDetail[] => {
  return lines.map((x, i) => ({ ...x, proformaLineNumber: '' + (i + 1) }));
};

/**
 * This function is used to fix the proforma line number in a group of lines.
 * It first checks if the lines are already sorted. If they are, it directly assigns proforma line numbers.
 * If they are not sorted, it sorts them first and then assigns proforma line numbers.
 *
 * @param {LineInfoGroupDetail[]} details - The array of line details.
 * @returns {LineInfoGroupDetail[]} - The array of line details with fixed proforma line numbers.
 */
export const fixProformaLineNumber = (details: LineInfoGroupDetail[]): LineInfoGroupDetail[] => {
  return assignProformaLineNumber(isSorted(details) ? details : sortLines(details));
};

/**
 * Merges new line info groups into the current line info groups.
 * If a group in the new line info groups already exists in the current line info groups,
 * the lines of the new group are merged into the existing group.
 * If a group in the new line info groups doesn't exist in the current line info groups,
 * the new group is added to the current line info groups.
 * The lines in each group are sorted by lineNumber and assigned a proforma line number.
 *
 * @param {LineInfoGroup[]} currentLineInfoGroup - The current line info groups.
 * @param {LineInfoGroup[]} newLineInfoGroup - The new line info groups to merge.
 * @returns {LineInfoGroup[]} - Returns the merged line info groups.
 */
export const addNewLines = (
  currentLineInfoGroup: LineInfoGroup[],
  newLineInfoGroup: LineInfoGroup[]
): LineInfoGroup[] => {
  // Initialize a map to store groups by their order number
  const groupMap = new Map<string, ExtendedLineInfoGroup>();

  // Convert the current groups to the extended format and add them to the map
  (currentLineInfoGroup ?? []).forEach((group) => {
    const extendedGroup = convertGroupToExtendedFormat(group);
    groupMap.set(group.groupId, extendedGroup);
  });

  // Merge the new groups into the existing ones in the map
  newLineInfoGroup.forEach((group) => {
    const existingGroup = groupMap.get(group.groupId);

    if (existingGroup) {
      // If the group already exists, merge the lines
      const mergedGroup = mergeLinesIntoGroup(existingGroup, group);
      const patchedLineMap = Array.from(mergedGroup.lineMap?.values() ?? []).map((line) => ({
        ...line,
        quantity: line.quantity?.value ? line.quantity : existingGroup.quantity,
        pricePrPcs: line.pricePrPcs?.value ? line.pricePrPcs : existingGroup.pricePrPcs,
      }));
      mergedGroup.lineMap = new Map(patchedLineMap.map((line) => [line.lineId, line]));

      groupMap.set(group.groupId, mergedGroup);
    } else {
      // If the group doesn't exist, add it to the map
      const extendedGroup = convertGroupToExtendedFormat(group);
      groupMap.set(group.groupId, extendedGroup);
    }
  });

  // Converts each group's lineMap to an array, sorts the lines if necessary, assigns proforma line numbers, and returns the updated groups.
  return Array.from(groupMap.values()).map((group) => {
    const lines = Array.from(group.lineMap?.values() ?? []);
    if (newLineInfoGroup.length > 0 && group.groupId === newLineInfoGroup[0].groupId) {
      if (group.quantity !== undefined) {
        const isMixedQuantity = !areAllValuesTheSame(lines, 'quantity', 'value');
        group.quantity.mixed = isMixedQuantity;
      }
      if (group.pricePrPcs !== undefined) {
        const isMixedPrice = !areAllValuesTheSame(lines, 'pricePrPcs', 'value');
        group.pricePrPcs.mixed = isMixedPrice;
      }
    }
    return {
      ...group,
      lines: fixProformaLineNumber(lines),
    };
  });
};
