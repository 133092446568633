export type EditNewsConstType = 'MODE_EDIT' | 'MODE_CREATE' | 'MODE_DELETE' | 'MODE_DEFAULT';

export const FETCH_NEWS_ACTION = 'fetch_news';
export const FETCH_NEWS_TYPES_ACTION = 'fetch_news_types';
export const ADD_FRESH_NEWS_ACTION = 'add_fresh_news';
export const UPDATE_EXISTING_NEWS_ACTION = 'update_existing_news';
export const DELETE_NEWS_ACTION = 'delete_news';
export const MODE_EDIT: EditNewsConstType = 'MODE_EDIT';
export const MODE_CREATE: EditNewsConstType = 'MODE_CREATE';
export const MODE_DELETE: EditNewsConstType = 'MODE_DELETE';
export const MODE_DEFAULT: EditNewsConstType = 'MODE_DEFAULT';
