import { TableState } from '../_types_/TableState';

export const SET_SHOULD_APPLY_FILTERS = 'SET_SHOULD_APPLY_FILTERS';

export interface SetShouldApplyFilters {
  type: typeof SET_SHOULD_APPLY_FILTERS;
  shouldApplyFilters: boolean;
}

export const doSetShouldApplyFilters = (shouldApplyFilters: boolean): SetShouldApplyFilters => ({
  type: SET_SHOULD_APPLY_FILTERS,
  shouldApplyFilters: shouldApplyFilters,
});

export const applySetShouldApplyFilters = (
  newState: TableState,
  action: SetShouldApplyFilters
): TableState => {
  newState.shouldApplyFilters = action.shouldApplyFilters;

  return newState;
};
