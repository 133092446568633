import { areAllValuesTheSame } from 'Helpers/util';
import { UpdateDetailsQuantity } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const updateDetailsQuantity = (
  updatedState: SampleProformaHeaderState,
  action: UpdateDetailsQuantity
): SampleProformaHeaderState => {
  return {
    ...updatedState,
    lineInfoGroup: updatedState.lineInfoGroup.map((group) => {
      if (group.groupId === action.groupId) {
        const updatesLines = [
          ...group.lines.map((line) => {
            if (line.lineId === action.lineId) {
              return {
                ...line,
                quantity: {
                  value: action.value,
                  mixed: line.quantity?.mixed ?? false,
                  hasError: action.hasError,
                  maxValue: line.quantity?.maxValue,
                },
              };
            }
            return line;
          }),
        ];

        const isMixed = !areAllValuesTheSame(updatesLines, 'quantity', 'value');

        return {
          ...group,
          quantity: {
            value: isMixed ? undefined : updatesLines[0].quantity?.value,
            mixed: isMixed,
            hasError: action.hasError,
            maxValue: updatesLines[0].quantity?.maxValue,
          },
          lines: updatesLines,
        };
      }
      return group;
    }),
  };
};
