import { TableOptions } from '../_types_/TableOptions';
import { TableState } from '../_types_/TableState';

export const DEFAULT_ROWS_PER_PAGE = [10, 20, 50, 100];

export const SET_TABLE_OPTIONS = 'SET_TABLE_OPTIONS';

export interface SetTableOptionsAction {
  type: typeof SET_TABLE_OPTIONS;
  options: TableOptions;
}

export const doSetTableOptions = ({
  toolbar = true,
  stickyHeader = true,
  paper = true,
  selectable = false,
  selectAll = false,
  searchBar = false,
  disableRefetchButton = false,
  responsive = true,
  showTableName = true,
  responsiveBreakpoint,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE,
  expandable = false,
  draggable = true,
  dontAddExpandToUrl = false,
  showExpandAllButton = false,
}: Partial<TableOptions>): SetTableOptionsAction => ({
  type: SET_TABLE_OPTIONS,
  options: {
    toolbar,
    stickyHeader,
    selectable,
    searchBar,
    selectAll,
    paper,
    disableRefetchButton,
    responsive,
    responsiveBreakpoint,
    rowsPerPageOptions,
    expandable,
    showTableName,
    draggable,
    dontAddExpandToUrl,
    showExpandAllButton,
  },
});

export const applySetTableOptions = (
  newState: TableState,
  action: SetTableOptionsAction
): TableState => {
  newState.tableOptions = action.options;

  return newState;
};
