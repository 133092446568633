import { ReactElement } from 'react';

import classes from './DateDisplay.module.scss';
type DateType = 'DateOnly' | 'MonthOnly' | 'YearOnly' | 'FullDate' | 'toLocaleString';
type componentType = 'span' | 'div' | 'fragment';
interface PropType {
  date: Date | undefined;
  type?: DateType;
  componentType?: componentType;
}

export const displayDate = (type: DateType, date?: Date): string => {
  if (!date) {
    return ' - ';
  } else {
    switch (type) {
      case 'DateOnly':
        return '' + date.getDate();
      case 'MonthOnly':
        return date.toLocaleString('en-us', { month: 'long' });
      case 'YearOnly':
        return '' + date.getFullYear();
      case 'toLocaleString': {
        return '' + date.toLocaleString();
      }
      case 'FullDate':
      default:
        return date.toLocaleDateString();
    }
  }
};

const DateDisplay = ({
  componentType = 'span',
  type = 'FullDate',
  ...props
}: PropType): ReactElement<PropType> => {
  switch (componentType) {
    case 'span':
      return (
        <span className={props.date ? '' : classes.noValue}>{displayDate(type, props.date)}</span>
      );
    case 'div':
      return (
        <div className={props.date ? '' : classes.noValue}>{displayDate(type, props.date)}</div>
      );
    case 'fragment':
    default:
      return <>{displayDate(type, props.date)}</>;
  }
};

export default DateDisplay;
