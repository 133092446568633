import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';

import { CAMPAIGN_KEY } from 'App/Campaign';
import BaseButton from 'Components/Shared/BestButton/BaseButton';
import { customConfirm } from 'Components/Shared/ConfirmDialog';
import DevbarButton from 'Components/UI/Devbar/widgets/DevbarButton';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

const LocalStorage = (): ReactElement => {
  const userKey = useSelector((root: RootReducerState): UserReducerType => root.userReducer).user
    ?.userIdHash;
  return (
    <DevbarButton label="LocalStorage">
      <Paper
        sx={{
          padding: 4,
        }}
      >
        <BaseButton
          onClick={(): void => {
            if (userKey === undefined) {
              alert('No settings for user');
              return;
            }
            const stored = localStorage.getItem(CAMPAIGN_KEY);
            if (stored === null) {
              alert('No settings found');
              return;
            }
            const parsed = JSON.parse(stored);
            if (!parsed) {
              alert('Error parsing settings');
              return;
            }
            delete parsed[userKey];
            if (Object.keys(parsed).length === 0) {
              localStorage.removeItem(CAMPAIGN_KEY);
            } else {
              localStorage.setItem(CAMPAIGN_KEY, JSON.stringify(parsed));
            }
            customConfirm(
              <div>
                <p>You&apos;ve deleted the user&apos;s campaign settings</p>
                <p>Do you want to reload the page?</p>
              </div>
            ).then((result) => {
              if (result) {
                window.location.reload();
              }
            });
          }}
        >
          Delete campaign storage for user
        </BaseButton>
      </Paper>
    </DevbarButton>
  );
};

export default LocalStorage;
