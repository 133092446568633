import { useState, useMemo, useEffect } from 'react';

import { Alert, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { datadogRum } from '@datadog/browser-rum';
import {
  useMinReleaseDateQuery,
  useMinReleaseChangedSubscription,
} from 'generated/releasetool_graphql';

const PromptToRefresh = () => {
  const [showReloadSuggestionAlert, setShowReloadSuggestionAlert] = useState(false);

  const { data: minReleaseData, loading, error } = useMinReleaseDateQuery();

  const { data: minReleaseDataSubscription } = useMinReleaseChangedSubscription();

  const userBuildTime = useMemo((): Date => {
    const dateAsString = (datadogRum.getUser().buildTime as string) || '';
    return new Date(dateAsString);
  }, []);

  const minReleaseDate = useMemo((): Date | undefined => {
    if (loading || error) {
      return undefined;
    }
    const date = minReleaseData?.minReleaseDate.date as string;
    const subscriptionDate = minReleaseDataSubscription?.minReleaseDate?.date as string;
    if (!date) {
      return undefined;
    }
    if (subscriptionDate) {
      return new Date(subscriptionDate);
    }
    return new Date(date);
  }, [
    loading,
    error,
    minReleaseData?.minReleaseDate.date,
    minReleaseDataSubscription?.minReleaseDate?.date,
  ]);
  useEffect(() => {
    if (minReleaseDate && userBuildTime && minReleaseDate > userBuildTime) {
      setShowReloadSuggestionAlert(true);
      return;
    }
    setShowReloadSuggestionAlert(false);
  }, [minReleaseDate, userBuildTime]);

  if (!showReloadSuggestionAlert) {
    return <></>;
  }
  return (
    <Grid container flexDirection="column" justifyContent="center" rowGap={1} mb={2}>
      {showReloadSuggestionAlert && (
        <Grid>
          <Alert
            severity="info"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  history.pushState(null, '', window.location.href);
                  history.go(0);
                }}
              >
                RELOAD
              </Button>
            }
          >
            You are using an older version of the Vendor Portal. Please reload the page to access
            the updated version. You can do this by clicking the 'Reload' button next to this
            message or pressing the reload icon in your browser.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default PromptToRefresh;
