import { TableSortOptions } from 'Components/Table/state/_types_/TableSortOptions';

import { TableState } from '../_types_/TableState';

export const SET_TABLE_SORT = 'SET_TABLE_SORT';

export interface SetTableSortAction {
  type: typeof SET_TABLE_SORT;
  sort: TableSortOptions[];
}

export const doSetTableSort = (sort: TableSortOptions[]): SetTableSortAction => ({
  type: SET_TABLE_SORT,
  sort,
});

export const applySetTableSort = (newState: TableState, action: SetTableSortAction): TableState => {
  newState.sort = action.sort;

  return newState;
};
