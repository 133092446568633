import { TableState } from '../_types_/TableState';

export const CLEAR_FILTER = 'CLEAR_FILTER';

export type ClearFilter = {
  type: typeof CLEAR_FILTER;
  property?: string;
};

export const doClearFilter = (property?: string): ClearFilter => ({
  type: CLEAR_FILTER,
  property,
});

export const applyClearFilter = (newState: TableState, action: ClearFilter): TableState => {
  const { property } = action;
  const getEmptyValue = (type: 'date' | 'selectOne' | 'select' | 'searchableSelect') => {
    switch (type) {
      case 'date':
        return [null, null];
      case 'selectOne':
        return null;
      case 'select':
      case 'searchableSelect':
        return [];
      default:
        return null;
    }
  };

  if (!property) {
    Object.keys(newState.filters).forEach((key) => {
      const filter = newState.filters[key];
      if (filter) {
        newState.filters[key] = {
          ...filter,
          value: getEmptyValue(filter.type),
        } as typeof filter;
      }
    });
    return newState;
  }

  const filterToClear = newState.filters[property];
  if (!filterToClear) {
    return newState;
  }

  newState.filters[property] = {
    ...newState.filters[property],
    value: getEmptyValue(filterToClear.type),
    // hack necessary cause typescript is being typescript
  } as typeof newState.filters[typeof property];
  return newState;
};
