import { ReactElement, useState } from 'react';

import { Alert, Typography } from '@mui/material';

import useCampaignConfigHandler from 'App/Campaign/useCampaignConfigHandler';
import { CAMPAIGN_SETTINGS_KEY } from 'Constants/user.constants';

import CampaignDialog from './CampaignDialog';

export const CAMPAIGN_KEY = CAMPAIGN_SETTINGS_KEY('earthquake2023');

const CAMPAIGN_TITLE = 'BESTSELLER is supporting Turkey and Syria.';
const CAMPAIGN_LINK_TEXT = 'Click here to learn how to help.';

const Campaign = (): ReactElement => {
  const [showDialog, setShowDialog] = useState(false);
  const { show, handleHide, userLoggedIn } = useCampaignConfigHandler({ CAMPAIGN_KEY });
  if (!show) {
    return <></>;
  }

  return (
    <>
      <CampaignDialog
        title={CAMPAIGN_TITLE}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />
      <Alert
        variant="filled"
        severity="info"
        onClose={userLoggedIn ? handleHide : undefined}
        sx={{ marginBottom: '8px', marginTop: '-16px' }}
      >
        <Typography component="span">{CAMPAIGN_TITLE}&nbsp;</Typography>
        <Typography
          component="span"
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setShowDialog(true)}
        >
          {CAMPAIGN_LINK_TEXT}
        </Typography>
      </Alert>
    </>
  );
};

export default Campaign;
