import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const getMyProfilePicture = async (
  accessToken: string,
  size?: 48 | 64 | 96 | 120 | 240 | 360 | 432 | 504 | 648,
): Promise<string | undefined> => {
  const photoTarget = size ? `photos/${size}x${size}` : 'photo';
  const url = `https://graph.microsoft.com/v1.0/me/${photoTarget}/$value`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'blob',
  };

  return axios
    .get(url, config)
    .then((response: AxiosResponse | undefined) => {
      if (!response) {
        return;
      }
      return (window.URL || window.webkitURL).createObjectURL(response.data);
    })
    .catch((error: AxiosError): undefined => {
      if (error.response?.status === 404) {
        return undefined;
      }
      console.error(error);
      return undefined;
    });
};
