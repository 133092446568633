import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import App from 'App';
import { properties, store } from 'Helpers';
import { withLDProvider, LDEvaluationDetail } from 'launchdarkly-react-client-sdk';
import { ThemeWrapper } from 'Theme';

import { datadogRum } from '@datadog/browser-rum';
import { msalConfig } from 'Config/adAuthConfig';
import AuthenticationWrapper from 'Contexts/adAuthProvider';
import { DataDogRumProvider } from 'Contexts/DataDogRumContext';
import { GraphQLProvider } from 'Contexts/GraphQLProvider';
import { ldFlagsMapper } from 'FeatureFlags/ldFlagsMapper';
import { createRoot } from 'react-dom/client';

import { AuthHandlerProvider } from './Hooks/useAuthHandler/AuthHandlerProvider';

import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

const AppLoader = (): ReactElement => {
  const routes = createBrowserRouter(
    [
      {
        path: '/*',
        element: (
          <GraphQLProvider>
            <App />
          </GraphQLProvider>
        ),
      },
    ],
    {
      basename: properties.PUBLIC_URL,
    },
  );

  return <RouterProvider router={routes} />;
};

//cleanup for old data
window.localStorage.removeItem('bsauth');
window.sessionStorage.removeItem('bsauth');

const container = document.getElementById('root');
if (!container) {
  alert(`No "root" element found in the DOM`);
}
const root = createRoot(container ?? new HTMLElement());

const LdApp = withLDProvider({
  clientSideID: properties.ldclientSideID,
  context: {
    kind: 'user',
    anonymous: true,
    key: 'VP-anom',
  },
  options: {
    diagnosticOptOut: true,
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags: ldFlagsMapper(),
})(AppLoader);

root.render(
  <div>
    <ThemeWrapper>
      <AuthHandlerProvider>
        <Provider store={store}>
          <AuthenticationWrapper config={msalConfig}>
            <DataDogRumProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <LdApp />
              </LocalizationProvider>
            </DataDogRumProvider>
          </AuthenticationWrapper>
        </Provider>
      </AuthHandlerProvider>
    </ThemeWrapper>
  </div>,
);
