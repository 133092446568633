import { ReactElement } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import PackingNoteSvg from 'Assets/images/svg/PackingNoteIcon/CreatePackingNote.svg';

const PackingNoteIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon component={PackingNoteSvg} viewBox="0 0 24 24" {...props} />
);

export default PackingNoteIcon;
