import { Suspense } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { INFORMATION_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { RouteConfig } from '.';

const NewsList = lazyImport(() => import('Views/News/NewsList'));

export const NEWS_ROUTES: RouteConfig = {
  label: 'News',
  to: INFORMATION_TO,
  icon: getIconByIconName('news'),
  permission: PERMISSION.VP_VIEW_NEWS,
  color: '#D1A667',
  component: (
    <Suspense fallback={<LazyLoadingMessage view="News" />}>
      <NewsList />
    </Suspense>
  ),
};
