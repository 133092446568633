import React, { ReactElement } from 'react';

import { Alert } from '@mui/material';
import Paper from '@mui/material/Paper';

const NoRecordsFound = (): ReactElement => {
  return (
    <Paper square>
      <Alert severity="warning">No records were found</Alert>
    </Paper>
  );
};

export default NoRecordsFound;
