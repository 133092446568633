import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box } from '@mui/material';

import {
  AccountInfo,
  Configuration,
  InteractionStatus,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from 'Config/adAuthConfig';

type Props = {
  config: Configuration;
};

interface AuthInterface {
  idToken: string | undefined;
  accessToken: string | undefined;
  account: AccountInfo | undefined;
}
export interface AuthContextInterface extends AuthInterface {
  setAuthContext: (val: AuthInterface) => void;
}
const AuthContextInitial: AuthContextInterface = {
  idToken: undefined,
  accessToken: undefined,
  account: undefined,
  setAuthContext: (_val: AuthInterface) => {},
};

export const AuthContext = createContext(AuthContextInitial);

export const AuthenticationProvider = (props: PropsWithChildren<unknown>): ReactElement => {
  const { instance, accounts, inProgress } = useMsal();

  const requesting = useRef<boolean>(false);

  const [authState, setAuthState] = useState<AuthInterface>(AuthContextInitial);
  const [error, setError] = useState<Error | undefined>(undefined);

  const memoizedAuthValue = useMemo(() => {
    return {
      ...authState,
      setAuthContext: setAuthState,
    };
  }, [authState]);

  const RequestIdToken = useCallback(() => {
    const account = accounts[0];
    const request: SilentRequest = {
      ...loginRequest,
      account,
    };

    instance
      .ssoSilent(request)
      .then((response) => {
        setAuthState({
          ...authState,
          idToken: response.idToken,
          accessToken: response.idToken,
          account,
        });
      })
      .catch(() => {
        instance
          .acquireTokenPopup(request)
          .then((response) => {
            setAuthState({
              ...authState,
              idToken: response.idToken,
              accessToken: response.idToken,
              account,
            });
          })
          .catch((e) => {
            setError(e);
          });
      });
  }, [accounts, authState, instance]);

  useEffect((): void => {
    if (requesting.current === false) {
      requesting.current = true;
      RequestIdToken();
    }
  }, [RequestIdToken, authState.idToken]);

  if (error !== undefined) {
    return <Box>Error</Box>;
  }

  return inProgress !== InteractionStatus.None || !authState.idToken ? (
    <Box>Loading</Box>
  ) : (
    <AuthContext.Provider value={memoizedAuthValue}>{props.children}</AuthContext.Provider>
  );
};

const AuthenticationWrapper = (props: PropsWithChildren<Props>): ReactElement => {
  const [client, setClient] = useState<PublicClientApplication | null>(null);
  useEffect(() => {
    if (client !== null) {
      return;
    }
    const init = async () => {
      const msalInstance = new PublicClientApplication(props.config);
      await msalInstance.initialize();
      setClient(msalInstance);
    };
    init();
  });
  if (client === null) {
    return <Box>Loading...</Box>;
  }

  return <MsalProvider instance={client}>{props.children}</MsalProvider>;
};
export default AuthenticationWrapper;
