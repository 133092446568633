import React, { forwardRef, ReactElement } from 'react';

import { Formik, FormikHelpers, FormikValues } from 'formik';

interface BestFormProps {
  onSubmit: (values: FormikValues, helpers: FormikHelpers<FormikValues>) => void;
  style?: React.CSSProperties;
  initialValues?: FormikValues;
}

export type BestFormSubmitButton = HTMLButtonElement;

const BestForm = forwardRef<BestFormSubmitButton, React.PropsWithChildren<BestFormProps>>(
  ({ onSubmit, style, children, initialValues = {} }, ref): ReactElement => {
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleReset, handleSubmit }) => (
          <form style={style} onReset={handleReset} onSubmit={handleSubmit}>
            {children}
            <button style={{ display: 'none' }} type="submit" ref={ref} />
          </form>
        )}
      </Formik>
    );
  },
);

BestForm.displayName = 'BestForm';
export default BestForm;
