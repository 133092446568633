import { TableState } from '../_types_/TableState';

export const SET_TABLE_SEARCH = 'SET_TABLE_SEARCH';

export interface SetTableSearchAction {
  type: typeof SET_TABLE_SEARCH;
  search: string | undefined;
}

export const doSetTableSearch = (search: string | undefined): SetTableSearchAction => ({
  type: SET_TABLE_SEARCH,
  search,
});

export const applySetTableSearch = (
  newState: TableState,
  action: SetTableSearchAction
): TableState => {
  newState.searchValue = action.search;

  return newState;
};
