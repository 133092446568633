import React, { ReactElement } from 'react';

import DisplayArea from 'Components/UI/Splash/DisplayArea';

import classes from './Splash.module.scss';

interface Props {
  fromSuspense?: boolean;
}

const Splash = (props: Props): ReactElement => {
  return (
    <div
      className={[classes.container, props.fromSuspense ? classes.fromSuspense : null].join(' ')}
    >
      <div className={classes.loading}>
        <div className={classes.loadingTextZoom}>
          <span className={classes.loadingTextZoom}>B</span>
          <span className={classes.loadingTextZoom}>E</span>
          <span className={classes.loadingTextZoom}>S</span>
          <span className={classes.loadingTextZoom}>T</span>
          <span className={classes.loadingTextZoom}>S</span>
          <span className={classes.loadingTextZoom}>E</span>
          <span className={classes.loadingTextZoom}>L</span>
          <span className={classes.loadingTextZoom}>L</span>
          <span className={classes.loadingTextZoom}>E</span>
          <span className={classes.loadingTextZoom}>R</span>
        </div>
        {props.fromSuspense && <DisplayArea />}
      </div>
    </div>
  );
};

export default Splash;
