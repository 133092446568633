import { ReactElement } from 'react';

import { useTheme } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';

import cellContent from 'Components/Table/Components/Helpers/cellContent';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';

import { TableColumn } from '../state/_types_/TableColumn';
import { useTableState } from '../state/useTableState';

interface Props<T extends TableRecord> {
  column: TableColumn<T>;
  row: T;
  isVertical?: boolean;
}

const TableCell = <T extends TableRecord>({
  column,
  row,
  isVertical = false,
}: Props<T>): ReactElement => {
  const { state, dispatch } = useTableState<T>();
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    stickyColumn: () => ({
      position: 'sticky',
      left: 0,
      zIndex: 0,
      backgroundColor: theme.palette.background.paper,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    }),
  }));
  const classes = useStyles({ position: 0 });

  if (column.hidden) {
    return <></>;
  }

  return (
    <MuiTableCell
      colSpan={isVertical ? state.columns.length : 1}
      className={column.sticky ? classes.stickyColumn : ''}
      align={column.number ? 'right' : 'inherit'}
    >
      {isVertical && column.label + ': '}
      <>{cellContent(column, row, dispatch)}</>
    </MuiTableCell>
  );
};

export default TableCell;
