import React, { ReactElement, useState } from 'react';

import { FilterList } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Badge,
  useTheme,
} from '@mui/material';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';

import { TableFilters } from 'Components/Table/state/_types_/TableFilters';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { doChangeFilter } from 'Components/Table/state/actions/changeFilter';
import { doChangePage } from 'Components/Table/state/actions/changePage';

import { removeUndefinedFromArray } from '../../../Helpers/util';
import { DoChangeFilterProps } from '../state/actions/changeFilter';
import { doClearFilter } from '../state/actions/clearFilter';
import { doSetShouldApplyFilters } from '../state/actions/setShouldApplyFilters';
import { useTableState } from '../state/useTableState';

import TableFilter from './TableFilters';

const TableFilterModal = (): ReactElement => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    active: {
      color: `${theme.palette.info.dark} !important`,
    },
    badge: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  }));

  const { state, dispatch } = useTableState();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [currentFilters, setCurrentFilters] = useState<TableFilters<TableRecord>>();

  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClickOpenButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget ?? null);
    setCurrentFilters(state.filters);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(doClearFilter());
    if (currentFilters) {
      Object.keys(currentFilters).forEach((filterProperty) => {
        const filter = currentFilters[filterProperty];
        if (filter) {
          dispatch(
            doChangeFilter({
              property: filterProperty,
              filterType: filter.type,
              value: filter.value,
            } as DoChangeFilterProps),
          );
        }
      });
    }
    setCurrentFilters(undefined);
  };

  const handleClearAllFilter = () => {
    dispatch(doClearFilter());
    dispatch(doSetShouldApplyFilters(true));
    setAnchorEl(null);
    setCurrentFilters(undefined);
  };

  const filterColumns = state.columns.filter((r) => r.filter);

  const handleApplyFilters = () => {
    dispatch(doSetShouldApplyFilters(true));
    dispatch(doChangePage(0));
    setAnchorEl(null);
    setCurrentFilters(undefined);
  };

  const numberOfFilters = Object.keys(state.filters).reduce((amount, filterKey) => {
    return (
      amount + [state.filters[filterKey]?.value].flat().filter(removeUndefinedFromArray).length
    );
  }, 0);

  if (filterColumns.length === 0) {
    return <></>;
  }

  return (
    <>
      <IconButton onClick={handleClickOpenButton} size="large">
        <Badge
          badgeContent={numberOfFilters}
          classes={{
            badge: classes.badge,
          }}
        >
          <FilterList className={open || numberOfFilters > 0 ? classes.active : ''} />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DialogContent>
          <DialogContentText component="div" style={{ width: '500px' }}>
            {filterColumns.map((column) => (
              <TableFilter key={`tableFilter-${column.property}`} column={column} />
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearAllFilter} color="primary" autoFocus variant="outlined">
            Clear
          </Button>
          <Button onClick={handleApplyFilters} color="primary" autoFocus variant="contained">
            Apply
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

export default TableFilterModal;
