import { ReactElement } from 'react';

import { MenuOpen } from '@mui/icons-material';
import { useTheme, AppBar, Toolbar, IconButton } from '@mui/material';
import { Box } from '@mui/system';

import Logo from 'Components/UI/Navigation/Logo';
import { DRAWER_WIDTH } from 'Components/UI/Navigation/NavigationBar/styles';
import { BreadCrumbs } from 'Components/UI/Navigation/NavigationBreadCrumbs';

type NavigationBarProps = {
  Actions: React.ComponentType;
} & (
  | {
      noSidebar: true;
      open?: never;
      handleDrawerOpen?: never;
    }
  | {
      noSidebar?: false;
      open: boolean;
      handleDrawerOpen: () => void;
    }
);

const NavigationBar = ({
  Actions,
  open,
  handleDrawerOpen,
  noSidebar,
}: NavigationBarProps): ReactElement => {
  const theme = useTheme();
  return (
    <AppBar
      sx={[
        {
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: '#3A3A3A',
          maxHeight: 64,
        },
        open
          ? {
              marginLeft: DRAWER_WIDTH,
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
              transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }
          : {},
      ]}
      position="fixed"
      elevation={0}
    >
      <Toolbar>
        {!noSidebar && (
          <Box sx={{ width: 34 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={[
                {
                  marginRight: 36,
                },
                !open ? {} : { display: 'none' },
              ]}
              size="large"
            >
              <MenuOpen
                sx={{
                  color: '#fff',
                  transform: 'rotate(180deg)',
                }}
              />
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Logo />
            <Box sx={{ paddingLeft: theme.spacing(2), display: 'flex', alignItems: 'center' }}>
              <BreadCrumbs />
            </Box>
          </Box>
          <Actions />
        </Box>
      </Toolbar>
      {/* SET ERROR MESSAGE HERE */}
      {/* <div
        style={{
          fontSize: 18,
          justifyContent: 'center',
          display: 'flex',
          zIndex: 99999,
          color: 'darkred',
          border: '1px solid darkred',
          margin: 8,
        }}
      >
        We are currently experiencing issues. We are working to reslove it
      </div> */}
    </AppBar>
  );
};

export default NavigationBar;
