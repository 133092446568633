import React, { useState, ReactElement, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { Popover, Button } from '@mui/material';

import { useReduxDispatch } from 'Helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

import TokenInfo from 'Components/UI/Devbar/TokenInfo/TokenInfo';
import BeBranches from 'Components/UI/Devbar/widgets/BeBranches';
import BuildInfo from 'Components/UI/Devbar/widgets/BuildInfo/BuildInfo';
import FakePrintPopover from 'Components/UI/Devbar/widgets/FakePrintError/FakePrintPopover/FakePrintPopover';
import FeatureFlagsPopover from 'Components/UI/Devbar/widgets/Flags/FeatureFlagsPopover';
import LocalStorage from 'Components/UI/Devbar/widgets/LocalStorage';
import PnTransmitter from 'Components/UI/Devbar/widgets/PnTransmitter';
import { getPermissionsArray, PERMISSION } from 'Constants/permissions.constants';
import { useGetDatabaseClass } from 'Models/Environment/environmentHelper';
import { Permission } from 'Models/Permission/_types_/Permission';
import { useHasPermission } from 'Models/Permission/permissionHelper';
import { User } from 'Models/User/_types_/User';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { DevSettingsState } from 'Store/reducers/devSettings.reducer';

import { userActions } from '../../../Store/actions/user.actions';

import classes from './Devbar.module.scss';

const Devbar = (): ReactElement => {
  const dispatch = useReduxDispatch();
  const hasPermission = useHasPermission();
  const user = useSelector((state: RootReducerState): User | null => state.userReducer.user);
  const devSettings = useSelector(
    (root: RootReducerState): DevSettingsState => root.devSettingsState,
  );
  const ldFlags = useFlags();

  const [anchorElPermission, setAnchorElPermission] = useState<HTMLButtonElement | null>(null);

  const openPermission = Boolean(anchorElPermission);
  const permissionPopoverId = openPermission ? 'permissionPopoverId' : undefined;

  const getDatabaseClass = useGetDatabaseClass();
  if (
    !hasPermission(PERMISSION.VP_DEVELOPER_PERMISSION) &&
    !hasPermission(PERMISSION.VP_VIEW_BOTTOMBAR)
  ) {
    return <></>;
  }

  return (
    <footer className={[classes.container, getDatabaseClass].join(' ')}>
      <div
        className={classes.footerSection}
        style={{
          backgroundColor:
            devSettings.fakeExtError.fullFailure || devSettings.fakeExtError.partialFailure
              ? 'orange'
              : '',
        }}
      >
        <FakePrintPopover />
      </div>
      <div className={classes.footerSection}>
        <Button
          aria-describedby={permissionPopoverId}
          variant="text"
          onClick={(evt: MouseEvent<HTMLButtonElement>): void => {
            setAnchorElPermission(evt.currentTarget);
          }}
        >
          <span>Permissions&nbsp;</span>
          <span>{!openPermission ? '⇧' : '⇩'}</span>
        </Button>
        <Popover
          id={permissionPopoverId}
          open={openPermission}
          anchorEl={anchorElPermission}
          onClose={(): void => setAnchorElPermission(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <table className={classes.table}>
            <tbody>
              {getPermissionsArray().map((permission: Permission): ReactElement => {
                return (
                  <tr key={permission.permissionsId}>
                    <td>{permission.permissionName}</td>{' '}
                    <td
                      className={classes.clickable}
                      align="right"
                      onClick={(): void => {
                        dispatch(userActions.fakePermission(permission.permissionsId));
                      }}
                    >
                      {hasPermission(permission.permissionsId) ? '✅' : '❌'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Popover>

        <></>
      </div>
      <div
        className={classes.footerSection}
        style={{
          backgroundColor: devSettings.disableAllFF.value ? 'orange' : '',
        }}
      >
        <FeatureFlagsPopover user={user} ldFlags={ldFlags} />
      </div>
      <div className={classes.footerSection}>
        <BeBranches />
      </div>
      <div className={classes.footerSection}>
        <Button
          onClick={(): void => {
            let storyNumber = prompt('Please enter Story Number', '');
            if (storyNumber !== null) {
              storyNumber = storyNumber.replace(/\D/g, '');
              window.open(`https://vendorportal-dev.bestseller.com/SUP-${storyNumber}/`);
            }
          }}
        >
          Go to FE Branch
        </Button>
      </div>
      <div className={classes.footerSection}>
        <TokenInfo />
      </div>
      <div className={classes.footerSection}>
        <BuildInfo />
      </div>
      <div className={classes.footerSection}>
        <LocalStorage />
      </div>
      <PnTransmitter />
    </footer>
  );
};

export default Devbar;
