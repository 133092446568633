import { UpdateDetailsCollection } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

export const updateDetailsCollection = (
  updatedState: SampleProformaHeaderState,
  action: UpdateDetailsCollection
): SampleProformaHeaderState => {
  // Create a copy of the lineInfoGroup array
  const updatedLines = [...updatedState.lineInfoGroup].map((group) => {
    // Check if the groupId of the current group matches the groupId from the action
    if (group.groupId === action.groupId) {
      // If it does, map over the lines in the current group
      group.lines = group.lines.map((line) => {
        // Check if the lineId of the current line matches the lineId from the action
        if (line.lineId === action.lineId) {
          // If it does, return a new object that spreads the properties of the current line
          // and updates the collection property with the value from the action
          return {
            ...line,
            collection: action.value,
          };
        }
        // If the lineId doesn't match, return the line as is
        return line;
      });
    }
    // If the groupId doesn't match, return the group as is
    return group;
  });
  return {
    ...updatedState,
    lineInfoGroup: updatedLines,
  };
};
