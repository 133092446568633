import { Suspense } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { PERMISSION } from 'Constants/permissions.constants';
import { ORDERS_TO, ORDERS_UPLOAD_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import NewsList from 'Views/News/NewsList';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { RouteConfig } from '.';

const Orders = lazyImport(() => import('Orders'));
const Upload = lazyImport(() => import('Views/Documents/OrderAttachments'));

export const ORDERS_ROUTES: RouteConfig = {
  label: 'Orders',
  to: ORDERS_TO,
  permission: PERMISSION.VP_VIEW_ORDER,
  icon: getIconByIconName('orders'),
  color: '#bc8940',
  component: (
    <Suspense fallback={<LazyLoadingMessage view="Orders" />}>
      <Orders />
    </Suspense>
  ),
  children: {
    OrderAttachments: {
      display: false,
      label: 'Upload Order Attachment',
      to: ORDERS_UPLOAD_TO,
      component: (
        <Suspense fallback={<LazyLoadingMessage view="Orders upload" />}>
          <Upload />
        </Suspense>
      ),
      permission: PERMISSION.VP_VIEW_ORDER,
      fallbackComponent: NewsList,
    },
  },
};
