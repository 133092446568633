import { TableState } from '../_types_/TableState';

export const CHANGE_FILTER = 'CHANGE_FILTER';

export type DoChangeFilterProps = {
  property: string;
} & (
  | {
      filterType: 'select';
      value: (string | number)[];
    }
  | {
      filterType: 'searchableSelect';
      value: (string | number)[];
    }
  | {
      filterType: 'selectOne';
      value: string | number;
    }
  | {
      filterType: 'date';
      value: [Date | null, Date | null];
    }
);

export type ChangeFilter = DoChangeFilterProps & {
  type: typeof CHANGE_FILTER;
};

export const doChangeFilter = (params: DoChangeFilterProps): ChangeFilter => ({
  type: CHANGE_FILTER,
  ...params,
});

export const applyChangeFilter = (newState: TableState, action: ChangeFilter): TableState => {
  const { type, property, ...filter } = action;
  newState.filters[property] = {
    type: filter.filterType,
    value: filter.value,
    // hack necessary cause typescript is being typescript
  } as typeof newState.filters[typeof property];
  return newState;
};
