import { Suspense, useMemo } from 'react';

import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { DASHBOARD_TO } from 'Constants/shared.constants';
import lazyImport from 'Helpers/lazyImport';
import LazyLoadingMessage from 'Views/routes/LazyLoadingMessage';

import { RouteConfig } from '.';

const Dashboard = lazyImport(() => import('Dashboard'));

export const useDASHBOARD_ROUTES = (): RouteConfig => {
  return useMemo(
    () => ({
      label: 'Dashboard',
      to: DASHBOARD_TO,
      icon: getIconByIconName('dashboard'),
      color: '#485627',
      component: (
        <Suspense fallback={<LazyLoadingMessage view="Dashboard" />}>
          <Dashboard />
        </Suspense>
      ),
    }),
    [],
  );
};
