import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import {
  GetUserConfig,
  userServiceApiBaseUrl,
} from 'UserAuthentication/_LoginUserServices/userService';
import { Profile } from 'UserAuthentication/Functionalities/ChooseProfile/_types/ProfileResponse';

const GET_LINK_ACCOUNTS = userServiceApiBaseUrl(`profiles`);

export const useGetLinkedProfiles = (
  token: string,
): UseLazyBestApiTuple<Profile[], GetUserConfig> => {
  return useLazyBestApi<Profile[], GetUserConfig>(GET_LINK_ACCOUNTS, {
    headers: {
      'USERS-API-VERSION': '2.0',
      authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};
