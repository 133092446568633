import React from 'react';

import { AuthHandlerDispatch } from 'Hooks/useAuthHandler/state/_types/AuthHandlerDisptach';
import { authState } from 'Hooks/useAuthHandler/state/_types/AuthState';

export const AuthHandlerContext =
  React.createContext<
    | {
        state: authState;
        dispatch: AuthHandlerDispatch;
      }
    | undefined
  >(undefined);
