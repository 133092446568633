import { TableState } from '../_types_/TableState';
import { getSelectableRows } from '../selectors/getSelectableRows';

export const SELECT_ALL_TABLE_ROWS_IN_VIEW = 'SELECT_ALL_TABLE_ROWS_IN_VIEW';

export interface SelectAllTableRowsInView {
  type: typeof SELECT_ALL_TABLE_ROWS_IN_VIEW;
}

export const doSelectAllTableRowsInView = (): SelectAllTableRowsInView => ({
  type: SELECT_ALL_TABLE_ROWS_IN_VIEW,
});

export const applySelectAllTableRowsInView = (
  newState: TableState,
  _action: SelectAllTableRowsInView
): TableState => {
  const keysOfSelectableRows = getSelectableRows(newState);
  const keysOfSelectedRows = Object.keys(newState.selectedRows);

  if (keysOfSelectedRows.some((r) => keysOfSelectableRows.includes(r))) {
    keysOfSelectedRows
      .filter((r) => keysOfSelectableRows.includes(r))
      .forEach((r) => delete newState.selectedRows[r]);
  } else {
    keysOfSelectableRows.forEach((r) => {
      const rowToBeSelected = newState.rows.find(
        (row) => String(row[newState.primaryKey]) === String(r)
      );
      if (rowToBeSelected) {
        newState.selectedRows[r] = rowToBeSelected;
      }
    });
  }

  return newState;
};
