import {
  deleteResourceRequestConfig,
  getResourceRequestConfig,
  modifyResourceRequestConfig,
} from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { properties } from 'Helpers/properties';

const branchNumber = sessionStorage.getItem('userServiceBranch')
  ? sessionStorage.getItem('userServiceBranch') + '/'
  : '';

export const userServiceApiBaseUrl = (path: string): string =>
  `${properties.serviceApiURL}${branchNumber}user/${path}`;

export type GetUserConfig = getResourceRequestConfig & {
  method: 'GET';
};

export type PutUserConfig = modifyResourceRequestConfig & {
  method: 'PUT';
};

export type PutUserVendorMdmConfig = modifyResourceRequestConfig<{
  vendorMdmIdentifier: string | null;
}> & {
  method: 'PUT';
};

export type ModifyMicrosoftUserConfig = modifyResourceRequestConfig<{
  email: string;
}> & {
  method: 'POST' | 'PUT';
};

export type PostLinkedProfileConfig = modifyResourceRequestConfig<{
  bi2NetworkId: string | undefined;
}> & {
  method: 'POST';
};

export type DeleteUserConfig = deleteResourceRequestConfig & {
  method: 'DELETE';
};
