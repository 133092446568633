import { ReactNode, Component } from 'react';

import { datadogRum } from '@datadog/browser-rum';

/*
Needs to be a class component since
componentDidCatch and getDerivedStateFromError: There are no Hook equivalents for these methods yet, but they will be added soon
https://reactjs.org/docs/hooks-faq.html
*/

interface State {
  hasError: boolean;
}
type PropType = React.PropsWithChildren<unknown>;

class ErrorBoundary extends Component<PropType, State> {
  constructor(props: React.PropsWithChildren<unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown): State {
    datadogRum.addError('ErrorBoundary: Caching issue?', { error: error });
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <p>Loading failed! Please reload.</p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
