import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';
import { useReduxDispatch } from 'Helpers';
import { v4 as uuidv4 } from 'uuid';

import { datadogRum } from '@datadog/browser-rum';
import { LOGOUT, SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { User } from 'Models/User/_types_/User';
import { userActions } from 'Store/actions/user.actions';
import {
  transformUser,
  useGetProfile,
} from 'UserAuthentication/_LoginUserServices/hooks/authentication/useGetProfile';
import { useMapCurrentProfileTokenToSelectedProfileToken } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useMapCurrentProfileTokenToSelectedProfileToken';
import { usePutDefaultProfile } from 'UserAuthentication/_LoginUserServices/hooks/functionalities/usePutDefaultProfile';

export const useChangeProfile = (
  bi2networkId: string | undefined,
): {
  user: User | undefined;
  hasLoaded: boolean;
  errors: { tokenError: AxiosError | undefined; profileError: AxiosError | undefined };
} => {
  const dispatch = useReduxDispatch();
  const { authDispatch } = useAuthState();
  const navigate = useNavigate();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [fetchNewToken, { data: dataNewToken, error: errorNewToken, loading: loadingNewToken }] =
    useMapCurrentProfileTokenToSelectedProfileToken(bi2networkId);

  useEffect(() => {
    if (bi2networkId && !hasLoaded) {
      fetchNewToken();
    }
  }, [bi2networkId, fetchNewToken, hasLoaded]);

  const adaptedToken = useMemo((): Token | undefined => {
    if (loadingNewToken) {
      return;
    }
    if (errorNewToken) {
      setHasLoaded(true);
      return;
    }
    if (dataNewToken) {
      const newToken: Token = {
        accessToken: dataNewToken.access_token,
        refreshToken: dataNewToken.refresh_token,
        expires: new Date().getTime() + 1000 * dataNewToken.expires_in,
        provider: 'AD',
        idToken: dataNewToken.access_token,
        remember: false,
        sid: uuidv4(),
        hasADLogin: true,
        email: dataNewToken.email,
        inviteStatus: dataNewToken.invite_status,
      };
      return newToken;
    }
  }, [dataNewToken, errorNewToken, loadingNewToken]);

  const [fetchGetProfile, { data: profileData, error: profileError, loading: profileLoading }] =
    useGetProfile(adaptedToken?.accessToken ?? '');

  useEffect(() => {
    if (adaptedToken && !hasLoaded) {
      fetchGetProfile();
      authDispatch({ type: SET_TOKENS, tokens: adaptedToken });
    }
  }, [adaptedToken, fetchGetProfile, authDispatch, hasLoaded]);

  const adaptedProfile = useMemo(() => {
    if (profileLoading) {
      return;
    }

    if (profileError) {
      datadogRum.addError('Login error. User could not be casted.', profileError);
      authDispatch({ type: LOGOUT });
      dispatch(userActions.deleteUser());
      setHasLoaded(true);
      return;
    }

    if (!profileData) {
      return;
    }

    return transformUser(profileData);
  }, [profileData, profileError, profileLoading, authDispatch, dispatch]);

  const [fetchPutDefaultAccount, { loading: loadingPutDefault, data: dataPutDefault }] =
    usePutDefaultProfile(bi2networkId ?? '');

  useEffect(() => {
    if (adaptedProfile) {
      fetchPutDefaultAccount();
    }
  }, [adaptedProfile, dispatch, navigate, fetchPutDefaultAccount]);

  useEffect(() => {
    if (loadingPutDefault) {
      return;
    }

    if (dataPutDefault && adaptedProfile) {
      setHasLoaded(true);
      dispatch(userActions.setUser(adaptedProfile));
      dispatch(userActions.updateUserMdM(dataPutDefault.mdmIdentifier));
    }
  }, [adaptedProfile, dataPutDefault, dispatch, loadingPutDefault, navigate]);

  return {
    user: adaptedProfile,
    hasLoaded,
    errors: {
      tokenError: errorNewToken,
      profileError,
    },
  };
};
