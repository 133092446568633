import React, { ChangeEvent, createRef, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import BaseButton from 'Components/Shared/BestButton/BaseButton';
import BestForm, { BestFormSubmitButton } from 'Components/Shared/Forms/BestForm';
import ValidatedTextfield from 'Components/Shared/Forms/ValidatedTextfield/ValidatedTextfield';
import { DataDogRumContext } from 'Contexts/DataDogRumContext';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { CreateIncidentResponse, handleCreateServiceNowIncident } from 'Services/support.service';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';

import classes from './SupportModal.module.scss';

interface PropType {
  handleClose: () => void;
}

const SupportModal = ({ handleClose }: PropType): ReactElement | null => {
  const location = useLocation();
  const [area, setArea] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const datadogRum = React.useContext(DataDogRumContext);

  const datadogInitialContext = datadogRum?.getInternalContext();
  const datadogApplicationId = datadogInitialContext?.application_id;
  const datadogSessionId = datadogInitialContext?.session_id;
  const getDataDogUrl = () => {
    const currentTime = new Date().getTime();
    const fourHoursAgo = currentTime - 4 * 60 * 60 * 1000;
    return `https://bestseller.datadoghq.eu/rum/explorer?query=%40session.id%3A${datadogSessionId}%20%40application.id%3A${datadogApplicationId}&from_ts=${fourHoursAgo}&to_ts=${currentTime}`;
  };

  const userState = useSelector((root: RootReducerState): UserReducerType => root.userReducer);
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>(
    userState.user && userState.user?.email ? userState.user?.email : '',
  );
  const [response, setResponse] = useState<CreateIncidentResponse | undefined>();

  const [loading, setLoading] = useState<boolean>(false);
  const [closing, setClosing] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect((): void => {
    setArea(location.pathname);
    setUrl(window.location.origin + location.pathname + location.search);
  }, [location]);

  const closeModal = (): void => {
    setMessage(' ');
    setEmail(userState.user && userState.user?.email ? userState.user?.email : '');
    setLoading(false);
    setClosing(false);
    setHasError(false);
    setResponse(undefined);
    handleClose();
  };

  const handleSubmit = async (): Promise<void> => {
    setHasError(false);

    if (!email.trim()) {
      return;
    }
    if (!message) {
      return;
    }
    try {
      setLoading(true);
      const res = await handleCreateServiceNowIncident({
        user: userState.user?.userName,
        email,
        message,
        context: {
          windowArea: area,
          windowLocation: url,
          user: userState.user || undefined,
          browserVersion: window.navigator.appVersion,
          ddSessionUrl: getDataDogUrl(),
          ddSessionReplayUrl: `https://bestseller.datadoghq.eu/rum/replay/sessions/${datadogSessionId}`,
        },
      });

      setResponse(res);
      setLoading(false);
      setMessage(' ');
      setClosing(true);
    } catch {
      // TODO: handle possible errors
      setLoading(false);
      setHasError(true);
    }
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setMessage(event.target.value);
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setEmail(event.target.value);

  const submitButton = createRef<BestFormSubmitButton>();

  return (
    <Dialog
      open
      aria-labelledby="support-dialog-title"
      aria-describedby="support-dialog-description"
      PaperProps={{
        square: true,
        className: [classes.dialogPaper, hasError ? classes.hasError : ''].join(' '),
      }}
      onBackdropClick={(event): void => {
        event.preventDefault();
        if (message.trim().length < 1) {
          return closeModal();
        }
        if (confirm('Are you sure that you want to close the form. Your data will be lost.')) {
          return closeModal();
        }
      }}
    >
      <DialogTitle id="support-dialog-title">
        <span>Please share your experience with us.</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent id="support-dialog-description">
        <DialogContentText>
          Help us improve your Vendor Portal workflow by reporting experienced issues and missing
          features. We value your opinion, so feel free to share them with us.
        </DialogContentText>
        {/*- Placeholder for Report Type select (support/feature request)-*/}
        {loading && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        <div className={[classes.formContainer, closing ? classes.visibiltyHidden : ''].join(' ')}>
          {closing && response && (
            <div className={classes.closingMessage}>
              <DialogContentText className={classes.thankYouMessage}>
                Thank you for your feedback!
              </DialogContentText>
              <Divider className={classes.divider} />
              <DialogContentText>
                <span>Your ticket number is</span>
                {'  '}
                {/* TODO: handle for dev, test and prod*/}
                {/* <a target="_blank" href={`https://bestsellerdev.service-now.com/bestsellersp?id=ticketsp&table=incident&sys_id=${response.sysId}`} rel="noopener noreferrer"> */}
                <Typography variant="button">{response.number}</Typography>
                {/* </a> */}
              </DialogContentText>
            </div>
          )}
          <BestForm onSubmit={handleSubmit} ref={submitButton}>
            <div>
              <ValidatedTextfield
                fullWidth
                label="e-mail"
                value={email}
                onChange={handleEmailChange}
                name="userName"
                variant="outlined"
              />
            </div>
            <div>
              <ValidatedTextfield
                fullWidth
                required
                onChange={handleMessageChange}
                variant="outlined"
                name="message"
                rows={4}
                multiline
                label="feedback"
                value={message}
              />
            </div>
          </BestForm>
        </div>
      </DialogContent>
      <DialogActions>
        {!closing ? (
          <>
            <BaseButton onClick={closeModal} buttonType="cancel" disableButton={loading}>
              Cancel
            </BaseButton>
            <BaseButton
              disableButton={loading || message.trim().length < 1 || closing}
              onClick={(): void => {
                if (submitButton && submitButton.current) {
                  submitButton.current.click();
                }
              }}
              ref={submitButton}
            >
              Submit
            </BaseButton>
          </>
        ) : (
          <BaseButton onClick={closeModal} disableButton={!!loading}>
            Close
          </BaseButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SupportModal;
