import { useEffect } from 'react';

import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { useTableState } from 'Components/Table/state/useTableState';

import { doSetTableRows } from '../../state/actions/setTableRows';

const useTableRowHelper = <T extends TableRecord>(rows: T[]): void => {
  const { dispatch } = useTableState<T>();

  useEffect(() => {
    dispatch(doSetTableRows(rows));
  }, [rows, dispatch]);
};

export default useTableRowHelper;
