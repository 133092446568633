import { areAllValuesTheSame } from 'Helpers/util';
import { fixProformaLineNumber } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/addNewLines';
import { fixupLineNumber } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/helpers/fixupLineNumbers';
import {
  RemoveSampleProformaLineGroup,
  RemoveSampleProformaLineDetails,
} from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderActions';
import { SampleProformaHeaderState } from 'Invoice/SampleProforma/CreateSampleProforma/Header/context/SampleProformaHeaderState';

/**
 * This function removes an entire group
 *
 * @param {SampleProformaHeaderState} updatedState - The current state of the sample proforma header.
 * @param {RemoveSampleProformaLineGroup} action - The action to be performed.
 * @returns {SampleProformaHeaderState} - The updated state of the sample proforma header.
 */
const handleGroup = (
  updatedState: SampleProformaHeaderState,
  action: RemoveSampleProformaLineGroup
): SampleProformaHeaderState => {
  return {
    ...updatedState,
    lineInfoGroup: fixupLineNumber(
      updatedState.lineInfoGroup.filter((x) => x.lineId !== action.lineId)
    ),
  };
};

/**
 * This function prepares to remove a line from details based on the action provided.
 * It creates a map of groups by groupId for quick lookup, filters out the line with the lineId from the action,
 * adjusts the proforma line number accordingly, and removes the group from the groupMap if no lines remain after the deletion.
 *
 * @param {SampleProformaHeaderState} updatedState - The current state of the sample proforma header.
 * @param {RemoveSampleProformaLineDetails} action - The action to be performed.
 * @returns {SampleProformaHeaderState} - The updated state of the sample proforma header.
 */
const handleDetails = (
  updatedState: SampleProformaHeaderState,
  action: RemoveSampleProformaLineDetails
): SampleProformaHeaderState => {
  // Create a map from the lineInfoGroup, using groupId as the key
  const groupMap = new Map(updatedState.lineInfoGroup.map((group) => [group.groupId, group]));

  // Check if the groupMap has the groupId from the action
  if (groupMap.has(action.groupId)) {
    // Get the details of the group
    const details = groupMap.get(action.groupId);

    // If details are undefined, return the updated state
    if (details === undefined) {
      return updatedState;
    }

    // Filter out the line with the lineId from the action and fix the line numbers
    details.lines = fixProformaLineNumber(
      details.lines.filter((line) => line.lineId !== action.lineId)
    );

    // If there are no lines left in the group, delete the group from the map
    if (details.lines.length === 0) {
      groupMap.delete(action.groupId);
    }
  }

  // Get the affected group
  const affectedGroup = groupMap.get(action.groupId);

  // If the affected group is not undefined, update the quantity and price
  if (affectedGroup !== undefined) {
    const isQuantityMixed = !areAllValuesTheSame(affectedGroup.lines, 'quantity', 'value');
    const isPriceMixed = !areAllValuesTheSame(affectedGroup.lines, 'pricePrPcs', 'value');

    // Update the quantity
    affectedGroup.quantity = {
      value: isQuantityMixed ? undefined : affectedGroup.lines[0].quantity?.value,
      mixed: isQuantityMixed,
      hasError: false,
      maxValue: affectedGroup.lines[0].quantity?.maxValue,
    };

    // Update the price
    affectedGroup.pricePrPcs = {
      editable: affectedGroup.pricePrPcs?.editable ?? true,
      value: isPriceMixed ? undefined : affectedGroup.lines[0].pricePrPcs?.value,
      mixed: isPriceMixed,
      hasError: false,
    };
  }

  // Fix the line numbers for all groups
  const lineInfoGroup = fixupLineNumber(Array.from(groupMap.values()));

  // Return the updated state with the new lineInfoGroup
  return {
    ...updatedState,
    lineInfoGroup: lineInfoGroup,
  };
};

/**
 * This function removes a line from a group or from details based on the action provided.
 * It delegates the task to either `handleGroup` or `handleDetails` function depending on the `fromDetails` property of the action.
 *
 * @param {SampleProformaHeaderState} updatedState - The current state of the sample proforma header.
 * @param {RemoveSampleProformaLineGroup | RemoveSampleProformaLineDetails} action - The action to be performed.
 * @returns {SampleProformaHeaderState} - The updated state of the sample proforma header.
 */
export const removeSampleProformaLine = (
  updatedState: SampleProformaHeaderState,
  action: RemoveSampleProformaLineGroup | RemoveSampleProformaLineDetails
): SampleProformaHeaderState => {
  // If the action is not from details, remove the line from the group
  if (!action.fromDetails) {
    return handleGroup(updatedState, action);
  }
  // If the action is from details, remove the line from details
  return handleDetails(updatedState, action);
};
