import { Key } from 'react';

import { ExpandedRows, TableState } from 'Models/Orders/_types_/OrderType';
import {
  COLLAPSE,
  COLLAPSE_ALL,
  COLLAPSE_MULTIPLE,
  EXPAND,
  EXPAND_MULTIPLE,
  TableExpandState,
} from 'Store/actions/table.action';

const initialState: TableState = {
  expandedRows: [],
};

export default (state = initialState, action: TableExpandState): TableState => {
  const findExpItem = (tableId: Key): ExpandedRows | undefined => {
    const expData = [...state.expandedRows];
    return expData.find((item: ExpandedRows): boolean => item.id === tableId);
  };

  const setExpandedState = (expItem: ExpandedRows): TableState => {
    return {
      ...state,
      expandedRows: [
        ...state.expandedRows.filter((er: ExpandedRows): boolean => er.id !== expItem.id),
        expItem,
      ],
    };
  };

  switch (action.type) {
    case EXPAND: {
      const expItem = findExpItem(action.tableId);
      return setExpandedState({
        id: action.tableId,
        rows: [...(expItem ? expItem.rows : []), action.rowId],
      });
    }
    case EXPAND_MULTIPLE: {
      const expItem = findExpItem(action.tableId);
      const newElements = action.rowIds.filter(
        (x) => expItem?.rows.find((y) => y === x) === undefined
      );
      return setExpandedState({
        id: action.tableId,
        rows: [...(expItem ? expItem.rows : []), ...newElements],
      });
    }
    case COLLAPSE_MULTIPLE: {
      const expItem = findExpItem(action.tableId);
      const newElements = (expItem?.rows ?? []).filter((x) => !action.rowIds.some((y) => y === x));

      return setExpandedState({
        id: action.tableId,
        rows: newElements,
      });
    }

    case COLLAPSE: {
      const expItem = findExpItem(action.tableId);
      if (expItem) {
        expItem.rows = expItem.rows.filter((val: Key): boolean => val !== action.rowId);
        return setExpandedState(expItem);
      }

      return state;
    }
    case COLLAPSE_ALL: {
      return setExpandedState({ id: action.tableId, rows: [] });
    }

    default:
      return state;
  }
};
