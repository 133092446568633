import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

import { CircularProgress, IconButton, Tooltip } from '@mui/material';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { displayDate } from 'Components/Shared/DateDisplay/DateDisplay';
import { getIconByIconName } from 'Components/Shared/Icons/Icons';
import { TableRecord } from 'Components/Table/state/_types_/TableRecord';
import { useTableState } from 'Components/Table/state/useTableState';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { openBlobInNewWindow } from 'Helpers/util';
import { useFetchSpecificOverviewOrderExcelPrint } from 'Services/orders.service';

const ExportableButtons = <T extends TableRecord>(): ReactElement => {
  const excelRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const csvRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const specificOverviewOrderExcelPrint = useFetchSpecificOverviewOrderExcelPrint();
  const flags = useFlags();
  const featureFlags = useGetFeatureFlag();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { state } = useTableState<T>();
  const ordersIdArray = useMemo(() => {
    return Object.keys(state.selectedRows);
  }, [state.selectedRows]);

  const csvReport = useMemo(
    () => ({
      data: Object.keys(state.selectedRows).map((key) => {
        const row = state.selectedRows[key as keyof typeof state.selectedRows];
        let formattedRow: typeof row | undefined = undefined;
        Object.keys(row).forEach((rowKey: keyof typeof row) => {
          const formatter = state.columns.find((x) => x.property === rowKey)?.csvFormatter;
          if (formatter) {
            formattedRow = { ...row };
            formattedRow[rowKey] = formatter(row) as never; //I have no idea, why this cast works. ¯\_(ツ)_/¯
          }
        });

        if (formattedRow) {
          return formattedRow;
        }
        return row;
      }),
      headers: state.columns
        .filter((x) => !x.hideFromCsvExport)
        .map((x) => ({ label: x.label, key: x.property })),

      filename: `Vendor portal ${displayDate('toLocaleString', new Date(Date.now()))}.csv`,
    }),
    [state]
  );

  const handleClick = useCallback(() => {
    if (flags[featureFlags('excel-overview-sup-52')]) {
      setLoading(true);
      specificOverviewOrderExcelPrint(ordersIdArray, 'EXCEL')
        .then((response) => {
          if (error) {
            setError(false);
          }
          openBlobInNewWindow(response.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      excelRef.current?.link.click();
    }
  }, [error, featureFlags, flags, specificOverviewOrderExcelPrint, ordersIdArray]);

  return (
    <>
      <CSVLink
        ref={excelRef}
        data={csvReport.data}
        headers={csvReport.headers}
        separator={';'}
        filename={csvReport.filename}
      />
      <Tooltip title="Download excel">
        {!loading ? (
          <IconButton onClick={handleClick}>{getIconByIconName('excel')}</IconButton>
        ) : (
          <CircularProgress size="28px" />
        )}
      </Tooltip>
      <CSVLink
        ref={csvRef}
        data={csvReport.data}
        headers={csvReport.headers}
        filename={csvReport.filename}
      />
      <Tooltip title="Download csv">
        <IconButton onClick={() => csvRef.current?.link.click()}>
          {getIconByIconName('csv')}
        </IconButton>
      </Tooltip>
    </>
  );
};
export default ExportableButtons;
