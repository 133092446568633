import { ReactNode } from 'react';

import { SET_INFO, SET_WARNING, SET_ERROR, CLEAR_ERROR } from 'Constants/error.constants';
import { toastType } from 'Store/reducers/toast.reducer';

import { BaseAction } from './_types_/BaseAction';

export interface ToastAction extends BaseAction {
  message: ReactNode;
  sticky?: boolean;
}

const setMessage = (type: toastType, message: ReactNode, sticky?: boolean): ToastAction => {
  let payloadType: string | undefined = undefined;

  switch (type) {
    case 'Info':
      payloadType = SET_INFO;
      break;
    case 'Warning':
      payloadType = SET_WARNING;
      break;
    case 'Error':
      payloadType = SET_ERROR;
      break;
    default:
      throw `${type} not a valid toast type`;
  }
  return { type: payloadType, message, sticky };
};

function setInfoMessage(message: ReactNode, sticky?: boolean): ToastAction {
  return setMessage('Info', message, sticky);
}

function setWarningMessage(message: ReactNode, sticky?: boolean): ToastAction {
  return setMessage('Warning', message, sticky);
}

function setErrorMessage(message: ReactNode, sticky?: boolean): ToastAction {
  return setMessage('Error', message, sticky);
}

function clearErrorMessage(): BaseAction {
  return { type: CLEAR_ERROR };
}

export const toastActions = {
  setInfoMessage,
  setWarningMessage,
  setErrorMessage,
  clearErrorMessage,
};
