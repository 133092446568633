import { useLazyBestApi, UseLazyBestApiTuple } from 'Components/Hooks/useLazyBestApi';
import {
  DeleteLoginConfig,
  loginServiceApiBaseUrl,
} from 'UserAuthentication/_LoginUserServices/loginService';

const REVOKE_ACCESS = (token: string) =>
  loginServiceApiBaseUrl(`accesstokenprovider/user/revokeUserAccess/${token}`);

export const useRevokeAccess = (token: string): UseLazyBestApiTuple<unknown, DeleteLoginConfig> => {
  return useLazyBestApi<unknown, DeleteLoginConfig>(REVOKE_ACCESS(token), {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    method: 'DELETE',
  });
};
