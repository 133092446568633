import React, { ReactElement } from 'react';

import { Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@mui/material';

import BaseButton from 'Components/Shared/BestButton/BaseButton';

interface Props {
  title: string;
  open: boolean;
  handleClose: () => void;
}
const CampaignDialog = ({ title, open, handleClose }: Props): ReactElement => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-guide-title"
      aria-describedby="alert-dialog-guide-description"
    >
      <DialogTitle id="alert-dialog-guide-title">{title}</DialogTitle>
      <DialogContent>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography component="span">Through this link&nbsp;</Typography>
        <a href="https://earthquake.trendyol.com" target="_blank" rel="noreferrer">
          earthquake.trendyol.com
        </a>
        <Typography component="span">, people from all around the world will be able:</Typography>
        <ul>
          <li>
            to donate to the Turkish Ministry of Trade&apos;s Earthquake Relief Initiative through
            Digital Support Cards such as Afad, Unicef, Red Cresdent.{' '}
          </li>
          <li>
            to purchase goods from the Required Items list, which will go directly to those impacted
            region through Trendyol logistics
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <BaseButton onClick={handleClose}>Close</BaseButton>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignDialog;
