import { getResourceRequestConfig } from 'Components/Hooks/_types_/UseBestApiRequestConfig';
import { properties } from 'Helpers/properties';

const branchNumber = sessionStorage.getItem('ordersBranch')
  ? sessionStorage.getItem('ordersBranch') + '/'
  : '';

export const ordersApiBaseUrl = (path: string): string =>
  `${properties.serviceApiURL}${branchNumber}orders/${path}`;

export type GetOrdersConfig = getResourceRequestConfig & {
  method: 'GET';
};
