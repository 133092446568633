import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/**
 * A hook like `React.useState` which stores the value in localStorage as well for persistance.
 *
 * @param defaultValue - value that is stored in localStorage if the item is not found.
 * @param key - the key that under which the value is stored in localStorage.
 *
 * @returns a tuple as `[value, setValue]`
 *
 *
 * @example
 * ```
 * const [open, setOpen] = useLocalStorage(true, 'navigationBarOpen');
 * ```
 */
const useLocalStorage = <T>(defaultValue: T, key: string): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    try {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

export default useLocalStorage;
